<template>
  <form style="padding: 2rem" class="form" id="formIncome">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                What is your monthly income?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input form="formIncome" v-model="formIncome.choice" :value="13" type="radio"
                        name="flexRadioDefault" id="formInc2" />
                      <label style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          " for="formInc2">Less than 30,000</label>
                    </div>
                    <div style="width: 100%; height: 80px; margin-top: -1rem" class="radio-card">
                      <input form="formIncome" v-model="formIncome.choice" :value="14" type="radio"
                        name="flexRadioDefault" id="formInc3" />
                      <label style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          " for="formInc3">30,001 to 100,000</label>
                    </div>
                    <div style="width: 100%; height: 80px; margin-top: -1rem" class="radio-card">
                      <input form="formIncome" v-model="formIncome.choice" :value="15" type="radio"
                        name="flexRadioDefault" id="formInc4" />
                      <label style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          " for="formInc4">100,001 to 200,000</label>
                    </div>
                    <div style="width: 100%; height: 80px; margin-top: -1rem" class="radio-card">
                      <input form="formIncome" v-model="formIncome.choice" :value="16" type="radio"
                        name="flexRadioDefault" id="formInc5" />
                      <label style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          " for="formInc5">200,001 to 300,000</label>
                    </div>
                    <div style="width: 100%; height: 80px; margin-top: -1rem" class="radio-card">
                      <input form="formIncome" v-model="formIncome.choice" :value="17" type="radio"
                        name="flexRadioDefault" id="formInc6" />
                      <label style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          " for="formInc6">300,000 and above</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2" variant="gradient"
        color="dark" @click.prevent="previous()">
        Back
      </soft-button>
      <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2" variant="gradient"
        color="success" type="submit" @click.prevent="getIncomeAnswer">
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formIncome: {},
      step: 5,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),
    getIncomeAnswer() {
      console.log("Income", this.formIncome);
      this.addInvestmentAnswer(this.formIncome);
    },
    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      if (this.invBoarding.success) {
        this.$emit("submitIncome", this.formIncome);
      } else {
        return;
      }
    },
  },
};
</script>
