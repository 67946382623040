<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Active Loans</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table
          class="table align-items-center justify-content-center mb-0"
          v-if="activeBorrowed.length > 0"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Lender
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Interest
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Date payable
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Amount Payable
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="abl in activeBorrowed" :key="abl.id">
            <active-loan-table-rows :abl="abl" />
          </tbody>
          <!-- <tbody v-else>
            <tr>
              <th colspan="5" class="text-center">No Active Loans!</th>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </div>
  <!-- Pay Active Loan Modal -->
  <div class="col-md-4">
    <pay-active-loan-modal />
  </div>

  <!-- Active Loan Details Modal -->
  <div class="col-md-4">
    <div
      class="modal fade"
      :id="'modal-active-loan-details_' + details_id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-active-loan-details">
              Loan Details
            </h5>

            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <li
              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <span class="text-xs">Loan amount</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  KES {{ parseFloat(detail.amount_payable).toLocaleString() }}
                </h6>
              </div>
              <div class="d-flex flex-column">
                <span class="text-xs">Interest rate</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  {{ detail.interest }}%
                </h6>
              </div>
            </li>
            <li
              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <span class="text-xs">Date payable</span>
                <h6
                  style="color: red !important"
                  class="text-dark mb-1 font-weight-bold text-lg"
                >
                  {{ detail.date_payable }}
                </h6>
              </div>
            </li>
            <label> Borrower's loan + interest cover </label>
            <div class="cover-bar">
              <div class="fifty">
                <p v-if="payCoverage >= 50">{{ payCoverage.toFixed(2) }}%</p>
              </div>
              <div class="twentyfive">
                <p v-if="payCoverage < 50 && payCoverage >= 10">
                  {{ payCoverage.toFixed(2) }}%
                </p>
              </div>
              <div class="ten">
                <p v-if="payCoverage < 10 && payCoverage >= 0">
                  {{ payCoverage.toFixed(2) }}%
                </p>
              </div>
            </div>
            <hr class="horizontal dark" />
            <h6
              style="margin-bottom: 1rem"
              class="modal-title"
              id="modal-title-loan-details"
            >
              Guarantors
            </h6>
            <ul class="list-unstyled max-width-200">
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span
                  >
                </span>
              </li>
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span
                  >
                </span>
              </li>
            </ul>
            <hr class="horizontal dark" />
            <h6
              style="margin-bottom: 1rem"
              class="modal-title"
              id="modal-title-loan-details"
            >
              Recent Transactions
            </h6>
            <div>
              <transaction-loan-card
                style="margin-top: -1rem"
                :horizontal-break="false"
                :card="{}"
                :item="[
                  {
                    title: 'Self Loan - WID-CDRIQT',
                    date: '27 February 2022, at 12:30 PM',
                    amount: 'KES 2,500',
                    icon: 'fa-arrow-down',
                    color: 'danger',
                  },
                  {
                    title: 'Loan Self Payment - DEP-CDRIQT',
                    date: '23 March 2022, at 04:30 AM',
                    amount: 'KES 2,000',
                    icon: 'fa-arrow-up',
                    color: 'success',
                  },
                  {
                    title: 'Loan Self Payment - DEP-CDRIQT',
                    date: '26 March 2022, at 13:45 PM',
                    amount: 'KES 500',
                    icon: 'fa-arrow-up',
                    color: 'success',
                  },
                ]"
              >
              </transaction-loan-card>
            </div>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark"
              >Change</soft-button
            >
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Close</soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import ActiveLoanTableRows from "./ActiveLoanTableRows.vue";
export default {
  name: "active-loans-table",
  data() {
    return {
      showMenu: false,
      details_id: 0,
      detail: {},
      payCoverage: 0,
    };
  },
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    getID(__id, __goal) {
      this.details_id = __id;
      this.detail = __goal;
      this.payCoverage =
        (parseFloat(__goal?.remaining_amount) /
          parseFloat(__goal?.amount_payable)) *
        100;
    },

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: { ActiveLoanTableRows },
  props: ["activeBorrowed", "minNav", "textWhite"],
};
</script>
