<template>
  <div class="d-flex flex-column">
    <h6 class="mb-3 text-sm">{{ lender.client_full_name }}</h6>
    <span class="mb-2 text-xs">
      Loan Limit:
      <span class="text-dark font-weight-bold ms-sm-2">
        {{ lender.lending_limit }}</span
      >
    </span>
    <span class="mb-2 text-xs">
      Average Lending Rate:
      <span class="text-dark ms-sm-2 font-weight-bold">
        {{ lender.lending_rate }}</span
      >
    </span>
    <span class="text-xs">
      Average Time to Reply:
      <span class="text-dark ms-sm-2 font-weight-bold"
        >{{ lender.time_to_reply }} hour(s)</span
      >
    </span>
  </div>
  <div class="ms-auto text-end">
    <soft-button
      class="mb-2"
      variant="gradient"
      color="success"
      full-width
      data-bs-toggle="modal"
      :data-bs-target="'#modal-lender-borrow_' + lender.id"
      >Borrow
    </soft-button>
  </div>

  <!-- Lender Borrow Modal -->
  <div class="">
    <div
      class="modal fade"
      :id="'modal-lender-borrow_' + lender.id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <form @submit.prevent="handleBorrowLoan" class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h6 class="modal-title" id="modal-title-lender-borrow">
              Borrow from {{ lender.client_full_name }}
            </h6>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="example-text-input" class="form-control-label"
              >Amount
              <small class="text-primary"
                >(Lender limit: {{ lender.lending_limit }})</small
              ></label
            >
            <input
              class="form-control"
              type="number"
              placeholder="KES"
              v-model="form.amount"
              id="example-text-input"
              @keyup="checkAmountInput($event)"
            />
            <p class="text-sm text-danger" v-if="amtError">
              You cannot borrow more than the lender's limit
            </p>
            <label for="example-text-input" class="form-control-label"
              >Interest (%)</label
            >
            <input
              class="form-control"
              type="number"
              placeholder="5"
              v-model="form.interest"
              id="example-text-input"
            />
            <label for="example-text-input" class="form-control-label"
              >Date Payable</label
            >
            <input
              class="form-control"
              type="date"
              v-model="form.date_payable"
              id="example-text-input"
            />
            <label
              style="margin-top: 1rem"
              for="example-text-input"
              class="form-control-label"
              >Add Guarantors</label
            >
            <p class="form-group">
              <soft-button
                class="mb-2"
                variant="gradient"
                type="button"
                color="dark"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGuarantor"
                aria-expanded="false"
                aria-controls="collapseGuarantor"
              >
                Click to Add Guarantors
              </soft-button>
            </p>
          </div>
          <div
            style="margin-top: -2rem"
            class="collapse"
            id="collapseGuarantor"
          >
            <div
              style="margin-left: -0.5rem; margin-right: -0.5rem"
              class="card-body"
            >
              <label>Search Guarantors</label>
              <input
                class="form-control mb-2"
                type="text"
                ref="guarantorCheck"
                id="example-text-input"
              />
              <small class="text-danger" v-if="guarantorAvailability != ''">
                {{ guarantorAvailability }}
              </small>
            </div>
            <p style="font-size: 12px; padding-left: 1rem; margin-top: -1rem">
              If you don't have any guarantors click on
              <strong>Invite guarantor button</strong>
            </p>
            <div class="modal-footer" style="border-top: none">
              <soft-button
                class="mb-2"
                variant="gradient"
                color="success"
                type="button"
                @click="clientLookUp(this.$refs['guarantorCheck'].value)"
                fullWidth
                >Add Guarantor</soft-button
              >
              <soft-button
                v-if="guarantorAvailability != ''"
                class="mb-2"
                variant="gradient"
                color="dark"
                type="button"
                fullWidth
                >Invite Guarantor</soft-button
              >
            </div>
          </div>
          <div class="modal-body">
            <label
              style="margin-top: 1rem"
              for="example-text-input"
              class="form-control-label"
              >Guarantors</label
            >
            <ul class="list-unstyled max-width-200">
              <li
                v-for="g in guarantorsList"
                :key="g.id"
                class="w-100 mb-2"
                style="min-width: 300px"
              >
                <span
                  class="text-sm d-flex justify-content-between align-items-center"
                  style="width: 100%"
                >
                  <span class="d-flex w-75 align-items-center">
                    <span style="width: 40px; height: 40px">
                      <img
                        :src="
                          g?.image === null
                            ? './assets/img/avatar.png'
                            : 'https://api.sortika.co.ke' + g?.image
                        "
                        style="
                          width: 40px;
                          height: 40px;
                          object-fit: cover;
                          border-radius: 50%;
                        "
                        alt=""
                      />
                    </span>
                    &nbsp;
                    {{ g.full_name }}
                    <span class="text-dark ms-sm-2 font-weight-bold">
                      {{ g.phone }}
                    </span>
                  </span>
                  <span @click="removeGuarantor(g.id)">
                    <i
                      class="fas fa-times-circle text-danger text-lg cursor-pointer"
                    >
                    </i>
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button
              class="mb-2"
              variant="gradient"
              type="submit"
              color="success"
              >Borrow Now</soft-button
            >
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Close</soft-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
// import Multiselect from "@vueform/multiselect";

export default {
  props: ["lender", "guarantors"],
  data() {
    return {
      form: {},
      amtError: false,
      garantorData: {},
      guarantorsList: [],
      selectedGuarantorsList: [],
      value: [],
      options: this.guarantors,
      guarantorAvailability: "",
    };
  },
  components: {
    SoftButton,
    // Multiselect,
  },

  // beforeMount() {
  //   // this.gaurantorsEval();
  //   let gnts = this.guarantors;
  //   let ids = [];
  //   let opts = [];

  //   gnts.map((gnt) => {
  //     ids.push(gnt.id);
  //     const __name = `${gnt.guarantor_profile.full_name} - ${gnt.guarantor_profile.phone_number}`;
  //     opts.push(__name);
  //   });
  //   this.value = ids;
  //   this.options = opts;

  //   console.log(this.value, this.options);
  // },

  methods: {
    ...mapActions(["borrowLoan", "lookUpClient"]),

    checkAmountInput(evnt) {
      let amt = evnt.target.value;
      if (parseFloat(amt) > parseFloat(this.lender?.lending_limit)) {
        this.amtError = true;
      } else {
        this.amtError = false;
      }
    },

    gaurantorsEval() {
      console.log("hits", this.guarantors);
      let gnts = this.guarantors;
      let ids = [];
      let opts = [];

      gnts.map((gnt) => {
        ids.push(gnt.id);
        opts.push(gnt.guarantor_profile.full_name);
      });
      this.value = ids;
      this.options = opts;

      console.log(this.value, this.options);
    },

    removeGuarantor(id) {
      this.guarantorsList = this.guarantorsList.filter((g) => g.id != id);
    },

    clientLookUp(e) {
      let oldPhone = e;
      let phone = oldPhone;
      let code = "254";

      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        phone = code + phone;
      } else if (phone.startsWith("+")) {
        phone = phone.substring(1);
        // phone = oldPhone;
      } else {
        phone = oldPhone;
      }
      let __phone = { phone_number: phone.toString() };
      this.lookUpClient(__phone);
      this.$refs["guarantorCheck"].value = "";
      this.guarantorAvailability = "";
    },

    handleBorrowLoan() {
      if (
        parseFloat(this.form.amount) > parseFloat(this.lender?.lending_limit)
      ) {
        this.amtError = true;
        this.$toast.error(
          "You cannot borrow more than the lender's maximum limit"
        );
        return;
      }
      let gIdsList = [];
      this.guarantorsList.map((gnt) => {
        let gDict = { client_id: gnt.id };
        gIdsList.push(gDict);
      });
      this.form.guarantors = gIdsList;
      this.form.from = "listed";
      this.form.type = "O2OC";
      this.form.persona = "CLIENT";
      console.log("form", this.form);
      this.borrowLoan(this.form);
    },
  },

  computed: {
    ...mapGetters(["clientFound", "currentUser"]),
  },

  watch: {
    clientFound() {
      this.garantorData = this.clientFound;
      console.log("object", this.clientFound);

      if (this.clientFound.id) {
        if (this.guarantorsList.length > 0) {
          let gIds = [];

          this.guarantorsList.map((gItem) => {
            gIds.push(gItem.id);
          });

          if (gIds.includes(this.clientFound.id) == true) {
            this.guarantorAvailability = "This number already selected";
            return;
          } else if (this.clientFound.id == this.currentUser.id) {
            this.guarantorAvailability =
              "You cannot guarantee yourself a loan.";
            return;
          } else {
            this.guarantorsList = [...this.guarantorsList, this.clientFound];
          }
        } else if (this.clientFound.id == this.currentUser.id) {
          this.guarantorAvailability = "You cannot guarantee yourself a loan.";
          return;
        } else {
          this.guarantorsList = [...this.guarantorsList, this.clientFound];
        }
      } else if (this.clientFound.id == this.currentUser.id) {
        this.guarantorAvailability = "You cannot guarantee yourself a loan.";
        return;
      } else {
        this.$toast.error("Guarantor does not exit!! Confirm the phone number");
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
