<template>
    <nav class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs" id="navbarBlur"
        data-scroll="true">
        <div class="px-3 py-1 container-fluid">
            <div style="display: contents">
                <breadcrumbs :currentPage="currentRouteName" />
                <li style="margin-top: -50px" class="nav-item d-flex hide-on-desktop">
                    <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body" id="iconNavbarSidenav">
                        <div class="sidenav-toggler-inner">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </a>
                </li>
            </div>
            <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
                :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
                <div class="pe-md-3 d-flex align-items-center"
                    :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
                    <div class="input-group">
                    </div>
                </div>
                <ul class="navbar-nav justify-content-end">
                    <li class="nav-item d-flex align-items-center">
                        <soft-button style="padding-left: 40px; padding-right: 40px; margin-right: 5px" class="my-4 mb-2"
                            variant="gradient" color="success" full-width data-bs-toggle="modal"
                            data-bs-target="#modal-deposit">Add
                            Money
                        </soft-button>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <router-link :to="{ name: 'Withdrawal' }">
                            <!-- <soft-button style="
                                              padding-left: 30px;
                                              padding-right: 30px;
                                              margin-right: 10px;
                                            " class="my-4 mb-2" variant="gradient" color="success" full-width>Withdraw
                </soft-button> -->
                            <button href="javascript:;" class="btn btn-outline-secondary ms-2"
                                style="border: 2px solid; padding-left: 30px; padding-right: 30px; margin-bottom: -1rem;">
                                Withdraw
                            </button>
                        </router-link>

                    </li>
                    <div style="margin-left: 0"></div>
                    <li class="nav-item d-xl-none ps-3 d-flex align-items-center hidden-on-mobile hidden-on-tablet">
                        <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body" id="iconNavbarSidenav">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                            </div>
                        </a>
                    </li>
                    <div style="margin-left: 1rem"></div>
                    <!-- <li class="px-3 nav-item d-flex align-items-center">
                <a class="p-0 nav-link" @click="toggleConfigurator" :class="textWhite ? textWhite : 'text-body'">
                  <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
                </a>
              </li> -->
                    <li class="nav-item dropdown d-flex align-items-center"
                        :class="this.$store.state.isRTL ? 'ps-2' : 'pe-2'">
                        <a href="#" class="p-0 nav-link" :class="[
                            textWhite ? textWhite : 'text-body',
                            showMenu ? 'show' : '',
                        ]" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
                            <i class="cursor-pointer fa fa-bell"></i>
                        </a>
                        <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
                            aria-labelledby="dropdownMenuButton">
                            <li class="mb-2" v-for="ntf in getFirstFiveNotifications()" :key="ntf.id">
                                <a class="dropdown-item border-radius-md" href="javascript:;">
                                    <div class="py-1 d-flex">
                                        <!-- <div class="my-auto">
                        <img
                          src="../../assets/img/team-2.jpg"
                          class="avatar avatar-sm me-3"
                          alt="user image"
                        />
                      </div> -->
                                        <div class="d-flex flex-column justify-content-center">
                                            <h6 class="mb-1 text-sm font-weight-normal">
                                                <span class="font-weight-bold">{{ ntf.title }}</span>
                                                from
                                                {{
                                                    ntf.created_by ? ntf.created_by != null : "Sortika"
                                                }}
                                            </h6>
                                            <p class="mb-0 text-xs text-secondary">
                                                <i class="fa fa-clock me-1"></i>
                                                {{ ntf.created_date }}
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <!-- {{ notifications }} -->
    </nav>

    <!-- Deposit Modal -->
    <div class="col-md-4">
        <deposit-modal />
    </div>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SelectGoal from "./Withdrawal/SelectGoal.vue";
// @ts-ignore
// import VueMultiStepForm from "vue-multi-step-form";
import { mapMutations, mapActions, mapGetters } from "vuex";
import DepositModal from "./deposit/DepositModal.vue";

export default {
    name: "navbar",
    data() {
        // stepData: [];
        return {
            showMenu: false,
            selectedOption: "option1",
            checked: "selection1",
            notifications: [],
        };
    },
    props: ["minNav", "textWhite"],
    created() {
        this.minNav;
        this.getNotifications();
    },
    methods: {
        ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
        ...mapActions(["toggleSidebarColor", "getNotifications"]),

        getFirstFiveNotifications() {
            return this.notifications.slice(0, 5);
        },

        toggleSidebar() {
            this.toggleSidebarColor("bg-white");
            this.navbarMinimize();
        },
    },
    components: {
        Breadcrumbs,
        SoftButton,
        // SelectGoal,
        DepositModal,
    },
    computed: {
        ...mapGetters(["notificationsList"]),
        currentRouteName() {
            return this.$route.name;
        },
    },

    watch: {
        notificationsList() {
            this.notifications = this.notificationsList.data;
        },
    },
    updated() {
        const navbar = document.getElementById("navbarBlur");
        window.addEventListener("scroll", () => {
            if (window.scrollY > 10 && this.$store.state.isNavFixed) {
                navbar.classList.add("blur");
                navbar.classList.add("position-sticky");
                navbar.classList.add("shadow-blur");
            } else {
                navbar.classList.remove("blur");
                navbar.classList.remove("position-sticky");
                navbar.classList.remove("shadow-blur");
            }
        });
    },
};
</script>
<style>
input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    height: 70%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    box-shadow: 7px 7px 15px rgba(2, 28, 53, 0.03);
    cursor: pointer;
    outline: none;
    border: 1px solid #e2e6f3;
}

input[type="radio"]:before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background-color: #f9fafd;
    border: 1px solid #e2e6f3;
    border-radius: 50%;
    top: 15px;
    right: 20px;
}

input[type="radio"]:after {
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 19px;
    right: 24.3px;
}

input[type="radio"]:hover {
    transform: scale(1.01);
}

input[type="radio"]:checked {
    border: 3px solid #78519e;
}

input[type="radio"]:checked:after {
    background-color: #78519e;
}

.radio-card {
    height: 150px;
    width: 200px;
    position: relative;
}
</style>
  <!-- <script src="https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/multistep-form.js" type="text/javascript"></script> -->
  
