<template>
  <form style="padding: 2rem" class="form" id="formProtect">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                Protecting my portfolio/capital is more important to me than
                high returns?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        form="formProtect"
                        v-model="formProtect.choice"
                        :value="47"
                        type="radio"
                        name="flexRadioDefault"
                        id="formP2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formP2"
                        >Strongly agree</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formProtect"
                        v-model="formProtect.choice"
                        :value="48"
                        type="radio"
                        name="flexRadioDefault"
                        id="formP3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formP3"
                        >Agree</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formProtect"
                        v-model="formProtect.choice"
                        :value="49"
                        type="radio"
                        name="flexRadioDefault"
                        id="formP4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formP4"
                        >Neutral</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formProtect"
                        v-model="formProtect.choice"
                        :value="50"
                        type="radio"
                        name="flexRadioDefault"
                        id="formP5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formP5"
                        >Disagree</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formProtect"
                        v-model="formProtect.choice"
                        :value="47"
                        type="radio"
                        name="flexRadioDefault"
                        id="formP6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formP6"
                        >Strongly disagree</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getProtectAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formProtect: {},
      step: 11,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getProtectAnswer() {
      console.log("Protect", this.formProtect);
      this.addInvestmentAnswer(this.formProtect);
    },

    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      if (this.invBoarding.success) {
        this.$emit("submitProtect", this.formProtect);
      } else {
        return;
      }
    },
  },
};
</script>
