<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end" style="margin-top: 1rem">
          <!-- <a :href="action.route" data-bs-toggle="modal" data-bs-target="#modal-kyc">
            <i class="text-sm fas fa-user-edit text-secondary" data-bs-toggle="tooltip" data-bs-placement="top"
              :title="action.tooltip"></i>
          </a> -->
          <!-- <button :href="action.route" class="btn btn-outline-secondary ms-2" style="border: 2px solid"
            data-bs-toggle="modal" data-bs-target="#modal-kyc">
            Edit
          </button> -->
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <hr class="my-4 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Full Name:</strong> &nbsp;
          {{ info.fullName }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Mobile:</strong> &nbsp; {{ info.mobile }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Email:</strong> &nbsp; {{ info.email }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Date Of Birth:</strong> &nbsp;
          {{ info.dob }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">ID/Passport Number:</strong> &nbsp;
          {{ info.identityNo }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Nationality:</strong> &nbsp;
          {{ info.nationality }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Location:</strong> &nbsp;
          {{ setLocationVariable() }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Mailing Address (P.O Box):</strong> &nbsp;
          {{ info.mailingAddress }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">KRA Pin:</strong> &nbsp;
          {{ info.kraPin }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Saving Points:</strong> &nbsp;
          {{ profileData.points?.toLocaleString() }}
        </li>
        <li
          style="margin-top: 1rem; margin-bottom: 1rem"
          class="pb-0 border-0 list-group-item ps-0"
        >
          <strong class="text-sm text-dark">Investor Profile:</strong> &nbsp;
          <a
            style="
              text-decoration: underline;
              cursor: pointer;
              color: red !important;
            "
            class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
            data-bs-toggle="collapse"
            data-bs-target="#collapseProfile"
            aria-expanded="false"
            aria-controls="collapseProfile"
          >
            {{ investProfile.name }}
            <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
          </a>
        </li>
        <div class="collapse" id="collapseProfile">
          <div>
            <p class="text-sm">
              {{ investProfile.characteristics }}
            </p>
          </div>
        </div>
      </ul>
      <button
        :href="action.route"
        class="btn btn-outline-secondary ms-2"
        style="border: 2px solid; width: 95%"
        data-bs-toggle="modal"
        data-bs-target="#modal-kyc"
        fullWidth
      >
        Edit Profile
      </button>
    </div>
  </div>

  <!-- KYC Update Modal -->
  <div class="col-md-4">
    <div
      class="modal fade"
      id="modal-kyc"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <form class="modal-content" @submit.prevent="updateKYC">
          <div style="border-bottom: none" class="modal-header">
            <h6 class="modal-title" id="modal-title-kyc">
              Update Personal Information
            </h6>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="example-text-input" class="form-control-label"
              >Select ID type</label
            >
            <select class="dataTable-selector form-control">
              <option>National ID</option>
              <option>Passport</option>
            </select>
            <label for="example-text-input" class="form-control-label"
              >ID/Passport Number</label
            >
            <input
              class="form-control"
              type="text"
              placeholder="2571000"
              id="example-text-input"
              :value="profileData.national_id_number"
              ref="national_id_number"
            />
            <label for="example-text-input" class="form-control-label"
              >Name as it appears on the ID</label
            >
            <input
              class="form-control"
              type="text"
              placeholder="John Doe"
              id="example-text-input"
              :value="profileData.full_name"
              ref="full_name"
            />
            <label for="example-text-input" class="form-control-label"
              >Date of Birth</label
            >
            <input
              class="form-control"
              type="date"
              id="example-text-input"
              :value="profileData.date_of_birth"
              ref="date_of_birth"
            />
            <div>
              <label>Gender</label>
              <div style="width: 100%; height: 80px" class="radio-card">
                <input
                  type="radio"
                  v-model="genderOption"
                  value="M"
                  ref="gender"
                  id="customRadio2"
                />
                <label
                  style="position: absolute; margin: 20px; cursor: pointer"
                  for="customRadio2"
                  >Male</label
                >
              </div>
              <div
                style="width: 100%; height: 80px; margin-top: -1rem"
                class="radio-card"
              >
                <input
                  type="radio"
                  v-model="genderOption"
                  value="F"
                  ref="gender"
                  id="customRadio3"
                />
                <label
                  style="position: absolute; margin: 20px; cursor: pointer"
                  for="customRadio3"
                  >Female</label
                >
              </div>
            </div>
            <label for="example-text-input" class="form-control-label"
              >Email Address</label
            >
            <input
              class="form-control"
              type="email"
              placeholder="johndoe@mail.com"
              id="example-text-input"
              :value="profileData?.email_address"
              ref="email"
            />
            <label for="example-text-input" class="form-control-label"
              >Nationality</label
            >
            <input
              class="form-control"
              type="text"
              placeholder="Kenyan"
              id="example-text-input"
              value="Kenyan"
              ref="nationality"
            />
            <label for="example-text-input" class="form-control-label"
              >Address (Including country of residence)</label
            >
            <input
              class="form-control"
              type="text"
              ref="address"
              :value="profileData?.address"
              placeholder="P.O Box, Nairobi, Kenya"
              id="example-text-input"
            />
            <li
              class="px-0 border-0 list-group-item"
              style="margin-top: 0.5rem"
            >
              <label>Dividend disposable preference</label>
              <soft-checkbox
                id="flexCheckDefault"
                name="flexCheckDefault"
                class="font-weight-light-survey"
              >
                Yes
              </soft-checkbox>
              <soft-checkbox
                id="flexCheckDefault"
                name="flexCheckDefault"
                class="font-weight-light-survey"
              >
                No
              </soft-checkbox>
            </li>
            <label for="example-text-input" class="form-control-label"
              >KRA Pin</label
            >
            <input
              class="form-control"
              type="text"
              id="example-text-input"
              :value="profileData.kra_pin_number"
              ref="kra_pin_number"
            />
            <label for="example-text-input" class="form-control-label"
              >Tax Status</label
            >
            <select class="form-control">
              <option>Residential</option>
              <option>Non-Residential</option>
              <option>Exemptional</option>
            </select>
            <div style="margin-top: 1rem"></div>
            <label for="example-text-input" class="form-control-label"
              >Upload Copy of ID (front & back) or Passport Copy</label
            >
            <vue-dropzone
              class="card-dropzone form-control-dropzone dropzone"
              :include-styling="false"
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              v-on:vdropzone-sending="sendingEvent"
            />
            <div style="margin-top: 1rem"></div>
            <label for="example-text-input" class="form-control-label"
              >Send OTP Code</label
            >
            <div class="d-flex align-items-center">
              <div class="form-group w-70">
                <div class="input-group bg-gray-200">
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    placeholder="2233"
                  />
                  <span
                    class="input-group-text bg-transparent"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="Referral code is reusable"
                    data-bs-original-title="Referral link, copy and share with friends on socials, i.e. facebook, whatsapp, telegram, sms, email, twitter"
                    ><i class="ni ni-key-25"></i
                  ></span>
                </div>
              </div>
              <button
                href="javascript:;"
                class="btn btn-outline-secondary ms-2"
                style="border: 2px solid"
              >
                Generate
              </button>
            </div>
          </div>
          <div style="border-top: none; margin-top: 2rem" class="modal-footer">
            <soft-button
              v-if="!loading"
              type="submit"
              class="mb-2"
              variant="gradient"
              color="success"
              style
            >
              Update Profile
            </soft-button>
            <soft-button
              v-else
              type="disabled"
              class="mb-2"
              variant="gradient"
              color="secondary"
              style
            >
              <i class="fas fas-spin fas-spinner"></i> Updating...
            </soft-button>
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Close</soft-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import { mapActions, mapGetters } from "vuex";
import vueDropzone from "vue2-dropzone-vue3";

export default {
  name: "ProfileInfoCard",
  data() {
    return {
      genderOption: "M",
      form: {},
      loading: false,
      dropzoneOptions: {
        url: "https://sortika.co.ke/post",
        thumbnailWidth: 200,
        maxFilesize: 2,
        addRemoveLinks: true,
        headers: { "My-Awesome-Header": "header value" },
      },
      fomarted_loc: "",
    };
  },
  components: {
    SoftButton,
    SoftCheckbox,
    vueDropzone,
    // FontAwesomeIcon,
  },
  props: {
    profileData: {
      type: Object,
    },
    investProfile: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      dob: String,
      identityNo: String,
      nationality: String,
      location: String,
      mailingAddress: String,
      kraPin: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  created() {
    // this.setLocationVariable();
    var scripts = [
      "@/assets/js/dropzone.min.js",
      "@/assets/js/holder.min.js",
      "@/assets/js/soft-ui-dashboard.min.js?v=1.0.0",
      "@/assets/js/docs.min.js",
      "@/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal("Hello Vue world!!!");
    },

    setLocationVariable() {
      let loc = this.info.location;
      if (typeof loc != "undefined") {
        try {
          let locObject = JSON.parse(loc);
          let fomarted_loc = `${locObject.name}, ${locObject.formatted_address}`;
          return fomarted_loc;
        } catch (error) {
          return loc;
        }
      }
    },

    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
    },

    ...mapActions(["updateProfile"]),

    updateKYC() {
      let kra = this.$refs["kra_pin_number"].value;
      if (kra == "") {
        kra = `${this.profileData.id}_NONE`;
      }

      let fullName = this.$refs["full_name"]?.value;
      let fname = "";
      let lname = "";
      if (fullName) {
        fullName = fullName.split(" ");
        fname = fullName[0];
        lname = fullName[1];
      }

      this.loading = true;

      this.updateProfile({
        gender: this.$refs["gender"].value,
        date_of_birth: this.$refs["date_of_birth"].value,
        email_address: this.$refs["email"].value,
        national_id_number: this.$refs["national_id_number"].value,
        kra_pin_number: kra,
        address: this.$refs["address"].value,
        full_name: fullName,
        first_name: fname,
        last_name: lname,
        // phone_number: this.$refs["phone_number"].value,
        phone_number: this.profileData.phone_number,
        id: this.profileData.id,
      });
    },
  },

  computed: {
    ...mapGetters(["profile", "success"]),
  },
  watch: {
    profile() {
      this.loading = false;
    },
  },
};

// id: this.profileData?.id,
// full_name: this.profileData?.full_name,
// phone_number: this.profileData?.phone_number,
// email: this.profileData?.email,
// date_of_birth: this.profileData?.date_of_birth,
// national_id_number: this.profileData?.national_id_number,
// nationality: "Kenyan",
// location: this.profileData?.address,
// kra_pin_number: this.profileData?.kra_pin_number,
</script>
