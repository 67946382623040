<template>
    <div class="modal fade" id="modal-investment-assistance" tabindex="-1" role="dialog" aria-labelledby="modal-default"
        aria-hidden="true">
        <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
            <div class="modal-content">
                <div id="app">
                    <section class="stepper-modal">
                        <transition name="slide-fade">
                            <section v-show="edit === 1">
                                <div style="border-bottom: none" class="modal-header">
                                    <h5 class="modal-title" id="modal-title-assistance" style="margin-left: 1rem">
                                        Investment Assistant
                                    </h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <div class=" card-body">
                                            <ul class="list-group">
                                                <li class="px-0 border-0 list-group-item">
                                                    <div>
                                                        <label for="example-date-input" class="form-control-label">How much
                                                            are
                                                            you
                                                            targeting?</label>
                                                        <input class="form-control" type="text" value="KES 5,000"
                                                            id="example-text-input">
                                                        <label for="example-date-input" class="form-control-label">End
                                                            Date?</label>
                                                        <input class="form-control" type="date" value="2023-11-23"
                                                            id="example-date-input">
                                                        <h6 style="margin-top:1rem">Description</h6>
                                                        <div class="d-flex pb-3">
                                                            <div>
                                                                <i class="fas fa-check text-success text-sm"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <div class="ps-3">
                                                                <span class="text-sm">Historically, the probability of a
                                                                    negative
                                                                    return
                                                                    in any one year is 17%</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex pb-3">
                                                            <div>
                                                                <i class="fas fa-check text-success text-sm"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <div class="ps-3">
                                                                <span class="text-sm">Recommended investment timeframe is 3
                                                                    to 5
                                                                    years</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex pb-3">
                                                            <div>
                                                                <i class="fas fa-check text-success text-sm"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <div class="ps-3">
                                                                <span class="text-sm">Investment returns over the long-term
                                                                    aim
                                                                    to
                                                                    be
                                                                    CBK Benchmark rate plus 2-3% pa.</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex pb-3">
                                                            <div>
                                                                <i class="fas fa-check text-success text-sm"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <div class="ps-3">
                                                                <span class="text-sm">This investment portfolio is made up
                                                                    of
                                                                    around
                                                                    70%
                                                                    defensive assets and 30% growth assets</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="margin-bottom: 1rem;"></div>
                                                    <div class="form-group">

                                                        <div style="margin-bottom: 1rem;"></div>

                                                        <soft-button class="my-4 mb-2" variant="gradient" fullWidth
                                                            color="success" @click="next">
                                                            Create
                                                        </soft-button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </transition>
                        <transition name="slide-fade">
                            <section v-show="edit === 2">
                                <div style="border-bottom: none" class="modal-header">
                                    <h5 class="modal-title" id="modal-title-assistance" style="margin-left: 1rem">
                                        Portfolio
                                    </h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <div class=" card-body">
                                            <ul class="list-group">
                                                <li class="list-group-item bg-gray-100 border-0">
                                                    <div
                                                        class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                                                        <div class="d-flex flex-column">
                                                            <h6 class="mb-3 text-lg">Portfolio One</h6>
                                                            <span class="mb-2 text-xs">
                                                                Portfolio Returns:
                                                                <span class="text-dark font-weight-bold ms-sm-2">
                                                                    8.60%</span>
                                                            </span>
                                                            <span class="mb-2 text-xs">
                                                                KES
                                                                <span style="font-size: 24px;"
                                                                    class="text-dark ms-sm-2 font-weight-bold">
                                                                    45,683.00</span>
                                                            </span>
                                                            <span class="text-xs">
                                                                Projected 10 yrs return
                                                                <span class="text-dark ms-sm-2 font-weight-bold"></span>
                                                            </span>
                                                        </div>
                                                        <div class="ms-auto text-end">
                                                            <!-- <soft-button class="mb-2" variant="gradient" color="success"
                                                            @click="next">Select
                                                        </soft-button> -->
                                                            <button href="javascript:;" class="btn btn-outline-primary ms-2"
                                                                style="border: 2px solid; box-shadow: none;"
                                                                @click="next">Select
                                                                Portfolio</button>
                                                        </div>
                                                    </div>
                                                    <div class="p-3 card-body">
                                                        <pie-chart id="chart-bar" :chart="{
                                                            labels: [
                                                                'Portfolio',
                                                                'Returns'
                                                            ],
                                                            datasets: {
                                                                label: 'Portfolio',
                                                                data: [90, 10],
                                                            },
                                                        }" />
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="mt-4 row">
                                                <div>
                                                    <div class="card z-index-2">

                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="list-group">
                                                <li class="px-0 border-0 list-group-item">
                                                    <div style="margin-bottom: 1rem;"></div>
                                                    <div class="form-group">

                                                        <div style="margin-bottom: 1rem;"></div>

                                                        <soft-button class="my-4 mb-2" variant="gradient" fullWidth
                                                            color="dark" @click.prevent="prev()">
                                                            Back
                                                        </soft-button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </transition>
                        <transition name="slide-fade">
                            <section v-show="edit === 3">
                                <div style="border-bottom: none" class="modal-header">
                                    <h5 class="modal-title" id="modal-title-assistance" style="margin-left: 1rem">
                                        Portfolio One
                                    </h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <div class="card-body">
                                            <ul class="list-group">
                                                <li class="px-0 border-0 list-group-item">
                                                    <div class="card z-index-2">
                                                        <div class="p-3">
                                                <li
                                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                    <div class="d-flex flex-column">
                                                        <h5 style="margin-top: 0.5rem;"
                                                            class="text-dark mb-1 font-weight-bold text-lg">
                                                            Portfolio One
                                                        </h5>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <soft-button class="mb-2" variant="gradient" color="success">Add
                                                            Portfolio
                                                        </soft-button>
                                                    </div>
                                                </li>
                                                <p class="mb-0" style="font-size: 14px;">This portfolio
                                                    contains investment
                                                    products that suit your
                                                    specific needs
                                                    based on your investment profile. Adding the portfolio will add the
                                                    goals therein as part of your investment goals and will affect your
                                                    daily targets</p>

                                        </div>
                                    </div>
                                    <div style="margin-top: 1rem;" class="card z-index-2">
                                        <div class="p-3">
                                            <li
                                                class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                <div class="d-flex flex-column">
                                                    <h6 class="text-dark mb-1 font-weight-bold text-ms">
                                                        Projection
                                                    </h6>
                                                </div>
                                                <div class="d-flex flex-column">
                                                </div>
                                            </li>
                                            <h6 class="text-dark mb-1 font-weight-bold text-xs">
                                                Hypothetical invest projection of KES 20,000.00 in 10 years
                                            </h6>
                                            <div class="col-lg-5">
                                                <span class="badge badge-dot-portfolio d-block text-start pb-0 mt-3">
                                                    <i class="bg-gradient-success"></i>
                                                    <span class="text-muted text-xs font-weight-bold">Investment: KES
                                                        20,000.00</span>
                                                </span>
                                                <span class="badge badge-dot-portfolio d-block text-start">
                                                    <i class="bg-gradient-dark"></i>
                                                    <span class="text-muted text-xs font-weight-bold">Returns: KES
                                                        46,683.00</span>
                                                </span>
                                            </div>
                                            <div>

                                                <div class="z-index-2">
                                                    <portfolio-chart id="chart-line" :chart="{
                                                        labels: [
                                                            '2014',
                                                            '2015',
                                                            '2016',
                                                            '2017',
                                                            '2018',
                                                            '2019',
                                                            '2020',
                                                            '2021',
                                                            '2022',
                                                            '2023',
                                                        ],
                                                        datasets: [
                                                            {
                                                                label: 'Investment',
                                                                data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                                                            },
                                                            {
                                                                label: 'Returns',
                                                                data: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200],

                                                            },
                                                        ],
                                                    }" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <li
                                            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div class="d-flex flex-column">
                                                <h6 style="margin-left: 1rem;margin-top: 0.5rem;"
                                                    class="text-dark mb-1 font-weight-bold text-ms">
                                                    Suggestions
                                                </h6>
                                            </div>
                                            <div class="d-flex flex-column">
                                            </div>
                                        </li>
                                        <suggestions-card />
                                    </div>
                                    <div class="form-group">
                                        <div style="margin-bottom: 1rem;"></div>
                                        <soft-button class="my-4 mb-2" variant="gradient" fullWidth color="dark"
                                            @click.prevent="prev()">
                                            Back
                                        </soft-button>
                                    </div>
                                    </li>
                                    </ul>
                                </div>
                </div>
            </div>
            </section>
            </transition>
            </section>
        </div>
    </div>
    </div>
    </div>
</template>

<script>
// import SoftInput from "@/components/SoftInput.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import PieChart from "@/examples/Charts/PieChart.vue";
import PortfolioChart from "@/examples/Charts/PortfolioChart.vue";
import SuggestionsCard from "./SuggestionsCard.vue"

export default {
    name: "investment-assistance-modal",
    components: {
        // SoftInput,
        // SoftCheckbox,
        SoftButton,
        PieChart,
        PortfolioChart,
        SuggestionsCard,
    },
    el: "#app",
    data() {
        return {
            goalOptionModal: 'goal1Modal',
            edit: 1,
        };
    },
    methods: {
        prev() {
            this.edit--;
        },

        next() {
            this.edit++;
        },
    }
};
</script>
