<template>
  <div class="py-4 container-fluid">
    <div v-if="status == true">
      <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="mb-4 card">
            <div class="p-3 card-body" style="padding: 1.1rem !important">
              <p class="mb-0 text-sm text-capitalize font-weight-bold">
                Credit Score
              </p>
              <h5 class="mb-0 font-weight-bolder">
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="
                    parseInt(
                      Number(
                        loanProfile.credit_score != 'NaN'
                          ? loanProfile.credit_score
                          : 0
                      )
                    )
                  "
                  :duration="3"
                  separator=","
                  decimalSeparator="."
                  :decimals="2"
                  :autoinit="true"
                />
              </h5>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="mb-4 card">
            <div class="p-3 card-body" style="padding: 1.1rem !important">
              <p class="mb-0 text-sm text-capitalize font-weight-bold">
                Loan Limit
              </p>
              <h5 class="mb-0 font-weight-bolder">
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="
                    parseInt(
                      Number(loanProfile.limit != 'NaN' ? loanProfile.limit : 0)
                    )
                  "
                  :duration="3"
                  prefix="KES "
                  separator=","
                  decimalSeparator="."
                  :decimals="2"
                  :autoinit="true"
                />
              </h5>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="mb-4 card">
            <div class="p-3 card-body" style="padding: 1.1rem !important">
              <p class="mb-0 text-sm text-capitalize font-weight-bold">
                Limit Growth Rate
              </p>
              <h5 class="mb-0 font-weight-bolder">
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="loanProfile?.limit_growth"
                  :duration="3"
                  separator=","
                  decimalSeparator="."
                  :decimals="2"
                  :autoinit="true"
                />
              </h5>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="mb-4 card">
            <div class="p-3 card-body" style="padding: 1.1rem !important">
              <p class="mb-0 text-sm text-capitalize font-weight-bold">
                Limit Base Growth
              </p>
              <h5 class="mb-0 font-weight-bolder">
                <vue3-autocounter
                  ref="counter"
                  :startAmount="0"
                  :endAmount="parseInt(Number(loanProfile.base_growth))"
                  :duration="3"
                  prefix="KES "
                  separator=","
                  decimalSeparator="."
                  :decimals="2"
                  :autoinit="true"
                />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="status == false">
      <h5 class="mb-0 font-weight-bolder">Kindly Complete Profile KYC</h5>
      <form class="modal-content" @submit.prevent="updateKYC">
        <div style="border-bottom: none" class="modal-header">
          <h6 class="modal-title" id="modal-title-kyc">
            Update Personal Information
          </h6>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="example-text-input" class="form-control-label"
            >Select ID type</label
          >
          <select class="dataTable-selector form-control">
            <option>National ID</option>
            <option>Passport</option>
          </select>
          <label for="example-text-input" class="form-control-label"
            >ID/Passport Number</label
          >
          <input
            class="form-control"
            type="text"
            placeholder="2571000"
            id="example-text-input"
            :value="profileData.national_id_number"
            ref="national_id_number"
          />
          <label for="example-text-input" class="form-control-label"
            >Name as it appears on the ID</label
          >
          <input
            class="form-control"
            type="text"
            placeholder="John Doe"
            id="example-text-input"
            :value="profileData.full_name"
            ref="full_name"
          />
          <label for="example-text-input" class="form-control-label"
            >Date of Birth</label
          >
          <input
            class="form-control"
            type="date"
            id="example-text-input"
            :value="profileData.date_of_birth"
            ref="date_of_birth"
          />
          <div>
            <label>Gender</label>
            <div style="width: 100%; height: 80px" class="radio-card">
              <input
                type="radio"
                v-model="genderOption"
                value="M"
                ref="gender"
                id="customRadio2"
              />
              <label
                style="position: absolute; margin: 20px; cursor: pointer"
                for="customRadio2"
                >Male</label
              >
            </div>
            <div
              style="width: 100%; height: 80px; margin-top: -1rem"
              class="radio-card"
            >
              <input
                type="radio"
                v-model="genderOption"
                value="F"
                ref="gender"
                id="customRadio3"
              />
              <label
                style="position: absolute; margin: 20px; cursor: pointer"
                for="customRadio3"
                >Female</label
              >
            </div>
          </div>
          <label for="example-text-input" class="form-control-label"
            >Email Address</label
          >
          <input
            class="form-control"
            type="email"
            placeholder="johndoe@mail.com"
            id="example-text-input"
            :value="profileData?.email_address"
            ref="email"
          />
          <label for="example-text-input" class="form-control-label"
            >Nationality</label
          >
          <input
            class="form-control"
            type="text"
            placeholder="Kenyan"
            id="example-text-input"
            value="Kenyan"
            ref="nationality"
          />
          <label for="example-text-input" class="form-control-label"
            >Address (Including country of residence)</label
          >
          <input
            class="form-control"
            type="text"
            ref="address"
            :value="profileData?.address"
            placeholder="P.O Box, Nairobi, Kenya"
            id="example-text-input"
          />
          <li class="px-0 border-0 list-group-item" style="margin-top: 0.5rem">
            <label>Dividend disposable preference</label>
            <soft-checkbox
              id="flexCheckDefault"
              name="flexCheckDefault"
              class="font-weight-light-survey"
            >
              Yes
            </soft-checkbox>
            <soft-checkbox
              id="flexCheckDefault"
              name="flexCheckDefault"
              class="font-weight-light-survey"
            >
              No
            </soft-checkbox>
          </li>
          <label for="example-text-input" class="form-control-label"
            >KRA Pin</label
          >
          <input
            class="form-control"
            type="text"
            id="example-text-input"
            :value="profileData.kra_pin_number"
            ref="kra_pin_number"
          />
          <label for="example-text-input" class="form-control-label"
            >Tax Status</label
          >
          <select class="form-control">
            <option>Residential</option>
            <option>Non-Residential</option>
            <option>Exemptional</option>
          </select>
          <div style="margin-top: 1rem"></div>
          <label for="example-text-input" class="form-control-label"
            >Upload Copy of ID (front & back) or Passport Copy</label
          >
          <vue-dropzone
            class="card-dropzone form-control-dropzone dropzone"
            :include-styling="false"
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            v-on:vdropzone-sending="sendingEvent"
          />
          <div style="margin-top: 1rem"></div>
          <label for="example-text-input" class="form-control-label"
            >Send OTP Code</label
          >
          <div class="d-flex align-items-center">
            <div class="form-group w-70">
              <div class="input-group bg-gray-200">
                <input
                  class="form-control form-control-sm"
                  type="number"
                  placeholder="2233"
                />
                <span
                  class="input-group-text bg-transparent"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  aria-label="Referral code is reusable"
                  data-bs-original-title="Referral link, copy and share with friends on socials, i.e. facebook, whatsapp, telegram, sms, email, twitter"
                  ><i class="ni ni-key-25"></i
                ></span>
              </div>
            </div>
            <button
              href="javascript:;"
              class="btn btn-outline-secondary ms-2"
              style="border: 2px solid"
            >
              Generate
            </button>
          </div>
        </div>
        <div style="border-top: none; margin-top: 2rem" class="modal-footer">
          <soft-button
            v-if="!loading"
            type="submit"
            class="mb-2"
            variant="gradient"
            color="success"
            style
          >
            Update Profile
          </soft-button>
          <soft-button
            v-else
            type="disabled"
            class="mb-2"
            variant="gradient"
            color="secondary"
            style
          >
            <i class="fas fas-spin fas-spinner"></i> Updating...
          </soft-button>
          <soft-button
            type="button"
            class="mb-2"
            color="warning"
            data-bs-dismiss="modal"
            >Close</soft-button
          >
        </div>
      </form>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue3Autocounter from "vue3-autocounter";

export default {
  name: "LoanLimit",
  components: {
    Vue3Autocounter,
    // Navbaronboarding,
    // AppFooter,
    // SoftInput,
    // SoftButton,
    // BootstrapDropdown,
  },
  data() {
    return {
      form: {},
      status: null,
      profileData: {},
      loanProfile: {},
    };
  },

  created() {
    this.getProfile();
    this.getLoanProfile();
    // this.setPlace();
  },
  methods: {
    ...mapActions(["getProfile", "getLoanProfile"]),
  },

  computed: {
    ...mapGetters(["profile", "loan_profile"]),
  },

  watch: {
    profile() {
      this.status = this.profile.is_verified;
      this.profileData = this.profile;
    },

    loan_profile() {
      this.loanProfile = this.loan_profile;
    },
  },
};
</script>

<style></style>
