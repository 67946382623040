<template>
  <div class="py-4 container-fluid" v-if="goals">
    <h6>Active Fixed Goals</h6>

    <div class="mt-4 row" v-if="goals.length > 0">
      <div class="col-lg-4 mb-2" v-for="goal in goals" :key="goal.id">
        <fixed-savings-goal-card :goal="goal" :goalItems="goals" />
      </div>
    </div>
    <div v-else class="mt-4 row">
      <div class="col-lg-4 mb-2">
        <i
          style="
            color: gray;
            font-size: 2rem !important;
            padding-bottom: 3rem !important;
          "
          class="fa fa-chain-broken text-sm ms-1"
          aria-hidden="true"
        ></i
        >Nothing Here!!
      </div>
    </div>
  </div>
  <div
    style="
      margin-top: 2rem;
      margin-bottom: -3rem !important;
      margin-left: 1.5rem !important;
      display: flex;
      justify-content: space-between;
    "
    v-if="savingItems"
  >
    <h6>Recommendations</h6>
    <div
      class="pe-md-3 d-flex align-items-center"
      :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
    >
      <div class="input-group">
        <span
          style="border: none; background-color: #d2d6da57"
          class="input-group-text text-body"
          ><i class="fas fa-search" aria-hidden="true"></i
        ></span>
        <input
          type="text"
          class="form-control"
          placeholder="Search Fixed Goals"
        />
      </div>
    </div>
  </div>
  <div
    style="
      margin-top: 3.5rem;
      margin-bottom: -1rem !important;
      margin-left: 1.5rem !important;
    "
  >
    <div class="mt-4 row">
      <div class="col-lg-4 mb-2" v-for="rgoal in savingItems" :key="rgoal.id">
        <recommended-fixed-goal-card :rgoal="rgoal" />
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import FixedSavingsGoalCard from "../components/FixedSavingsGoalCard.vue";
import RecommendedFixedGoalCard from "../components/RecommendedFixedGoalCard.vue";
// import   rtsBarChart from "@/examples/Cha  ReportsBarChart
// import Gra    ineChart from  exam  /Charts/GradientLiCharvue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "fixed-goals-tab",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  props: ["savingItems", "goals"],
  components: {
    FixedSavingsGoalCard,
    RecommendedFixedGoalCard,
  },
  mounted() {
    setTooltip();
  },
};
</script>
