<template>
  <navbaronboarding btn-background="bg-gradient-primary" />
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
                                                <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
                                                <p class="text-white text-lead">
                                                  Save, Invest, Borrow & Lend.
                                                </p>
                                              </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Personal Information</h5>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="addPersonalInfo">
              <div class="mb-3">
                <div>
                  <label>Gender</label>
                  <div style="width: 100%; height: 80px" class="radio-card">
                    <input type="radio" v-model="form.gender" value="M" id="customRadio2" />
                    <label style="position: absolute; margin: 20px; cursor: pointer" for="customRadio2">Male</label>
                  </div>
                  <div style="width: 100%; height: 80px; margin-top: -1rem" class="radio-card">
                    <input type="radio" v-model="form.gender" value="F" id="customRadio3" />
                    <label style="position: absolute; margin: 20px; cursor: pointer" for="customRadio3">Female</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label>Date of Birth</label>
                <div style="display: flex">
                  <input class="form-control form-control-sm" id="day" type="number" placeholder="Day" min="1" max="31"
                    v-model="form.day" style="width: 33%" />
                  <div style="margin-left: 10px"></div>
                  <input class="form-control form-control-sm" id="month" type="number" placeholder="Month" min="1"
                    max="12" v-model="form.month" style="width: 33%" />
                  <div style="margin-left: 10px"></div>
                  <input class="form-control form-control-sm" id="year" type="number" placeholder="Year"
                    :max="maxYear().toString()" v-model="form.year" style="width: 33%" />
                </div>
              </div>
              <div class="mb-3">
                <label>Your Location</label>
                <!-- <input class="form-control form-control-sm" id="text" type="search" v-model="form.address"
                  placeholder="address..." aria-label="Location" /> -->
                <GMapAutocomplete class="form-control form-control-sm" :select-first-on-enter="true"
                  placeholder="Search..." id="address" type="text" @place_changed="setPlace" v-model="form.address"
                  :options="{ fields: ['name', 'formatted_address'] }">
                </GMapAutocomplete>
                <p style="font-size: 0.7rem; margin-top: 0.5rem">
                  Search by a near by landmark i.e School, Hospital, Market
                  e.t.c
                </p>
                <!-- <option v-for="item in suggestions" :key="item.place_id" v-text="item.description" :options="{ fields: ['geometry', 'address_components'] }"/> -->
                <!-- <bulma-dropdown options="options" @select="selected = $event" placeholder="Search" /> -->
              </div>
              <!-- <div class="mb-3">
                            <bootstrap-dropdown :options="options" @select="selected = $event" placeholder="Search" />
                          </div> -->
              <div class="text-center">
                <!-- <router-link :to="{ name: 'Quick Survey' }"> -->
                <soft-button type="submit" color="success" full-width variant="gradient"
                  class="my-4 mb-2">Next</soft-button>
                <!-- </router-link> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>


<script>
import Navbaronboarding from "@/examples/PageLayout/Navbaronboarding.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbaronboarding,
    AppFooter,
    // SoftInput,
    SoftButton,
    // BootstrapDropdown,
  },
  data() {
    return {
      form: {},
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.setForm();
    this.maxYear();
    // this.setPlace();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    setPlace(address) { this.form.address = JSON.stringify(address) },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["updateProfileOnboarding"]),

    maxYear() {
      const _current_year = new Date().getFullYear();
      const max_year = _current_year - 18;
      return max_year;
    },
    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
          gender: "",
          day: "",
          month: "",
          year: this.maxYear(),
          address: "",
        };
    },

    setDate(day, month, year) {
      let d = day;
      let m = month;
      let y = year;

      if (d < 10) {
        d = "0" + day;
      }
      if (m < 10) {
        m = "0" + m;
      }
      return y + "-" + m + "-" + d;
    },

    addPersonalInfo() {
      if (this.form.day > 31 || this.form.day < 1) {
        this.$toast.error("wrong day input");
        console.log("wrong day input");
        return;
      }
      if (this.form.month > 12 || this.form.month < 1) {
        this.$toast.error("wrong month input");
        console.log("wrong month input");
        return;
      }
      if (this.form.year > this.maxYear()) {
        this.$toast.error("you must atleast 18 years");
        console.log("you must atleast 18 years");
        return;
      }

      let d = this.form.day;
      let m = this.form.month;
      let y = this.form.year;

      if (d < 10) {
        d = "0" + d;
      }
      if (m < 10) {
        m = "0" + m;
      }

      const date_of_birth = y + "-" + m + "-" + d;

      this.loading = true;
      this.updateProfileOnboarding({
        address: this.form.address,
        gender: this.form.gender,
        date_of_birth: date_of_birth,
        id: this.currentUser.id,
        step: "personal",
      });
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters(["profile", "currentUser", "success"]),
  },

  watch: {
    success() {
      if (this.success) {
        this.$router.push({ name: "Quick Survey" });
      } else {
        this.loading = false;
        this.$toast.error("Something Went Wrong");
        return;
      }
    },
  },
};
</script>
