<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navtips btn-background="bg-gradient-primary" />
      </div>
    </div>
  </div>
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pb-0 card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">
              Create Custom Goal
            </h3>
            <p class="mb-0">How long do you want to lock the savings?</p>
          </div>
          <div class="card-body">
            <div>
              <div class="pb-0 text-explainer">
                <div class="mb-3">
                  <soft-checkbox
                    id="flexCheckDefault"
                    name="flexCheckDefault"
                    class="font-weight-light-survey"
                  >
                    Yes (Goal redeemed in 48 hours)
                  </soft-checkbox>
                  <soft-checkbox
                    id="flexCheckDefault"
                    name="flexCheckDefault"
                    class="font-weight-light-survey"
                  >
                    No (Instant withdrawal)
                  </soft-checkbox>
                </div>
              </div>
              <div style="display: flex">
                <!-- <router-link style="width: 100%" :to="{ name: 'Savings' }"> -->
                <soft-button
                  v-if="!loading"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="success"
                  full-width
                  @click="addGoal"
                  >Complete
                </soft-button>
                <soft-button
                  v-else
                  class="my-4 mb-2"
                  variant="gradient"
                  color="success"
                  full-width
                  type="disabled"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </soft-button>

                <!-- </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";

const body = document.getElementsByTagName("body")[0];
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Custom Interest",
  components: {
    Navtips,
    AppFooter,
    SoftButton,
    // SoftInput,
    SoftCheckbox,
  },

  data() {
    return {
      loading: false,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["createCustomSavingGoal"]),

    addGoal() {
      let name = localStorage.getItem("sortikaOnboardingGoalName");
      let target = localStorage.getItem("sortikaOnboardingGoalTarget");
      let date = localStorage.getItem("sortikaOnboardingGoalDate");

      console.log(name, target, date);
      const form = {
        name: name,
        target_amount: target,
        end_date: date,
        type: "SAVC",
        terms: "",
        client: this.currentUser.id,
      };
      this.loading = true;
      this.createCustomSavingGoal([form]);
    },
  },

  computed: {
    ...mapGetters(["currentUser", "getGoalSavedStatus"]),
  },

  watch: {
    getGoalSavedStatus() {
      if (this.getGoalSavedStatus) {
        this.loading = false;
        localStorage.removeItem("sortikaOnboardingGoalName");
        localStorage.removeItem("sortikaOnboardingGoalTarget");
        localStorage.removeItem("sortikaOnboardingGoalDate");

        this.$router.push({ name: "Savings" });
      } else {
        this.loading = false;

        this.$toast.error("Failed to save the goal");
        return;
      }
    },
  },
};
</script>
