<template>
  <!-- BYOB Modal -->
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex justify-content-between">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ goal?.name }}
              <span class="text-info">
                ({{
                  parseFloat(
                    (
                      (parseFloat(goal.saved_amount) * 100) /
                      parseFloat(goal.target_amount)
                    ).toFixed(2)
                  )
                }}%)
              </span>
            </h6>
            <!-- <span> -->
            <span class="text-xs text-danger" v-if="!goal.status">
              &nbsp;|&nbsp;disabled</span
            >
            <span class="text-xs text-primary" v-if="goal.extended_to != null">
              &nbsp;|&nbsp; Extended to: {{ goal.extended_to }}</span
            >
            <!-- </span> -->
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Savings Progress
            </h6>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <div style="margin-bottom: 1rem">
          <soft-progress
            color="success"
            variant="gradient"
            :percentage="
              parseFloat(
                (
                  (parseFloat(goal.saved_amount) * 100) /
                  parseFloat(goal.target_amount)
                ).toFixed(2)
              )
            "
          />
        </div>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">Allocation</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ goal?.allocation }}%
            </h6>
          </div>
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">Saved</h6>
            <span class="text-xs"
              >{{ goal.saved_amount }} of {{ goal.target_amount }}</span
            >
          </div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">End Date</span>
            <h6
              style="color: red !important"
              class="text-dark mb-1 font-weight-bold text-sm"
            >
              {{ goal.end_date }}
            </h6>
          </div>
          <div class="d-flex flex-column">
            <span class="text-xs">Remaining Day(s)</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ goal.remaining_days }}
            </h6>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <div>
          <soft-button
            style="box-shadow: none"
            variant="gradient"
            color="success"
            fullWidth
            data-bs-toggle="modal"
            :data-bs-target="'#allGoalDetailsModal_' + goal.id"
            >More Details
          </soft-button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          :id="'allGoalDetailsModal_' + goal.id"
          tabindex="-1"
          role="dialog"
          aria-labelledby="fixedGoalDetailsModalTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div id="app">
                <section class="stepper-modal">
                  <transition name="slide-fade">
                    <section v-show="edit === 1" v-if="tab === 'view-byob'">
                      <div style="border-bottom: none" class="modal-header">
                        <h5
                          class="modal-title"
                          :id="'fixedGoalDetailsModalLabel' + goal.id"
                        >
                          {{ goal.name }}
                        </h5>
                        <button
                          type="button"
                          class="btn-close text-dark"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Daily Target</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.daily_target }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Saved Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.saved_amount }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Interest Earned</span>
                            <h6
                              style="color: #03da92 !important"
                              class="text-dark mb-1 font-weight-bold text-sm"
                            >
                              {{ goal.earned_amount }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Target Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.target_amount.toLocaleString() }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Allocation</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.allocation }}%
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Remaining Day(s)</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.remaining_days }} day(s)
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">End Date</span>
                            <h6
                              style="color: red !important"
                              class="text-dark mb-1 font-weight-bold text-sm"
                            >
                              {{ goal.end_date }}
                            </h6>
                          </div>
                        </li>
                        <p>
                          <a
                            style="text-decoration: underline"
                            class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                            data-bs-toggle="collapse"
                            href="#collapseGoalTerms"
                            aria-expanded="false"
                            aria-controls="collapseGoalTerms"
                          >
                            Goal Terms
                            <i
                              class="fas fa-arrow-right text-sm ms-1"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </p>
                        <div class="collapse" id="collapseGoalTerms">
                          <div class="card card-body">
                            {{ goal.terms }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="radio-btn-group">
                <div class="radio">
                  <input style="height: 10px; padding-bottom: 3rem;margin-bottom: 5rem; width: 48%" type='radio'
                    name='radio' value='click_me' checked v-model="checked">
                  <label for="click_me">Click Me</label>
                  <input style="height: 10px; padding-bottom: 3rem;width: 48%; margin-left: 12rem;" type='radio'
                    name='radio' value='or_met' v-model="checked">
                  <label for="or_me">Or Me</label>
                  <h5 class="show m-t-2">Currently checked value:</h5>
                  <span>{{ checked }}</span>
                </div>
              </div> -->
                      <!-- <div>
                <edit-byob v-if="tab === 'edit-byob'" />
                <redeem-byob v-if="tab === 'redeem-byob'" />
              </div> -->
                      <div style="border-top: none" class="modal-footer">
                        <!-- <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2" variant="gradient"
                  color="success" fullWidth @click="tab = tab === 'redeem-byob' ? 'edit-byob' : 'redeem-byob'"
                  v-if="!isHidden" v-on:click="isHidden = true">Edit</soft-button> -->
                        <soft-button
                          type="radio"
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'edit-byob' ? 'view-byob' : 'edit-byob'
                          "
                        >
                          Edit
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'redeem-byob'
                                ? 'view-byob'
                                : 'redeem-byob'
                          "
                        >
                          Redeem
                        </soft-button>

                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'transfer-byob'
                                ? 'view-byob'
                                : 'transfer-byob'
                          "
                        >
                          Transfer
                        </soft-button>

                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="secondary"
                          @click.prevent="disable"
                          fullWidth
                        >
                          <span v-if="goal.status">Disable</span>
                          <span v-else>Enable</span>
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="danger"
                          @click="deletion"
                        >
                          Delete
                        </soft-button>
                        <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </soft-button>
                      </div>
                    </section>
                  </transition>
                  <transition name="slide-fade">
                    <section v-show="edit === 2">
                      <form
                        style="padding: 2rem"
                        class="form"
                        v-if="tab === 'edit-byob'"
                        @submit.prevent="updateGoal"
                      >
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Goal Name</label>
                            <input
                              id="goalname"
                              type="text"
                              v-model="form.name"
                              placeholder="Goal Name"
                              aria-label="Goal Name"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Total Amount</label>
                            <input
                              id="cpassword"
                              type="number"
                              v-model="form.target_amount"
                              placeholder="KES"
                              aria-label="Target Amount"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>End Date</label>
                            <input
                              id="date"
                              type="date"
                              placeholder=""
                              v-model="form.end_date"
                              aria-label="End Date"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob' ? 'edit-byob' : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            <span v-if="loading"
                              ><i class="fas fa-spin fa-spinner"></i
                            ></span>
                            <span v-else>Submit</span>
                          </soft-button>
                        </div>
                      </form>

                      <form
                        style="padding: 2rem"
                        class="form"
                        v-if="tab === 'redeem-byob'"
                        @submit.prevent="redeemGoal"
                      >
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter the amount you want to redeem</label>
                            <input
                              id="goalname"
                              type="number"
                              placeholder="KES"
                              aria-label="Goal Name"
                              class="form-control"
                              v-model="formRedeem.amount"
                            />
                            <p style="color: #0ebb81; font-size: 12px">
                              Maximum you can redeem is KES
                              {{ goal?.saved_amount }}
                            </p>
                            <label style="text-align: center; font-weight: 400"
                              >Redemption request shall be processed within
                              <strong style="color: #0ebb81">48 hours</strong>
                              to consolidate funds from various underlying
                              investments</label
                            >
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob'
                                  ? 'redeem-byob'
                                  : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            Submit
                          </soft-button>
                        </div>
                      </form>

                      <form
                        style="padding: 2rem"
                        class="form"
                        v-if="tab === 'transfer-byob'"
                        @submit.prevent="transferGoal"
                      >
                        <label
                          >What is
                          <strong
                            style="color: #0ebb81"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Moving funds between goals"
                            data-bs-placement="top"
                            title="Moving funds between goals"
                            class="cursor-pointer"
                            data-animation="true"
                            >goal transfer?</strong
                          >
                        </label>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Select goal</label>
                            <select
                              v-model="formTrans.goal_to"
                              id="destination-goal"
                              @change="goalAmount($event)"
                              class="form-control"
                              required
                            >
                              <option disabled selected>
                                ---Select Goal---
                              </option>
                              <option
                                v-for="goalItem in goalItems"
                                :key="goalItem.id"
                                :value="goalItem.id"
                              >
                                {{
                                  goalItem.name
                                    ? goalItem.name
                                    : goalItem.type_display
                                }}
                              </option>
                            </select>
                            <p class="text-info mt-1 text-xs rounded">
                              {{ goalItemSavedAmount }}
                            </p>
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter Amount</label>
                            <input
                              id="cpassword"
                              v-model="formTrans.amount"
                              type="number"
                              placeholder="KES"
                              aria-label="Target Amount"
                              class="form-control"
                            />
                            <p style="color: #0ebb81; font-size: 12px">
                              Maximum you can redeem is KES
                              {{ goal.saved_amount }}
                            </p>
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob'
                                  ? 'transfer-byob'
                                  : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            Submit
                          </soft-button>
                        </div>
                      </form>
                    </section>
                  </transition>
                </section>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftProgress from "@/components/SoftProgress";
import setTooltip from "@/assets/js/tooltip.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BYOBGoalCard",
  components: {
    SoftProgress,
    SoftButton,
  },
  props: ["goal"],

  el: "#app",
  data: () => {
    return {
      steps: {},
      edit: 1,
      tab: "view-byob",
      goalItemSavedAmount: "",
      form: {},
      goalItems: [],
      formTrans: {},
      formDisable: {},
      formDelete: {},
      formRedeem: {},
      loading: false,
      isHidden: false,
      components: [
        {
          name: "Edit BYOB",
          component: "edit-byob",
        },
        {
          name: "Redeem BYOB",
          component: "redeem-byob",
        },
        {
          name: "Transfer BYO",
          component: "transfer-byob",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    ...mapActions([
      "deleteGoal",
      "editGoal",
      "disableGoal",
      "makeGoalRedemption",
      "makeTransferGoal",
      "fetchGoals",
    ]),
    updateGoal() {
      this.loading = true;
      this.editGoal(this.form);
    },

    redeemGoal() {
      this.formRedeem.goal = this.goal.id;
      this.makeGoalRedemption(this.formRedeem);
    },

    transferGoal() {
      if (
        parseFloat(this.formTrans.amount) >
          parseFloat(this.goal.saved_amount) ||
        parseFloat(this.formTrans.amount) < 1
      ) {
        this.$toast.error(
          "Amount enter exceeds the saved amount or less than 1"
        );
        return;
      } else {
        this.loadingForm = true;
        this.formTrans.goal_from = this.goal
          ? this.goal.id
          : this.formTrans.goal_from;
        this.formTrans.group = this.goal.group_goal ? this.goal.group_goal : "";
        this.makeTransferGoal(this.formTrans);
      }
    },

    goalAmount(e) {
      let __id = e.target.value;
      this.goalItems.forEach((item) => {
        if (item.id === parseInt(__id)) {
          this.goalItemSavedAmount = `The ' ${
            item.name ? item.name : item.type_display
          } ' goal has a total of KES ${item.saved_amount} amount saved`;
        }
      });
    },

    setFormData(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            name: this.goal.name,
            target_amount: this.goal.target_amount,
            end_date: this.goal.end_date,
            id: this.goal.id,
            client: this.currentUser.id,
          };
    },

    setFormTransferData(value = null) {
      this.erroTransForm = null;
      this.formTrans = value
        ? this.$clone(value)
        : {
            goal_to: "",
            goal_from: this.goal,
            amount: 0,
            client: this.currentUser.id,
          };
    },

    showAlert() {
      // Use sweetalert2
      this.$swal("Hello Vue world!!!");
    },
    disable() {
      this.formDisable.id = this.goal.id;
      this.formDisable.is_active = !this.goal.status;
      this.disableGoal(this.formDisable);
    },
    error() {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
    deletion() {
      this.$swal({
        title: "Do you want to delete?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.formDelete.id = this.goal.id;
          this.deleteGoal(this.form);
        } else if (result.isDenied) {
          this.$swal("Goal deletion canceled", "", "info");
        }
      });
    },
    prev() {
      this.edit--;
    },

    next() {
      this.edit++;
    },

    customerRegister: function () {
      this.hasSubmitted = true;
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "isUpdated",
      "isTransfered",
      "isDisabled",
      "goals_info",
      "isDeleted",
      "redeemStatus",
      "redeemMessage",
      "loadingInfo",
    ]),
  },

  watch: {
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.goalItems = this.goals_info.goals;
    },
    redeemMessage() {
      this.$toast.info(this.redeemMessage);
      // if (this.redeemStatus) {
      //   this.$router.go();
      // } else {
      //   this.$toast.error(this.redeemMessage);
      // }
    },
    isTransfered() {
      if (this.isTransfered) {
        this.setFormTransferData();
        this.$toast.success("Transfered successfully");
        this.$emit("refreshState");
      }
    },
    isUpdated() {
      if (this.isUpdated) {
        this.$toast.success("Updated successfully");
        this.loading = false;
      } else {
        this.$toast.error("Failed to Update");
        this.loading = false;
      }
    },
    isDisabled() {
      if (this.isDisabled) {
        this.$router.go();
        this.$swal({
          icon: "success",
          title: this.goal.status == true ? "Disable" : "Enable",
          text: `Your Goal has been ${
            this.goal.status == true ? "Disabled" : "Enabled"
          }!`,
        });
      } else {
        this.$swal({
          icon: "Failed",
          title: "Action failed",
          text: "Action failed to be executed!",
        });
      }
    },
    isDeleted() {
      if (this.isDeleted) {
        this.$swal("Goal deletion initiated!", "", "success");
        this.$router.go();
        // this.$router.push({
        //   name: "Savings",
        // });
      }
    },
  },
  created() {
    this.setFormData();
    this.setFormTransferData();

    var scripts = [
      "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/multistep-form.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/datatables.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/soft-ui-dashboard.min.js?v=1.1.1",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/choices.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/smooth-scrollbar.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/dragula/dragula.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/chartjs.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/tilt.min.js",
      "/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  mounted() {
    setTooltip();
  },
};
</script>
