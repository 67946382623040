import { customParse, tokenHeaders } from "../../services/helpers";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_URL;

const state = {
  auth_token: localStorage.getItem("token"),
  currentUser: customParse(localStorage.getItem("user")),
  auth_loading: false,
  auth_message: "",
  auth_error: false,
  profile: {},
  referred_clients: [],
  referred_count: 0,
  referral_wallet: {},
  clientFound: {},
  success: false,
  notificationsList: [],
  payMethods: [],
  inviteStatus: false,
  inviteMsg: "",
  OptStatus: false,
  Opt: {},
};

const getters = {
  auth_info: (state) => {
    return {
      token: state.auth_token,
      ip_address: state.ip_address,
      loading: state.auth_loading,
      message: state.auth_message,
      error: state.auth_error,
    };
  },
  invite: (state) => {
    return {
      inviteStatus: state.inviteStatus,
      inviteMsg: state.inviteMsg,
    };
  },
  currentUser: (state) => state.currentUser,
  loggedIn: (state) => state.token && state.token.length > 1,
  profile: (state) => state.profile,
  referred_clients: (state) => state.referred_clients,
  referred_count: (state) => state.referred_count,
  referral_wallet: (state) => state.referral_wallet,
  notificationsList: (state) => state.notificationsList,
  success: (state) => state.success,
  payMethods: (state) => state.payMethods,
  clientFound: (state) => state.clientFound,
  OptStatus: (state) => state.OptStatus,
  Opt: (state) => state.Opt,
};

const actions = {
  setCurrentUser: ({ commit }, user) => commit("SET_CURRENT_USER", user),
  removeCurrentUser: ({ commit }) => commit("LOG_OUT"),

  loginCustomer: async ({ state, commit }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      const headers = tokenHeaders({ token: state.token });
      const url = BASE_URL + "client/login";
      let response = await axios.post(url, formData, headers);
      const { data, message, token, success } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_AUTH_USER", { data, token, success });
    } catch (err) {
      console.log("error", err.response.data?.message);
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("LOG_OUT");
    }
  },

  registerCustomer: async ({ commit }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      const headers = tokenHeaders({ token: state.token });
      const url = BASE_URL + "client/create";
      let response = await axios.post(url, formData, headers);
      const { data, message, token } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_AUTH_USER", { data, token });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("LOG_OUT");
    }
  },

  updateProfile: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token: token });
      const url = BASE_URL + "client/profile/update";
      let response = await axios.post(url, formData, headers);
      const { data, message, success } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("USER_PROFILE", { data, token, success });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  updateProfileOnboarding: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token: token });
      const url = BASE_URL + "client/profile/update_onboard";
      let response = await axios.post(url, formData, headers);
      const { data, message, success } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("USER_PROFILE", { data, token, success });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  sendInvites: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token: token });
      const url = BASE_URL + "client/connections/invite_contacts";
      let response = await axios.post(url, formData, headers);
      const { message, success } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_INVITES", { success, message });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  updateUserImage: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token });
      const url = BASE_URL + "client/image/update";
      let response = await axios.post(url, formData, headers);
      const { data, message } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      console.log("data", data);
      commit("USER_PROFILE_IMAGE", data);
    } catch (err) {
      const message = err.response?.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  UpdatePassiveSavings: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token });
      const url = BASE_URL + "client/passive-savings-percentage/update";
      let response = await axios.post(url, formData, headers);
      const { data, message } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_PASSIVE", data);
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  addPaymentMethod: async ({ commit, state }, formData) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      let token = state.auth_token;
      const headers = tokenHeaders({ token });
      const url = BASE_URL + "client/payment-methods/create";
      let response = await axios.post(url, formData, headers);
      const { data, message, success } = response.data;
      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("PAY_METHOD", { data, success });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  getPaymentMethods: async ({ commit, rootState }) => {
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const url = BASE_URL + "client/payment-methods";
      const headers = tokenHeaders({ ip_address, token });

      let response = await axios.get(url, headers);
      const { data } = response.data;

      commit("PAY_METHOD", { data, token });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  getProfile: async ({ commit, rootState }) => {
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const url = BASE_URL + "client/profile";
      const headers = tokenHeaders({ ip_address, token });

      let response = await axios.get(url, headers);
      const { data } = response.data;

      commit("USER_PROFILE", { data, token });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("LOG_OUT");
    }
  },

  getReferrals: async ({ commit, rootState }, params) => {
    try {
      let token = rootState.auth.auth_token;
      let ip_address = rootState.auth.ip_address;
      // const url = BASE_URL + "client/srt/active_referrals";
      let url = BASE_URL + "client/referrals";
      const headers = tokenHeaders({ ip_address, token });

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      let response = await axios.get(url, headers);
      const { data, referral_wallet, count } = response.data;
      commit("SET_REFERRALS", { data, referral_wallet, count });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  lookUpClient: async ({ commit, rootState }, params) => {
    try {
      let token = rootState.auth.auth_token;
      let ip_address = rootState.auth.ip_address;
      let url = BASE_URL + "client/lookup_exact_client?";
      const headers = tokenHeaders({ ip_address, token });

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      let response = await axios.get(url, headers);
      const { data } = response.data;
      commit("SET_CLIENT_LOOKUP", data);
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  optInOut: async ({ commit, rootState }, formData) => {
    try {
      let token = rootState.auth.auth_token;
      let ip_address = rootState.auth.ip_address;
      let url = BASE_URL + "client/payment-methods/automatic/update";
      const headers = tokenHeaders({ ip_address, token });

      let response = await axios.post(url, formData, headers);
      const { data, success } = response.data;
      commit("SET_OPT_IN_OUT", { data: data, success: success });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  getNotifications: async ({ commit, rootState }, params) => {
    try {
      let token = rootState.auth.auth_token;
      let ip_address = rootState.auth.ip_address;
      // const url = BASE_URL + "client/srt/active_referrals";
      let url = BASE_URL + "client/notifications";
      const headers = tokenHeaders({ ip_address, token });

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      let response = await axios.get(url, headers);
      const { data } = response.data;
      commit("SET_NOTIFICATIONS", { data });
    } catch (err) {
      const message = err.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
    }
  },

  webAuthProfile: async ({ commit, rootState }, authToken) => {
    try {
      let ip_address = rootState.auth.ip_address;
      const url = BASE_URL + "client/web/auth";
      const headers = tokenHeaders({ ip_address });
      headers.headers.Authorization = `Token ${authToken}`;
      console.log(headers);

      let response = await axios.get(url, headers);
      const { data, token } = response.data;

      commit("WEB_AUTH", { data, token });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("LOG_OUT");
    }
  },
};

const mutations = {
  SET_CURRENT_USER: (state, newValue) => {
    state.currentUser = newValue;
    if (newValue) window.localStorage.setItem("user", JSON.stringify(newValue));
    else window.localStorage.removeItem("user");
  },
  LOG_OUT: (state) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    // localStorage.removeItem("bot_token");
    localStorage.removeItem("ip_address");

    state.currentUser = null;
    state.auth_token = null;
    // state.bot_auth = "";
  },
  AUTH_LOADING: (state, new_value) => {
    state.auth_loading = new_value.loading;
    state.auth_error = new_value.error;
    state.auth_message = new_value.message;
  },
  SET_AUTH_USER: (state, new_value) => {
    const { data, token, success } = new_value;
    localStorage.setItem("user", JSON.stringify(data));
    state.currentUser = data;
    state.success = success;

    if (token) {
      localStorage.setItem("token", token);
      state.auth_token = token;
    }
  },
  USER_PROFILE: (state, payload) => {
    const { data, token, success } = payload;
    const local_token = localStorage.getItem("token");
    if (local_token === token) {
      state.profile = data;
      state.success = success;
    }
  },

  USER_PROFILE_IMAGE: (state, payload) => {
    state.profile = { ...state.profile, image: payload.image };
    localStorage.setItem("user", JSON.stringify(payload));
  },

  SET_PASSIVE: (state, payload) => {
    state.profile = { ...state.profile, passive_savings: payload };
  },

  SET_OPT_IN_OUT: (state, payload) => {
    state.payMethods.map((req) => {
      if (req.id == payload.data.id) {
        state.payMethods.filter((req) => req.id == payload.data.id);
        state.payMethods = state.lenderLoanReq.push(payload.data);
      }
    });
    state.OptStatus = payload.success;
    state.Opt = payload.data;
  },

  SET_REFERRALS: (state, payload) => {
    const { data, referral_wallet, count } = payload;
    state.referred_clients = data;
    state.referred_count = count;
    state.referral_wallet = referral_wallet;
  },

  SET_CLIENT_LOOKUP: (state, payload) => {
    state.clientFound = payload;
  },

  SET_NOTIFICATIONS: (state, payload) => {
    state.notificationsList = payload;
  },

  SET_INVITES: (state, payload) => {
    state.inviteStatus = payload.success;
    state.inviteMsg = payload.message;
  },

  PAY_METHOD: (state, payload) => {
    const { data, success } = payload;
    state.payMethods = data;
    state.success = success;
  },

  WEB_AUTH: (state, payload) => {
    const { data, token } = payload;

    console.log(token);
    if (token) {
      localStorage.setItem("token", token);
      state.profile = data;
      state.auth_token = token;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
