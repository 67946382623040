<template>
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ goal?.name }}
            </h6>
            <span class="text-xs"></span>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Savings Progress
            </h6>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <div style="margin-bottom: 1rem">
          <soft-progress color="success" variant="gradient" :percentage="
            parseFloat(
              (
                (parseFloat(goal?.saved_amount) * 100) /
                parseFloat(goal?.target_amount)
              ).toFixed(2)
            )
          " />
        </div>
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <span class="text-xs">Allocation</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ goal?.allocation }}%
            </h6>
          </div>
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">Saved</h6>
            <span class="text-xs">{{ goal.saved_amount }} of {{ goal.target_amount }}</span>
          </div>
        </li>
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <span class="text-xs">End Date</span>
            <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-sm">
              {{ goal.end_date }}
            </h6>
          </div>
          <div class="d-flex flex-column">
            <span class="text-xs">Remaining Day(s)</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ goal.remaining_days }}
            </h6>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <div>
          <soft-button style="box-shadow: none" variant="gradient" color="success" fullWidth data-bs-toggle="modal"
            :data-bs-target="'#investmentGoalDetailsModal_' + goal.id">More Details
          </soft-button>
        </div>

        <!-- Modal -->
        <div class="modal fade" :id="'investmentGoalDetailsModal_' + goal.id" tabindex="-1" role="dialog"
          aria-labelledby="fixedGoalDetailsModalTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div id="app">
                <section class="stepper-modal">
                  <transition name="slide-fade">
                    <section v-show="edit === 1" v-if="tab === 'view-byob'">
                      <div style="border-bottom: none" class="modal-header">
                        <h5 class="modal-title" :id="'fixedGoalDetailsModalLabel' + goal.id">
                          {{ goal.name }}
                        </h5>
                        <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Daily Target</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.daily_target }}
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Saved Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.saved_amount }}
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Interest Earned</span>
                            <h6 style="color: #03da92 !important" class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.earned_amount }}
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Target Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.target_amount.toLocaleString() }}
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Allocation</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.allocation }}%
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">Remaining Day(s)</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.remaining_days }} of 190 Day(s)
                            </h6>
                          </div>
                        </li>
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <span class="text-xs">End Date</span>
                            <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-sm">
                              {{ goal.end_date }}
                            </h6>
                          </div>
                        </li>
                        <p>
                          <a style="text-decoration: underline"
                            class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                            data-bs-toggle="collapse" href="#collapseGoalTerms" aria-expanded="false"
                            aria-controls="collapseGoalTerms">
                            Goal Terms
                            <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                          </a>
                        </p>
                        <div class="collapse" id="collapseGoalTerms">
                          <div class="card card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred nesciunt
                            sapiente ea proident.
                          </div>
                        </div>
                      </div>
                      <div style="border-top: none" class="modal-footer">
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                          variant="gradient" color="success" fullWidth @click.prevent="next()"
                          @click="tab = tab === 'edit-byob' ? 'view-byob' : 'edit-byob'">
                          Edit
                        </soft-button>
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                          variant="gradient" color="success" fullWidth @click.prevent="next()"
                          @click="tab = tab === 'redeem-byob' ? 'view-byob' : 'redeem-byob'">
                          Redeem
                        </soft-button>
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                          variant="gradient" color="success" fullWidth @click.prevent="next()"
                          @click="tab = tab === 'transfer-byob' ? 'view-byob' : 'transfer-byob'">
                          Transfer
                        </soft-button>
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                          variant="gradient" color="secondary" fullWidth @click="disable">
                          Disable
                        </soft-button>
                        <soft-button style="margin-right: 0.1rem" class="my-4 mb-2" variant="gradient" color="danger"
                          @click="deletion">
                          Delete
                        </soft-button>
                        <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                          Close
                        </soft-button>
                      </div>
                    </section>
                  </transition>
                  <transition name="slide-fade">
                    <section v-show="edit === 2">
                      <form style="padding: 2rem" class="form" v-if="tab === 'edit-byob'">
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Goal Name</label>
                            <input id="goalname" type="text" placeholder="Goal Name" aria-label="Goal Name"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Total Amount</label>
                            <input id="cpassword" type="number" placeholder="KES" aria-label="Target Amount"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>End Date</label>
                            <input id="date" type="date" placeholder="" aria-label="End Date" class="form-control" />
                          </div>
                        </div>
                        <div style="margin-top: 3rem;" class="form-group cta-step">
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="dark" @click.prevent="prev()"
                            @click="tab = tab === 'view-byob' ? 'edit-byob' : 'view-byob'">
                            Back
                          </soft-button>
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="success">
                            Submit
                          </soft-button>
                        </div>
                      </form>
                      <form style="padding: 2rem" class="form" v-if="tab === 'redeem-byob'">
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter the amount you want to redeem</label>
                            <input id="goalname" type="number" placeholder="KES" aria-label="Goal Name"
                              class="form-control" />
                            <p style="color: #0ebb81; font-size: 12px;">Maximum you can redeem is KES 1,000.00</p>
                            <label style="text-align: center; font-weight: 400">Redemption request shall be processed
                              within <strong style="color:#0ebb81">48 hours</strong> to
                              consolidate funds from various
                              underlying investments</label>
                          </div>
                        </div>
                        <div style="margin-top: 3rem;" class="form-group cta-step">
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="dark" @click.prevent="prev()"
                            @click="tab = tab === 'view-byob' ? 'redeem-byob' : 'view-byob'">
                            Back
                          </soft-button>
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="success">
                            Submit
                          </soft-button>
                        </div>
                      </form>
                      <form style="padding: 2rem" class="form" v-if="tab === 'transfer-byob'">
                        <label>What is <strong style="color:#0ebb81" data-bs-toggle="tooltip"
                            data-bs-original-title="Moving funds between goals" data-bs-placement="top"
                            title="Moving funds between goals" class="cursor-pointer" data-animation="true">goal
                            transfer?</strong></label>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Select goal</label>
                            <select class="form-control">
                              <option>BYOB</option>
                              <option>Bitcoincash</option>
                              <option>School fees</option>
                              <option>Mombasa trip</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter Amount</label>
                            <input id="cpassword" type="number" placeholder="KES" aria-label="Target Amount"
                              class="form-control" />
                            <p style="color: #0ebb81; font-size: 12px;">Maximum you can redeem is KES 1,000.00</p>
                          </div>
                        </div>
                        <div style="margin-top: 3rem;" class="form-group cta-step">
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="dark" @click.prevent="prev()"
                            @click="tab = tab === 'view-byob' ? 'transfer-byob' : 'view-byob'">
                            Back
                          </soft-button>
                          <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="success">
                            Submit
                          </soft-button>
                        </div>
                      </form>
                    </section>
                  </transition>
                </section>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftAvatar from "@/components/SoftAvatar.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import gok from "@/assets/img/gok.png";

export default {
  name: "ActiveInvestmentsCard",
  components: {
    SoftButton,
    // SoftAvatar,
    // SoftInput,
  },
  el: "#app",
  data() {
    return {
      edit: 1,
      tab: 'view-byob',
    };
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal('Hello Vue world!!!');
    },
    disable() {
      this.$swal({
        icon: 'success',
        title: 'Disable',
        text: 'Your Goal has been disabled!',
      });
    },
    error() {
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    },
    deletion() {
      this.$swal({
        title: 'Do you want to delete?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal('Goal deletion initiated!', '', 'success')
        } else if (result.isDenied) {
          this.$swal('Goal deletion canceled', '', 'info')
        }
      })
    },
    prev() {
      this.edit--;
    },

    next() {
      this.edit++;
    },

    customerRegister: function () {
      this.hasSubmitted = true;
    },
  },
  props: ["goal"],
  mounted() {
    setTooltip();
  },
};
</script>
