<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div id="app">
        <section class="stepper-onboarding">
          <transition name="slide-fade">
            <section v-show="step === 1">
              <form style="padding: 2rem" class="form">
                <explainer />
                <div style="margin-top: 3rem" class="form-group cta-step">
                  <soft-button
                    style="margin-right: 0.1rem; margin-top: 0 !important"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    @click.prevent="next"
                    fullWidth
                  >
                    Get Started
                  </soft-button>
                </div>
              </form>
            </section>
          </transition>

          <!-- age -->
          <transition name="slide-fade">
            <section v-show="step === 2">
              <age @submitAge="submitAge" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 3">
              <what @next="next" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 4">
              <income @submitIncome="submitIncome" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 5">
              <monthly-savings
                @submitMonthlySavings="submitMonthlySavings"
                @prev="prev"
              />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 6">
              <savings-cover
                @submitSavngsCover="submitSavngsCover"
                @prev="prev"
              />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 7">
              <period @submitPeriod="submitPeriod" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 8">
              <investor-period
                @submitInvestorPeriod="submitInvestorPeriod"
                @prev="prev"
              />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 9">
              <familiar @submitFamiliar="submitFamiliar" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 10">
              <description
                @submitDescription="submitDescription"
                @prev="prev"
              />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 11">
              <protect @submitProtect="submitProtect" @prev="prev" />
            </section>
          </transition>

          <transition name="slide-fade">
            <section v-show="step === 12">
              <complete :invProfile="invProfile" @prev="prev" />
            </section>
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import Explainer from "./investments/onboarding/Explainer.vue";
import Age from "./investments/onboarding/Age.vue";
import What from "./investments/onboarding/What.vue";
import Income from "./investments/onboarding/Income.vue";
import MonthlySavings from "./investments/onboarding/MonthlySavings.vue";
import SavingsCover from "./investments/onboarding/SavingsCover.vue";
import Period from "./investments/onboarding/Period.vue";
import InvestorPeriod from "./investments/onboarding/InvestorPeriod.vue";
import Familiar from "./investments/onboarding/Familiar.vue";
import Description from "./investments/onboarding/Description.vue";
import Protect from "./investments/onboarding/Protect.vue";
import Complete from "./investments/onboarding/Complete.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "investments-onboarding-default",
  el: "#app",
  data() {
    return {
      step: 1,
      invProfile: {},
      completed: false,
    };
  },
  components: {
    SoftButton,
    Explainer,
    Age,
    What,
    Income,
    MonthlySavings,
    SavingsCover,
    Period,
    InvestorPeriod,
    Familiar,
    Description,
    Protect,
    Complete,
  },
  methods: {
    ...mapActions(["getInvestmentsProfile", "addInvestmentAnswer"]),

    submitAge(data) {
      console.log("formAge", data);
      this.addInvestmentAnswer(data);
      this.step = 3;
    },

    submitWhat() {
      this.step = 4;
    },

    submitIncome() {
      this.step = 5;
    },

    submitMonthlySavings() {
      this.step = 6;
    },

    submitSavngsCover() {
      this.step = 7;
    },

    submitPeriod() {
      this.step = 8;
    },

    submitInvestorPeriod() {
      this.step = 9;
    },

    submitFamiliar() {
      this.step = 10;
    },

    submitDescription() {
      this.step = 11;
    },

    submitProtect() {
      this.step = 12;
      this.getInvestmentsProfile();
    },

    prev() {
      this.step--;
    },

    next() {
      this.step++;
    },
  },
  mounted() {
    setTooltip();
    setNavPills();
  },

  computed: {
    ...mapGetters(["investmentsProfile", "invBoarding"]),
  },

  watch: {
    investmentsProfile() {
      console.log("this.investmentsProfile", this.investmentsProfile);
      if (!this.investmentsProfile.question) {
        this.invProfile = this.investmentsProfile;
      }
    },
    "invBoarding.data"() {
      if (!this.invBoarding.complete) {
        this.step =
          this.step != 1
            ? this.invBoarding.data.id + 1
            : this.invBoarding.data.id;

        this.completed = this.invBoarding.complete;

        console.log("invBoarding.data", this.invBoarding.data, this.step);
      }
    },
  },
};
</script>
