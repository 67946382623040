<template>
  <div
    class="modal fade"
    id="modal-goal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-default"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal- modal-dialog-centered modal-"
      role="document"
    >
      <div class="modal-content">
        <div style="border-bottom: none" class="modal-header">
          <h6 class="modal-title" id="modal-title-deposit">
            Create custom goal
          </h6>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="card-body">
              <ul class="list-group">
                <li class="px-0 border-0 list-group-item">
                  <form @submit.prevent="createGoal" class="form-group">
                    <label for="example-text-input" class="form-control-label"
                      >Name your goal</label
                    >
                    <input
                      class="form-control"
                      v-model="form.name"
                      type="text"
                      placeholder="Goal Name"
                      id="example-text-input"
                    />
                    <label for="example-date-input" class="form-control-label"
                      >When do you target to achieve this goal?</label
                    >
                    <input
                      class="form-control"
                      v-model="form.end_date"
                      type="date"
                      id="example-date-input"
                    />
                    <label for="example-number-input" class="form-control-label"
                      >How much are you targeting to save?</label
                    >
                    <input
                      class="form-control"
                      v-model="form.target_amount"
                      type="number"
                      placeholder="KES 500"
                      id="example-number-input"
                    />
                    <!-- <label for="example-number-input" class="form-control-label">How long do you
                                            want to
                                            lock your savings?</label>
                                        <select class="form-control">
                                            <option>Select Period</option>
                                            <option>30 days</option>
                                            <option>90 days</option>
                                            <option>180 days</option>
                                            <option>1 Year</option>
                                            <option>3 Years</option>
                                        </select> -->
                    <div style="margin-bottom: 1rem"></div>
                    <div>
                      <label style="margin-bottom: 1rem"
                        >Would you like to earn interest on this goal?</label
                      >
                      <div style="width: 100%; height: 80px" class="radio-card">
                        <input type="radio" id="radio3" name="radioDisabled" />
                        <label
                          style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          "
                          for="customRadioDisabled"
                          >Yes (Goal redeemed in 48 hours)</label
                        >
                      </div>
                      <div
                        style="width: 100%; height: 80px; margin-top: -1rem"
                        class="radio-card"
                      >
                        <input type="radio" id="radio3" name="radioDisabled" />
                        <label
                          style="
                            position: absolute;
                            margin: 20px;
                            cursor: pointer;
                          "
                          for="customRadioDisabled"
                          >No (Instant withdrawal) Goal</label
                        >
                      </div>
                    </div>
                    <label for="example-number-input" class="form-control-label"
                      >Goal terms</label
                    >
                    <textarea
                      class="form-control"
                      v-model="form.terms"
                      type="text"
                      placeholder="Add goal terms"
                      id="example-terms-input"
                    ></textarea>

                    <input type="hidden" v-model="form.type" />
                    <input type="hidden" v-model="form.client" />
                    <soft-button
                      type="submit"
                      style="margin-right: 1rem"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      fullWidth
                    >
                      Add Goal
                    </soft-button>
                  </form>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftInput from "@/components/SoftInput.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "savings-modal",
  components: {
    // SoftInput,
    // SoftCheckbox,
    SoftButton,
  },
  data() {
    return {
      goalOptionModal: "goal1Modal",
      form: {
        saving: null,
        group: null,
      },
      loadingForm: false,
      createStatus: false,
    };
  },

  created() {
    this.setForm();
  },

  methods: {
    ...mapActions(["createCustomSavingGoal"]),

    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            name: "",
            target_amount: 1.0,
            end_date: "",
            type: "SAVC",
            terms: "",
            client: this.user,
          };
    },

    createGoal() {
      this.loadingForm = true;
      let amt = parseFloat(this.form.target_amount);
      this.form.target_amount = parseFloat(amt.toFixed(2));
      this.createCustomSavingGoal([this.form]);
    },
  },

  computed: {
    ...mapGetters["loadingInfo"],
  },

  watch: {
    loadingInfo(){
      this.loadingForm = false;
      this.loadingInfo.success && this.$router.go()
    }
  }
};
</script>
