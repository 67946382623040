const state = {
  loading: false,
  error: false,
  message: "",
  created: false,
};

const getters = {
  loadingInfo: (state) => ({
    loading: state.loading,
    error: state.error,
    message: state.message,
    created: state.created,
  }),
};

const actions = {};

const mutations = {
  SET_LOADING: (state, newValue) => {
    state.loading = newValue.loading;
    state.error = newValue.error;
    state.message = newValue.message;
    state.created = newValue.created ?? false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
