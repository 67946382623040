<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center">
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <div class="col-xl-6 mb-xl-0 mb-4">
            <!-- <master-card /> -->
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-md-6">
              </div>
              <div class="col-md-6">
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <!-- <payment-methods-card /> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4">
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "Guarantor",
  components: {
  },
  mounted() {
    setTooltip();
  },
};
</script>
