<template>
  <aside class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'" id="sidenav-main">
    <div class="sidenav-header">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <a class="m-0 navbar-brand" href="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
      </a>
    </div>
    <hr class="mt-0 horizontal dark" />
    <div>
      <sidenav-list :cardBg="customClass" />
      <router-link :to="{ name: 'Sign In' }">
        <div class="sidenav-footer cursor-pointer" @click="logout">
          <i class="fa fa-sign-out text-dark footer-logout"></i>
          <h6 class="footer-logout-text">Logout</h6>
        </div>
      </router-link>
    </div>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import { mapActions, mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions(["removeCurrentUser"]),

    logout() {
      this.removeCurrentUser();
    },
  },
  computed: {
    ...mapState(["isRTL", "currentUser"]),
  },
  watch: {
    currentUser() {
      const user = this.currentUser;
      if (!user) {
        this.$router.push({ name: "Sign In", replace: true });
      }
    },
  },
};
</script>
