import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_URL;

const state = {
  countries: [],
};

const getters = {
  countries: (state) => state.countries,
};

const actions = {
  fetchCountries: async ({ rootState, commit }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = BASE_URL + "client/country_codes?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await axios.get(url, headers);
      console.log("country", response.data);
      const { message, data } = response.data;

      commit("SET_COUNTRY", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_COUNTRY: (state, payload) => {
    state.countries = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
