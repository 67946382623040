<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Completed Loans</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Lender
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Interest
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Date payable
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                Amount
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="completedLenders.length > 0">
            <tr v-for="al in completedLenders" :key="al.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ al.client_borrower_name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">{{ al.interest }}%</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">
                  {{ al.date_payable }}
                </span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">
                    KES {{ parseFloat(al.amount).toLocaleString() }}
                  </span>
                </div>
              </td>
              <td class="align-middle">
                <button href="javascript:;" class="btn btn-outline-secondary ms-2"
                  style="border: 2px solid;margin-top: 0.4rem; margin-bottom: 0.4rem;" data-bs-toggle="modal"
                  data-bs-target="#modal-complete-lender-rating">Rate Borrower</button>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <th colspan="5" class="text-center">No Completed Loans!</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Completed Lender Rating Modal -->
  <div class="col-md-4">
    <div class="modal fade" id="modal-complete-lender-rating" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-complete-lender-rating">Rate Borrower</h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <span class="text-xs">Borrower</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  John Doe
                </h6>
              </div>
              <div class="d-flex flex-column">
                <span class="text-xs" style="text-align: end;">Date paid</span>
                <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-lg">
                  10-Oct-2023
                </h6>
              </div>
            </li>
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <span class="text-xs">Interest rate</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">2%</h6>
              </div>
              <div class="d-flex flex-column">
                <span class="text-xs" style="text-align: end;">Loan amount</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  KES 2,500.00
                </h6>
              </div>
            </li>
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <span class="text-xs">Rate borrower</span>
                <star-rating :inline="true" :star-size="30" :read-only="false" :show-rating="false"
                  :rating="4"></star-rating>
              </div>
            </li>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark">Rate borrower</soft-button>
            <soft-button type="button" class="mb-2" color="warning" data-bs-dismiss="modal">Dismiss</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton";
// @ts-ignore
import StarRating from "vue-star-rating";

export default {
  name: "complete-lend-table",
  components: {
    SoftButton,
    StarRating
  },
  props: ["completedLenders"],
};
</script>
