<template>
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex justify-content-between">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ cgoal?.name }}
              <span class="text-info">
                ({{
                  parseFloat(
                    (
                      (parseFloat(cgoal.saved_amount) * 100) /
                      parseFloat(cgoal.target_amount)
                    ).toFixed(2)
                  )
                }}%)
              </span>
            </h6>
            <span class="text-xs text-danger" v-if="!cgoal.status">
              &nbsp;|&nbsp;disabled</span
            >
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Savings Progress
            </h6>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <div style="margin-bottom: 1rem">
          <soft-progress
            color="success"
            variant="gradient"
            :percentage="
              parseFloat(
                (
                  (parseFloat(cgoal.saved_amount) * 100) /
                  parseFloat(cgoal.target_amount)
                ).toFixed(2)
              )
            "
          />
        </div>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">Allocation</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ cgoal?.allocation }}%
            </h6>
          </div>
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">Saved</h6>
            <span class="text-xs"
              >{{ cgoal.saved_amount }} of {{ cgoal.target_amount }}</span
            >
          </div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">End Date</span>
            <h6
              style="color: red !important"
              class="text-dark mb-1 font-weight-bold text-sm"
            >
              {{ cgoal.end_date }}
            </h6>
          </div>
          <div class="d-flex flex-column">
            <span class="text-xs">Remaining Day(s)</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ cgoal.remaining_days }}
            </h6>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <div>
          <soft-button
            style="box-shadow: none"
            variant="gradient"
            color="success"
            fullWidth
            data-bs-toggle="modal"
            :data-bs-target="'#customGoalDetailsModal_' + cgoal.id"
            >More Details
          </soft-button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          :id="'customGoalDetailsModal_' + cgoal.id"
          tabindex="-1"
          role="dialog"
          aria-labelledby="customGoalDetailsModalTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div id="app">
                <section class="stepper-modal">
                  <transition name="slide-fade">
                    <section v-show="edit === 1" v-if="tab === 'view-byob'">
                      <div style="border-bottom: none" class="modal-header">
                        <h5
                          class="modal-title"
                          :id="'customGoalDetailsModalLabel' + cgoal.id"
                        >
                          {{ cgoal.name }}
                        </h5>
                        <button
                          type="button"
                          class="btn-close text-dark"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Daily Target</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ cgoal.daily_target }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Saved Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ cgoal.saved_amount }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Interest Earned</span>
                            <h6
                              style="color: #03da92 !important"
                              class="text-dark mb-1 font-weight-bold text-sm"
                            >
                              {{ cgoal.earned_amount }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Target Amount</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ cgoal.target_amount?.toLocaleString() }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Allocation</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ cgoal.allocation }}%
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">Remaining Day(s)</span>
                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                              {{ cgoal.remaining_days }}
                            </h6>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                        >
                          <div class="d-flex flex-column">
                            <span class="text-xs">End Date</span>
                            <h6
                              style="color: red !important"
                              class="text-dark mb-1 font-weight-bold text-sm"
                            >
                              {{ cgoal.end_date }}
                            </h6>
                          </div>
                        </li>
                        <p>
                          <a
                            style="text-decoration: underline"
                            class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                            data-bs-toggle="collapse"
                            href="#collapseGoalTerms"
                            aria-expanded="false"
                            aria-controls="collapseGoalTerms"
                          >
                            Goal Terms
                            <i
                              class="fas fa-arrow-right text-sm ms-1"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </p>
                        <div class="collapse" id="collapseGoalTerms">
                          <div class="card card-body">
                            {{ cgoal.terms }}
                          </div>
                        </div>
                      </div>
                      <div style="border-top: none" class="modal-footer">
                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'edit-byob' ? 'view-byob' : 'edit-byob'
                          "
                        >
                          Edit
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'redeem-byob'
                                ? 'view-byob'
                                : 'redeem-byob'
                          "
                        >
                          Redeem
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          fullWidth
                          @click.prevent="next()"
                          @click="
                            tab =
                              tab === 'transfer-byob'
                                ? 'view-byob'
                                : 'transfer-byob'
                          "
                        >
                          Transfer
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem; margin-top: 0 !important"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="secondary"
                          fullWidth
                          @click="disableCustom"
                        >
                          <span v-if="goal.status">Disable</span>
                          <span v-else>Enable</span>
                        </soft-button>
                        <soft-button
                          style="margin-right: 0.1rem"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="danger"
                          @click.prevent="deletionCustom"
                        >
                          Delete
                        </soft-button>
                        <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </soft-button>
                      </div>
                    </section>
                  </transition>
                  <transition name="slide-fade">
                    <section v-show="edit === 2">
                      <form
                        style="padding: 2rem"
                        class="form"
                        @submit.prevent="updateCustomGoal"
                        v-if="tab === 'edit-byob'"
                      >
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Goal Name</label>
                            <input
                              id="goalname"
                              type="text"
                              placeholder="Goal Name"
                              v-model="formCustomEdit.name"
                              aria-label="Goal Name"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Total Amount</label>
                            <input
                              id="cpassword"
                              type="number"
                              v-model="formCustomEdit.target_amount"
                              placeholder="KES"
                              aria-label="Target Amount"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>End Date</label>
                            <input
                              id="date"
                              type="date"
                              placeholder=""
                              v-model="formCustomEdit.end_date"
                              aria-label="End Date"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob' ? 'edit-byob' : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            Submit
                          </soft-button>
                        </div>
                      </form>

                      <form
                        style="padding: 2rem"
                        @submit.prevent="redeemCustomGoal"
                        class="form"
                        v-if="tab === 'redeem-byob'"
                      >
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter the amount you want to redeem</label>
                            <input
                              id="goalname"
                              type="number"
                              v-model="formCustomRedeem.amount"
                              placeholder="KES"
                              aria-label="Goal Name"
                              class="form-control"
                            />
                            <p style="color: #0ebb81; font-size: 12px">
                              Maximum you can redeem is KES
                              {{ cgoal.saved_amount }}
                            </p>
                            <label style="text-align: center; font-weight: 400"
                              >Redemption request shall be processed within
                              <strong style="color: #0ebb81">48 hours</strong>
                              to consolidate funds from various underlying
                              investments</label
                            >
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob'
                                  ? 'redeem-byob'
                                  : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            Submit
                          </soft-button>
                        </div>
                      </form>
                      <form
                        style="padding: 2rem"
                        @submit.prevent="transferCustomGoal"
                        class="form"
                        v-if="tab === 'transfer-byob'"
                      >
                        <label
                          >What is
                          <strong
                            style="color: #0ebb81"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Moving funds between goals"
                            data-bs-placement="top"
                            title="Moving funds between goals"
                            class="cursor-pointer"
                            data-animation="true"
                            >goal transfer?</strong
                          ></label
                        >
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Select goal</label>
                            <select
                              v-model="formCustomTrans.goal_to"
                              id="destination-goal"
                              @change="goalAmount($event)"
                              class="form-control"
                              required
                            >
                              <option disabled selected>
                                ---Select Goal---
                              </option>
                              <option
                                v-for="goalItem in goalItems"
                                :key="goalItem.id"
                                :value="goalItem.id"
                              >
                                {{
                                  goalItem.name
                                    ? goalItem.name
                                    : goalItem.type_display
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <div style="width: 100%">
                            <label>Enter Amount</label>
                            <input
                              id="cpassword"
                              type="number"
                              v-model="formCustomTrans.amount"
                              placeholder="KES"
                              aria-label="Target Amount"
                              class="form-control"
                            />
                            <p style="color: #0ebb81; font-size: 12px">
                              Maximum you can redeem is KES
                              {{ cgoal.saved_amount }}
                            </p>
                          </div>
                        </div>
                        <div
                          style="margin-top: 3rem"
                          class="form-group cta-step"
                        >
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            color="dark"
                            @click.prevent="prev()"
                            @click="
                              tab =
                                tab === 'view-byob'
                                  ? 'transfer-byob'
                                  : 'view-byob'
                            "
                          >
                            Back
                          </soft-button>
                          <soft-button
                            style="
                              margin-right: 0.1rem;
                              margin-top: 0 !important;
                            "
                            class="my-4 mb-2"
                            variant="gradient"
                            type="submit"
                            color="success"
                          >
                            Submit
                          </soft-button>
                        </div>
                      </form>
                    </section>
                  </transition>
                </section>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftProgress from "@/components/SoftProgress";
import setTooltip from "@/assets/js/tooltip.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomGoalsCard",
  components: {
    SoftProgress,
    SoftButton,
  },
  el: "#app",
  data() {
    return {
      edit: 1,
      tab: "view-byob",

      formCustomEdit: {},
      formCustomRedeem: {},
      formCustomTrans: {},
      formCustomDisable: {},
      formCustomDelete: {},
      goalItemSavedAmount: "",
    };
  },
  methods: {
    ...mapActions([
      "deleteGoal",
      "editGoal",
      "disableGoal",
      "makeGoalRedemption",
      "makeTransferGoal",
      "fetchGoals",
    ]),
    updateCustomGoal() {
      this.loading = true;
      this.editGoal(this.formCustomEdit);
    },

    redeemCustomGoal() {
      this.formCustomRedeem.goal = this.goal.id;
      this.makeGoalRedemption(this.formCustomRedeem);
    },

    transferCustomGoal() {
      if (
        parseFloat(this.formCustomTrans.amount) >
          parseFloat(this.goal.saved_amount) ||
        parseFloat(this.formCustomTrans.amount) < 1
      ) {
        this.$toast.error(
          "Amount enter exceeds the saved amount or less than 1"
        );
        return;
      } else {
        this.loadingForm = true;
        this.formCustomTrans.goal_from = this.goal
          ? this.goal.id
          : this.formCustomTrans.goal_from;
        this.formCustomTrans.group = this.goal.group_goal
          ? this.goal.group_goal
          : "";
        this.makeTransferGoal(this.formCustomTrans);
      }
    },

    goalAmount(e) {
      let __id = e.target.value;
      this.goalItems.forEach((item) => {
        if (item.id === parseInt(__id)) {
          this.goalItemSavedAmount = `The ' ${
            item.name ? item.name : item.type_display
          } ' goal has a total of KES ${item.saved_amount} amount saved`;
        }
      });
    },

    formCustomEditForm(value = null) {
      this.errorForm = null;
      this.formCustomEdit = value
        ? this.$clone(value)
        : {
            name: this.cgoal.name,
            target_amount: this.cgoal.target_amount,
            end_date: this.cgoal.end_date,
            id: this.cgoal.id,
            client: this.currentUser.id,
          };
    },

    setFormCustomTransferData(value = null) {
      this.erroCustomForm = null;
      this.formCustomTrans = value
        ? this.$clone(value)
        : {
            goal_to: "",
            goal_from: this.cgoal,
            amount: 0,
            client: this.currentUser.id,
          };
    },

    showAlert() {
      // Use sweetalert2
      this.$swal("Hello Vue world!!!");
    },
    disableCustom() {
      this.formCustomDisable.id = this.cgoal.id;
      this.formCustomDisable.is_active = !this.cgoal.status;
      this.disableGoal(this.formCustomDisable);
    },
    error() {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
    deletionCustom() {
      this.$swal({
        title: "Do you want to delete?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.formCustomDelete.id = this.cgoal.id;
          this.deleteGoal(this.formCustomDelete);
          this.$swal("Goal deletion initiated!", "", "success");
        } else if (result.isDenied) {
          this.$swal("Goal deletion canceled", "", "info");
        }
      });
    },
    prev() {
      this.edit--;
    },

    next() {
      this.edit++;
    },

    customerRegister: function () {
      this.hasSubmitted = true;
    },
  },
  props: ["cgoal", "goalItems"],

  created() {
    this.setFormCustomTransferData();
    this.formCustomEditForm();
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "isUpdated",
      "isTransfered",
      "isDisabled",
      "isDeleted",
      "redeemStatus",
      "redeemMessage",
      "loadingInfo",
    ]),
  },

  watch: {
    redeemMessage() {
      this.$toast.info(this.redeemMessage);
      // if (this.redeemStatus) {
      //   this.$router.go();
      // } else {
      //   this.$toast.error(this.redeemMessage);
      // }
    },
    isTransfered() {
      if (this.isTransfered) {
        this.setFormTransferData();
        this.$toast.success("Transfered successfully");
        this.$emit("refreshState");
      }
    },
    isUpdated() {
      if (this.isUpdated) {
        this.$toast.success("Updated successfully");
        this.loading = false;
      } else {
        this.$toast.error("Failed to Update");
        this.loading = false;
      }
    },
    isDisabled() {
      if (this.isDisabled) {
        this.$router.go();
        this.$swal({
          icon: "success",
          title: this.cgoal.status == true ? "Disable" : "Enable",
          text: `Your Goal has been ${
            this.cgoal.status == true ? "Disabled" : "Enabled"
          }!`,
        });
      } else {
        this.$swal({
          icon: "Failed",
          title: "Action failed",
          text: "Action failed to be executed!",
        });
      }
    },
    isDeleted() {
      if (this.isDeleted) {
        this.$swal("Goal deletion initiated!", "", "success");
        this.$router.go();
        // this.$router.push({
        //   name: "Savings",
        // });
      }
    },
  },

  mounted() {
    setTooltip();
  },
};
</script>
