import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  deposited: "Processing...",
  depositStatus: false,
};

const getters = {
  is_deposited: (state) => state.deposited,
  depositStatus: (state) => state.depositStatus,
};

const mutations = {
  SET_DEPOSIT: (state, payload) => {
    state.depositStatus = payload;
  },
};

const actions = {
  make_deposit: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      var url_suffix = "client/deposits/auto/create";
      if (formData.type == "MPA") {
        url_suffix = "client/deposits/auto/create";
      } else if (formData.type == "WA") {
        url_suffix = "client/deposits/wallet/create";
      } else if (formData.type == "CA") {
        url_suffix = "client/deposits/card/create";
      } else if (formData.type == "PA") {
        url_suffix = "client/deposits/paypal/create";
      } else if (formData.type == "CROW") {
        url_suffix = "client/deposits/auto/create";
      }

      const url = URL + url_suffix;
      console.log("deposit", url, URL, url_suffix);
      await axios.post(url, formData, headers);
      // const { data, message } = response.data;

      commit("SET_DEPOSIT", true);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message:
            "Deposit initialized successfully.\n Please confirm you pin on the stk push to finalize this transaction.",
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit("SET_DEPOSIT", "Failed to deposit!!!");
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
