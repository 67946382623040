<template>
  <form style="padding: 2rem" class="form" id="formWhat">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                What are you investing for?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="6"
                        id="formWhat1"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat1"
                        >For retirement</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="7"
                        id="formWhat2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat2"
                        >For my children</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="8"
                        id="formWhat3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat3"
                        >For a car</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="9"
                        id="formWhat4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat4"
                        >For a home</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="10"
                        id="formWhat5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat5"
                        >Long term investment growth</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="11"
                        id="formWhat6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat6"
                        >Nothing Specific</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formWhat.choice"
                        form="formWhat"
                        :value="12"
                        id="formWhat7"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formWhat7"
                        >Other</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getWhatAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formWhat: {},
      step: 3,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getWhatAnswer() {
      console.log("What", this.formWhat);
      this.addInvestmentAnswer(this.formWhat);
      this.$emit("next", this.step);
    },
    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  // watch: {
  //   "invBoarding.data"() {
  //     if (this.invBoarding.data.id == 3) {
  //       this.$emit("submitWhat", 4);
  //     } else {
  //       return;
  //     }
  //   },
  // },
};
</script>
