<template>
  <nav aria-label="breadcrumb">
    <ol class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb me-sm-6">
      <li class="text-sm breadcrumb-item">
        <a class="opacity-8" href="#">Page</a>
      </li>
      <li class="text-sm breadcrumb-item active" aria-current="page">
        {{ currentPage }}
      </li>
    </ol>
    <h6 style="font-size: 32px" class="mb-0 font-weight-bolder">
      {{ currentPage }}
    </h6>
  </nav>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
};
</script>
