import Dashboard from "@/views/Dashboard.vue";
import Savings from "@/views/Savings.vue";
import Loans from "@/views/Loans.vue";
import LoansOnboard from "@/views/LoansOnboard.vue";
import Investments from "@/views/Investments.vue";
import InvestmentInAppOnboarding from "@/views/InvestmentInAppOnboarding.vue";
import Packages from "@/views/Packages.vue";
import Tables from "@/views/Tables.vue";
import Guarantor from "@/views/Guarantor.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";
import ForgotPin from "@/views/ForgotPin.vue";
import SignUp from "@/views/SignUp.vue";
import Personal from "@/views/Personal.vue";
import Survey from "@/views/Survey.vue";
import Tips from "@/views/Tips.vue";
import TipTwo from "@/views/TipTwo.vue";
import TipThree from "@/views/TipThree.vue";
import TipFour from "@/views/TipFour.vue";
import TipFive from "@/views/TipFive.vue";
import Product from "@/views/Product.vue";
import SavingsExplainer from "@/views/savings/Explainer.vue";
import Withdrawal from "@/views/withDraw/Withdrawal.vue";
// import WithdrawalAlt from "@/views/withDraw/WithdrawalAlt.vue";

//BYOB Onboarding
import CreateBYOB from "@/views/savings/CreateBYOB.vue";
import BYOBTarget from "@/views/savings/BYOBTarget.vue";
import BYOBDate from "@/views/savings/BYOBDate.vue";
import BYOBPeriod from "@/views/savings/BYOBPeriod.vue";
import BYOBInterest from "@/views/savings/BYOBInterest.vue";

//Custom Goal Onboarding
import CreateCustom from "@/views/savings/CreateCustom.vue";
import CustomName from "@/views/savings/CustomName.vue";
import CustomTarget from "@/views/savings/CustomTarget.vue";
import CustomDate from "@/views/savings/CustomDate.vue";
import CustomPeriod from "@/views/savings/CustomPeriod.vue";
import CustomInterest from "@/views/savings/CustomInterest.vue";

//Investments Onboarding
import InvestmentExplainer from "@/views/investments/Explainer.vue";
import Age from "@/views/investments/Age.vue";
import What from "@/views/investments/What.vue";
import Income from "@/views/investments/Income.vue";
import SavingsCover from "@/views/investments/SavingsCover.vue";
import MonthlySavings from "@/views/investments/MonthlySavings.vue";
import Period from "@/views/investments/Period.vue";
import InvestorPeriod from "@/views/investments/InvestorPeriod.vue";
import Familiar from "@/views/investments/Familiar.vue";
import Description from "@/views/investments/Description.vue";
import Protect from "@/views/investments/Protect.vue";
import Congratulations from "@/views/investments/Congrats.vue";

//Borrowing Onboarding
import BorrowingExplainer from "@/views/borrowing/Explainer.vue";
import BorrowerInformation from "@/views/borrowing/Personal.vue";
import Amount from "@/views/borrowing/Amount.vue";
import ListedLenders from "@/views/borrowing/ListedLenders.vue";
import Guarantors from "@/views/borrowing/Guarantors.vue";
import LoanTips from "@/views/borrowing/LoanTips.vue";
import Tax from "@/views/borrowing/Tax.vue";
import SendOTP from "@/views/borrowing/SendOTP.vue";

//Lending Onboarding
import LendingExplainer from "@/views/lending/Explainer.vue";
import LendingBYOB from "@/views/lending/CreateBYOB.vue";
import BYOBDeposit from "@/views/lending/BYOBDeposit.vue";
// import ListedLenders from "@/views/borrowing/ListedLenders.vue";
// import Guarantors from "@/views/borrowing/Guarantors.vue";
// import LoanTips from "@/views/borrowing/LoanTips.vue";

//Loans Dashboard
import Borrower from "@/views/loansdash/Borrower.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { authRequired: true },
    component: Dashboard,
  },
  {
    path: "/savings",
    name: "Savings",
    meta: { authRequired: true },
    component: Savings,
  },
  {
    path: "/loans",
    name: "Loans",
    meta: { authRequired: true },
    component: Loans,
  },
  {
    path: "/loans/limit",
    name: "Loan Limit",
    meta: { authRequired: true },
    component: LoansOnboard,
  },
  {
    path: "/investments",
    name: "Investments",
    meta: { authRequired: true },
    component: Investments,
  },
  {
    path: "/investment-onboarding",
    name: "Investment Onboarding",
    // meta: { authRequired: true },
    component: InvestmentInAppOnboarding,
  },

  {
    path: "/tables",
    name: "Tables",
    meta: { authRequired: true },
    component: Tables,
  },
  {
    path: "/guarantor",
    name: "Guarantor",
    meta: { authRequired: true },
    component: Guarantor,
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    meta: { authRequired: true },
    component: Withdrawal,
    // component: WithdrawalAlt,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { authRequired: true },
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/forgot-pin",
    name: "Forgot Pin",
    component: ForgotPin,
  },
  {
    path: "/register",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/info",
    name: "Personal Information",
    meta: { authRequired: true },
    component: Personal,
  },
  {
    path: "/survey",
    name: "Quick Survey",
    meta: { authRequired: true },
    component: Survey,
  },
  {
    path: "/tips",
    name: "Tips",
    meta: { authRequired: true },
    component: Tips,
  },
  {
    path: "/tips/2",
    name: "Tip Two",
    meta: { authRequired: true },
    component: TipTwo,
  },
  {
    path: "/tips/3",
    name: "Tip Three",
    meta: { authRequired: true },
    component: TipThree,
  },
  {
    path: "/tips/4",
    name: "Tip Four",
    meta: { authRequired: true },
    component: TipFour,
  },
  {
    path: "/tips/5",
    name: "Tip Five",
    meta: { authRequired: true },
    component: TipFive,
  },
  {
    path: "/product",
    name: "Product",
    meta: { authRequired: true },
    component: Product,
  },
  {
    path: "/save/intro",
    name: "Savings Explainer",
    meta: { authRequired: true },
    component: SavingsExplainer,
  },
  {
    path: "/save/byob",
    name: "Create BYOB",
    meta: { authRequired: true },
    component: CreateBYOB,
  },
  {
    path: "/save/byob/1",
    name: "BYOB Target",
    meta: { authRequired: true },
    component: BYOBTarget,
  },
  {
    path: "/save/byob/2",
    name: "BYOB Date",
    meta: { authRequired: true },
    component: BYOBDate,
  },
  {
    path: "/save/byob/3",
    name: "BYOB Period",
    meta: { authRequired: true },
    component: BYOBPeriod,
  },
  {
    path: "/save/byob/4",
    name: "BYOB Interest",
    meta: { authRequired: true },
    component: BYOBInterest,
  },
  {
    path: "/save/custom",
    name: "Create Custom",
    meta: { authRequired: true },
    component: CreateCustom,
  },
  {
    path: "/save/custom/1",
    name: "Custom Name",
    meta: { authRequired: true },
    component: CustomName,
  },
  {
    path: "/save/custom/2",
    name: "Custom Target",
    meta: { authRequired: true },
    component: CustomTarget,
  },
  {
    path: "/save/custom/3",
    name: "Custom Date",
    meta: { authRequired: true },
    component: CustomDate,
  },
  {
    path: "/save/custom/4",
    name: "Custom Period",
    meta: { authRequired: true },
    component: CustomPeriod,
  },
  {
    path: "/save/custom/5",
    name: "Custom Interest",
    meta: { authRequired: true },
    component: CustomInterest,
  },
  {
    path: "/invest/intro",
    name: "Investment Explainer",
    meta: { authRequired: true },
    component: InvestmentExplainer,
  },
  {
    path: "/invest/1",
    name: "Investment Age",
    meta: { authRequired: true },
    component: Age,
  },
  {
    path: "/invest/2",
    name: "Investment What",
    meta: { authRequired: true },
    component: What,
  },
  {
    path: "/invest/3",
    name: "Investment Income",
    meta: { authRequired: true },
    component: Income,
  },
  {
    path: "/invest/4",
    name: "Monthly Savings",
    meta: { authRequired: true },
    component: MonthlySavings,
  },
  {
    path: "/invest/5",
    name: "Savings Cover",
    meta: { authRequired: true },
    component: SavingsCover,
  },
  {
    path: "/invest/6",
    name: "Period",
    meta: { authRequired: true },
    component: Period,
  },
  {
    path: "/invest/7",
    name: "Investor Period",
    meta: { authRequired: true },
    component: InvestorPeriod,
  },
  {
    path: "/invest/8",
    name: "Familiar",
    meta: { authRequired: true },
    component: Familiar,
  },
  {
    path: "/invest/9",
    name: "Description",
    meta: { authRequired: true },
    component: Description,
  },
  {
    path: "/invest/10",
    name: "Protect",
    meta: { authRequired: true },
    component: Protect,
  },
  {
    path: "/invest/complete",
    name: "Congratulations",
    meta: { authRequired: true },
    component: Congratulations,
  },
  {
    path: "/loans/borrow",
    name: "Loan Borrower",
    meta: { authRequired: true },
    component: Borrower,
  },
  {
    path: "/packages",
    name: "Sortika Packages",
    meta: { authRequired: true },
    component: Packages,
  },
  {
    path: "/borrow/intro",
    name: "Borrowing Explainer",
    meta: { authRequired: true },
    component: BorrowingExplainer,
  },
  {
    path: "/borrow/1",
    name: "Borrower Information",
    meta: { authRequired: true },
    component: BorrowerInformation,
  },
  {
    path: "/borrow/2",
    name: "Tax",
    meta: { authRequired: true },
    component: Tax,
  },
  {
    path: "/borrow/3",
    name: "Loan Amount",
    meta: { authRequired: true },
    component: Amount,
  },
  {
    path: "/borrow/4",
    name: "Listed Lenders",
    meta: { authRequired: true },
    component: ListedLenders,
  },
  {
    path: "/borrow/5",
    name: "Send OTP",
    meta: { authRequired: true },
    component: SendOTP,
  },
  {
    path: "/borrow/6",
    name: "Guarantors",
    meta: { authRequired: true },
    component: Guarantors,
  },
  {
    path: "/borrow/7",
    name: "Loan Tips",
    meta: { authRequired: true },
    component: LoanTips,
  },
  {
    path: "/lend/intro",
    name: "Lending Explainer",
    meta: { authRequired: true },
    component: LendingExplainer,
  },
  {
    path: "/lend/1",
    name: "Lending BYOB",
    meta: { authRequired: true },
    component: LendingBYOB,
  },
  {
    path: "/lend/2",
    name: "BYOB Deposit",
    meta: { authRequired: true },
    component: BYOBDeposit,
  },
];

export default routes;
