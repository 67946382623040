<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Borrowing Limit
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="
                  parseInt(Number(loanProfile.borrowing_limit_amount))
                "
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Lending Limit
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="parseInt(Number(loanProfile.lending_limit))"
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Passive Savings" value="9%" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrowing limit" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div> -->
    </div>
    <!-- <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
            <div class="col-lg-4 col-sm-8">
                <nav-pill-loan>
                </nav-pill-loan>
            </div>
        </div> -->
    <div style="display: flex; justify-content: space-between">
      <div
        style="margin-top: 10px"
        class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8"
        id="nav"
      >
        <ul
          style="padding-left: 1rem; padding-right: 1rem"
          class="nav nav-pills nav-fill p-1"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1 active active"
              data-bs-toggle="tab"
              id="borrow"
              href="#"
              role="tab"
              aria-controls="Borrow"
              aria-selected="true"
              v-on:click="makeActive('borrowActive')"
            >
              Borrow
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="#"
              id="lend"
              role="tab"
              aria-controls="Lend"
              v-on:click="makeActive('lendActive')"
              aria-selected="false"
            >
              Lend
            </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 0.5rem">
        <router-link :to="{ name: 'Guarantor' }">
          <soft-button
            class="mb-2"
            variant="gradient"
            color="dark"
            style="margin-right: 0.4rem"
            >Guarantor
          </soft-button>
        </router-link>
        <soft-button
          class="mb-2"
          variant="gradient"
          color="dark"
          data-bs-toggle="modal"
          data-bs-target="#modal-borrow"
          >Borrow
        </soft-button>
      </div>
    </div>
    <div>
      <div
        style="
          margin-left: -1.2rem !important;
          margin-right: -1.2rem !important;
        "
        id="borrow"
        v-show="isActiveTab('borrowActive')"
      >
        <borrower
          :loanProfile="loanProfile"
          :loanLendersData="loanLendersData"
        />
      </div>
      <div
        style="
          margin-left: -1.2rem !important;
          margin-right: -1.2rem !important;
        "
        id="lend"
        v-show="isActiveTab('lendActive')"
      >
        <lender :loanProfile="loanProfile" />
      </div>
    </div>
  </div>
  <!-- Borrow Modal -->
  <div class="col-md-4">
    <div
      class="modal fade"
      id="modal-borrow"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <form @submit.prevent="handleBorrowLoan" class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h6 class="modal-title" id="modal-title-withdraw">Borrow</h6>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="example-text-input" class="form-control-label"
              >Select Finance Source?</label
            >
            <select
              class="form-control"
              v-model="form.from"
              @change="fetchData"
            >
              <option value="savings">From Savings (Self Loan)</option>
              <option value="listed">From Listed Lenders</option>
              <option value="group">From Groups</option>
              <option value="specific">From Specific User</option>
              <option value="broadcast">From Any Legible Lender</option>
            </select>
            <div
              v-if="form.from == 'specific'"
              class="mb-3"
              style="margin-top: 2rem"
            >
              <label for="example-text-input" class="form-control-label"
                >Lender Phone Number</label
              >
              <input
                class="form-control"
                v-model="form.lender_phone"
                type="text"
                placeholder="+2547..."
                id="example-text-input"
              />
            </div>

            <!-- Your groups  -->
            <div
              v-if="form.from == 'group'"
              class="mb-3"
              style="margin-top: 2rem"
            >
              <label
                style="margin-bottom: 1.5rem"
                for="example-text-input"
                class="form-control-label"
                >Groups (Select your preferred group below)</label
              >
              <div
                v-for="groupInfo in groupsData"
                :key="groupInfo.id"
                style="width: 100%; height: 80px; margin-top: -1rem"
                class="radio-card"
              >
                <input
                  type="radio"
                  v-model="form.group_lender"
                  :value="groupInfo.id"
                  id="customRadio2"
                />
                <label
                  style="position: absolute; margin: 20px; cursor: pointer"
                  for="customRadio2"
                  >{{ groupInfo.name }}
                  (saved Kes.
                  {{ groupInfo.saved_amount }})</label
                >
              </div>
            </div>

            <!-- listed lenders -->
            <div
              v-if="form.from == 'listed'"
              class="mb-3"
              style="margin-top: 2rem"
            >
              <label
                style="margin-bottom: 1.5rem"
                for="example-text-input"
                class="form-control-label"
                >Listed Lenders (Select your preferred lender below)</label
              >
              <div
                class="w-full flex justify-content-center"
                v-if="listedLenders.length < 1"
              >
                <label class="text-secondary">
                  &nbsp; &nbsp; <i class="fas fa-exclamation-triangle"></i> No
                  Lenders Found!!</label
                >
              </div>

              <div
                v-else
                v-for="lenderInfo in listedLenders"
                :key="lenderInfo.id"
                style="width: 100%; height: 80px; margin-top: -1rem"
                class="radio-card"
              >
                <input
                  type="radio"
                  v-model="form.client_lender"
                  :value="lenderInfo.code"
                  id="customRadio2"
                />
                <label
                  style="position: absolute; margin: 20px; cursor: pointer"
                  for="customRadio2"
                  >{{ lenderInfo.name }}</label
                >
              </div>
            </div>
            <label for="example-text-input" class="form-control-label"
              >Amount</label
            >
            <input
              class="form-control"
              v-model="form.amount"
              type="number"
              placeholder="KES"
              id="example-text-input"
            />
            <label for="example-text-input" class="form-control-label"
              >Interest (%)</label
            >
            <input
              class="form-control"
              v-model="form.interest"
              type="number"
              placeholder="5"
              id="example-text-input"
            />
            <label for="example-text-input" class="form-control-label"
              >Date Payable</label
            >
            <input
              class="form-control"
              v-model="form.date_payable"
              type="date"
              id="example-text-input"
            />

            <span
              v-if="form.from !== 'specific'"
              style=""
              id="collapseGuarantor"
            >
              <div>
                <label>Search and Add Guarantors</label>
                <input
                  class="form-control mb-2"
                  type="text"
                  ref="bGuarantorCheck"
                  id="example-text-input"
                />
                <small class="text-danger" v-if="bGuarantorAvailability != ''">
                  {{ bGuarantorAvailability }}
                </small>
              </div>
              <p style="font-size: 12px">
                If you don't have any guarantors click on
                <strong>Invite guarantor button</strong>
              </p>
              <div class="modal-footer" style="border-top: none">
                <soft-button
                  class="mb-2"
                  variant="gradient"
                  color="success"
                  type="button"
                  @click="clientLookUp(this.$refs['bGuarantorCheck'].value)"
                  fullWidth
                  >Add Guarantor</soft-button
                >
                <soft-button
                  v-if="bGuarantorAvailability != ''"
                  class="mb-2"
                  variant="gradient"
                  color="dark"
                  type="button"
                  fullWidth
                  >Invite Guarantor</soft-button
                >
              </div>
            </span>

            <div class="modal-body" v-if="form.from !== 'specific'">
              <label
                style="margin-top: 1rem"
                for="example-text-input"
                class="form-control-label"
                >Guarantors</label
              >
              <div class="modal-body">
                <label
                  style="margin-top: 1rem"
                  for="example-text-input"
                  class="form-control-label"
                  >Guarantors</label
                >
                <ul class="list-unstyled max-width-200">
                  <li
                    v-for="g in bGuarantorsList"
                    :key="g.id"
                    class="w-100 mb-2"
                    style="min-width: 300px"
                  >
                    <span
                      class="text-sm d-flex justify-content-between align-items-center"
                      style="width: 100%"
                    >
                      <span class="d-flex w-75 align-items-center">
                        <span style="width: 40px; height: 40px">
                          <img
                            :src="
                              g?.image === null
                                ? '@/assets/img/avatar.png'
                                : g?.image
                            "
                            style="
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                              border-radius: 50%;
                            "
                            alt=""
                          />
                        </span>
                        &nbsp;
                        {{ g.full_name }}
                        <span class="text-dark ms-sm-2 font-weight-bold">
                          {{ g.phone }}
                        </span>
                      </span>
                      <span @click="removeGuarantor(g.id)">
                        <i
                          class="fas fa-times-circle text-danger text-lg cursor-pointer"
                        >
                        </i>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style="border-top: none; margin-top: 2rem" class="modal-footer">
            <soft-button
              class="mb-2"
              variant="gradient"
              type="submit"
              color="success"
              >Borrow Now</soft-button
            >
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Close</soft-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
import Borrower from "./loansdash/Borrower.vue";
import Lender from "./loansdash/Lender.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
import Vue3Autocounter from "vue3-autocounter";
export default {
  name: "loans-default",

  components: {
    SoftButton,
    Borrower,
    Lender,
    Vue3Autocounter,
  },

  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: "borrowActive", // Borrow is chosen by default

      loanProfile: {},
      loanItems: [],
      filters: {},
      stage: "active",
      bGuarantorAvailability: "",
      bGuarantorsList: [],
      loanLendersData: [],
      listedLenders: [],
      groupsData: [],

      form: {},
    };
  },

  created() {
    this.getLoanProfile();
    this.fetchLenders();
    this.fetchClientGroups();
    // this.fetchLoans();
    // this.fetchBorrowerLoan(this.stage);
    var scripts = [
      "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/multistep-form.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/datatables.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/soft-ui-dashboard.min.js?v=1.1.1",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/choices.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/smooth-scrollbar.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/dragula/dragula.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/chartjs.min.js",
      "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/countup.min.js",
      "/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

  methods: {
    ...mapActions([
      "getLoanProfile",
      "getLoans",
      "borrowLoan",
      "lookUpClient",
      "getLenders",
      "getListedLenders",
      "getClientGroups",
    ]),
    // fetchBorrowerLoan(stage) {
    //   this.getBorrowerLoan({ stage });
    // },
    fetchLoans() {
      this.getLoans();
    },
    fetchLenders() {
      this.getLenders();
    },
    fetchListedLenders() {
      this.getListedLenders();
    },
    fetchClientGroups() {
      this.getClientGroups({ is_member: "true" });
    },

    fetchData() {
      if (this.form.from == "listed") {
        this.fetchListedLenders();
      }
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },
    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            from: "savings",
            amount: "",
            interest: 0,
            type: "",
            persona: "",
            lender_phone: "",
          };
    },
    handleBorrowLoan() {
      let oldPhone = this.form?.lender_phone;
      let phone = oldPhone;
      let code = "+254";

      if (phone && phone?.startsWith("0")) {
        phone = phone.substring(1);
        phone = code + phone;
        // } else if (phone.startsWith("+")) {
        //   phone = phone.substring(1);
        // phone = oldPhone;
      } else {
        phone = oldPhone;
      }

      this.form.lender_phone = phone;

      if (this.form.from == "savings") {
        this.form.type = "SELF";
        this.form.persona = "CLIENT";
      }

      if (this.form.from == "listed") {
        this.form.type = "O2OC";
        this.form.persona = "CLIENT";
        // this.form.lender_phone =
      }
      if (this.form.from == "group") {
        this.form.type = "O2OG";
        this.form.persona = "GROUP";
      }
      if (this.form.from == "specific" || this.form.from == "broadcast") {
        this.form.type = "O2OC";
        this.form.persona = "CLIENT";
      }

      let guarantorsIDs = [];
      this.bGuarantorsList.map((gnt) => {
        let gbDict = { client_id: gnt.id };
        guarantorsIDs.push(gbDict);
      });

      if (this.form.from !== "specific") {
        this.form.guarantors = guarantorsIDs;
      }

      this.form.client_borrower = this.loanProfile.client;

      this.borrowLoan(this.form);
    },

    clientLookUp(e) {
      let oldPhone = e;
      let phone = oldPhone;
      let code = "254";

      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        phone = code + phone;
      } else if (phone.startsWith("+")) {
        phone = phone.substring(1);
        // phone = oldPhone;
      } else {
        phone = oldPhone;
      }
      let __phone = { phone_number: phone.toString() };
      this.lookUpClient(__phone);
      this.$refs["bGuarantorCheck"].value = "";
      this.bGuarantorAvailability = "";
    },
    removeGuarantor(id) {
      this.bGuarantorsList = this.bGuarantorsList.filter((g) => g.id != id);
    },
  },

  computed: {
    ...mapGetters([
      "loan_profile",
      "listed_lenders",
      "loans",
      "clientFound",
      "currentUser",
      "loadingInfo",
      "loanLenders",
      "clientGroups",
    ]),
  },

  watch: {
    loan_profile() {
      this.loanProfile = this.loan_profile;
    },
    clientGroups() {
      this.groupsData = this.clientGroups;
    },
    // loadingInfo() {
    //   this.$toast.info(this.loadingInfo.message);
    // },
    loanLenders() {
      this.loanLendersData = this.loanLenders;
    },
    listed_lenders() {
      this.listedLenders = this.listed_lenders;
    },
    loans() {
      this.loansItems = this.loans;
    },
    clientFound() {
      if (this.clientFound.id) {
        if (this.bGuarantorsList.length > 0) {
          let gIds = [];

          this.bGuarantorsList.map((gItem) => {
            gIds.push(gItem.id);
          });

          if (gIds.includes(this.clientFound.id) == true) {
            this.bGuarantorAvailability = "This number already selected";
            return;
          } else if (this.clientFound.id == this.currentUser.id) {
            this.bGuarantorAvailability =
              "You cannot guarantee yourself a loan.";
            return;
          } else {
            this.bGuarantorsList = [...this.bGuarantorsList, this.clientFound];
          }
        } else if (this.clientFound.id == this.currentUser.id) {
          this.bGuarantorAvailability = "You cannot guarantee yourself a loan.";
          return;
        } else {
          this.bGuarantorsList = [...this.bGuarantorsList, this.clientFound];
        }
      } else if (this.clientFound.id == this.currentUser.id) {
        this.bGuarantorAvailability = "You cannot guarantee yourself a loan.";
        return;
      } else {
        this.$toast.error("Guarantor does not exit!! Confirm the phone number");
      }
    },
    // borrowLoans() {
    //   this.borrowLoanData = this.borrowLoans;
    // },
  },
  mounted() {
    setTooltip();
  },
};
</script>
