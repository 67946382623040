<template>
  <form style="padding: 2rem" class="form" id="formAge">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                What's your age bracket?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        form="formAge"
                        type="radio"
                        name="choice"
                        v-model="formAge.choice"
                        :value="1"
                        id="age1"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="age1"
                        >18 - 25</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formAge"
                        type="radio"
                        name="choice"
                        v-model="formAge.choice"
                        :value="2"
                        id="age2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="age2"
                        >26 - 35</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formAge"
                        type="radio"
                        name="choice"
                        v-model="formAge.choice"
                        :value="3"
                        id="age3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="age3"
                        >36 - 45</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formAge"
                        type="radio"
                        name="choice"
                        v-model="formAge.choice"
                        :value="4"
                        id="age4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="age4"
                        >46 - 55</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formAge"
                        type="radio"
                        name="choice"
                        v-model="formAge.choice"
                        :value="5"
                        id="age5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="age5"
                        >56+</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getAgeAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "investment-onboarding-in-app-explainer",
  components: {
    SoftButton,
  },
  el: "#app",
  data() {
    return {
      formAge: {},
      step: 2,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getAgeAnswer() {
      console.log("submitAge", this.formAge);
      this.$emit("submitAge", this.formAge);
      // this.addInvestmentAnswer(this.formAge);
    },

    previous() {
      this.$emit("prev", this.step);
    },
  },

  computed: {
    ...mapGetters(["invBoarding"]),
  },

  // watch: {
  //   "invBoarding.data"() {
  //     if (!this.invBoarding.complete) {
  //       // this.$emit("submitAge", this.formAge);
  //       this.$emit("next", this.step);
  //     } else {
  //       return;
  //     }
  //   },
  // },
};
</script>
