<template>
  <form style="padding: 2rem" class="form" id="formMonthlySavings">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                How much do you save each month?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div class="pb-0 text-explainer">
                      <div>
                        <div
                          style="width: 100%; height: 80px"
                          class="radio-card"
                        >
                          <input
                            form="formMonthlySavings"
                            v-model="formMonthlySavings.choice"
                            :value="18"
                            type="radio"
                            name="flexRadioDefault"
                            id="formMS2"
                          />
                          <label
                            style="
                              position: absolute;
                              margin: 20px;
                              cursor: pointer;
                            "
                            for="formMS2"
                            >Less than 10,000</label
                          >
                        </div>
                        <div
                          style="width: 100%; height: 80px; margin-top: -1rem"
                          class="radio-card"
                        >
                          <input
                            form="formMonthlySavings"
                            v-model="formMonthlySavings.choice"
                            :value="19"
                            type="radio"
                            name="flexRadioDefault"
                            id="formMS3"
                          />
                          <label
                            style="
                              position: absolute;
                              margin: 20px;
                              cursor: pointer;
                            "
                            for="formMS3"
                            >10,000 to 25,000</label
                          >
                        </div>
                        <div
                          style="width: 100%; height: 80px; margin-top: -1rem"
                          class="radio-card"
                        >
                          <input
                            form="formMonthlySavings"
                            v-model="formMonthlySavings.choice"
                            :value="20"
                            type="radio"
                            name="flexRadioDefault"
                            id="formMS4"
                          />
                          <label
                            style="
                              position: absolute;
                              margin: 20px;
                              cursor: pointer;
                            "
                            for="formMS4"
                            >26,000 to 36,000</label
                          >
                        </div>
                        <div
                          style="width: 100%; height: 80px; margin-top: -1rem"
                          class="radio-card"
                        >
                          <input
                            form="formMonthlySavings"
                            v-model="formMonthlySavings.choice"
                            :value="21"
                            type="radio"
                            name="flexRadioDefault"
                            id="formMS5"
                          />
                          <label
                            style="
                              position: absolute;
                              margin: 20px;
                              cursor: pointer;
                            "
                            for="formMS5"
                            >36,000 to 50,000</label
                          >
                        </div>
                        <div
                          style="width: 100%; height: 80px; margin-top: -1rem"
                          class="radio-card"
                        >
                          <input
                            form="formMonthlySavings"
                            v-model="formMonthlySavings.choice"
                            :value="22"
                            type="radio"
                            name="flexRadioDefault"
                            id="formMS6"
                          />
                          <label
                            style="
                              position: absolute;
                              margin: 20px;
                              cursor: pointer;
                            "
                            for="formMS6"
                            >50,000 and above</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getMonthlySavingsAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formMonthlySavings: {},
      step: 5,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getMonthlySavingsAnswer() {
      console.log("MonthlySavings", this.formMonthlySavings);
      this.addInvestmentAnswer(this.formMonthlySavings);
    },

    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.success"() {
      if (this.invBoarding.success) {
        this.$emit("submitMonthlySavings", this.formMonthlySavings);
      } else {
        return;
      }
    },
  },
};
</script>
