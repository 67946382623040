<template>
  <div style="margin-top: 1.5rem" class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Current balance"
          :value="
            'KES ' + parseFloat(profileData?.saved_amount).toLocaleString()
          "
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Available balance"
          :value="
            'KES ' +
            parseFloat(profileData?.saved_amount_projected).toLocaleString()
          "
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="No. Of Referrals"
          :value="count"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Referral Balance"
          :value="'KES ' + parseFloat(wallet?.amount).toLocaleString()"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
    </div>
    <div class="col-md-12 mb-4">
      <payment-methods-card
        :userId="profileData?.id"
        :userPhone="profileData?.phone_number"
        :payment_method="payment_method"
      />
    </div>
    <div class="mt-3 row">
      <div
        style="margin-bottom: 1rem"
        class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0"
      >
        <profile-info-card
          title="Personal Information"
          :profileData="profileData"
          :info="{
            fullName: profileData.full_name,
            mobile: profileData.phone_number,
            email: profileData.email_address,
            dob: profileData.date_of_birth,
            identityNo: profileData?.national_id_number,
            nationality: 'Kenyan',
            location: profileData?.address,
            mailingAddress: '43286 - 00100 GPO',
            kraPin: profileData?.kra_pin_number,
          }"
          :investProfile="investProfile"
          :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
        />
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Platform Settings</h6>
          </div>
          <div class="p-3 card-body">
            <h6
              class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
            >
              Application
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-button
                  color="dark"
                  variant="gradient"
                  data-bs-toggle="modal"
                  data-bs-target="#stkPush"
                >
                  Adjust Savings STK Push
                </soft-button>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault4"
                  name="Product Update"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  checked
                  >Savings Auto-deduct</soft-switch
                >
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault2"
                  name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80"
                  class="ps-0 ms-auto"
                  checked
                  >Browser Notifications</soft-switch
                >
              </li>
            </ul>
            <h6
              class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
            >
              Savings Settings
            </h6>
            <ul class="list-group">
              <!-- <li class="px-0 border-0 list-group-item">
                <label>Payment method(deposit)</label>
                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light-survey">
                  M-PESA
                </soft-checkbox>
                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light-survey">
                  Card
                </soft-checkbox>
              </li>
              <li class="px-0 border-0 list-group-item">
                <label>Passive savings payment method</label>
                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light-survey">
                  M-PESA
                </soft-checkbox>
                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light-survey">
                  Card
                </soft-checkbox>
              </li> -->
              <li class="px-0 border-0 list-group-item">
                <form @submit.prevent="editPassive">
                  <label style="margin-bottom: 1rem"
                    >Passive savings percentage (%)</label
                  >
                  <input
                    id="text"
                    type="number"
                    class="form-control"
                    ref="passiveField"
                    :value="profileData.passive_savings"
                    placeholder="Eg. 5"
                    aria-label="Location"
                  />
                  <soft-button
                    style="margin-right: 1rem"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="dark"
                    type="submit"
                    fullWidth
                  >
                    Update Savings Percentage
                  </soft-button>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Referral Link</h6>
          </div>
          <div class="border-1 border-secondary p-3">
            <div class="d-flex align-items-center">
              <div class="form-group w-70">
                <div class="input-group bg-gray-200">
                  <input
                    class="form-control form-control-sm"
                    :value="profileData.referal_link"
                    type="text"
                    disabled=""
                    onfocus="focused(this)"
                    onfocusout="defocused(this)"
                    ref="mylink"
                    rel="noopener noreferrer"
                  />
                  <span
                    class="input-group-text bg-transparent"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="Referral code is reusable"
                    data-bs-original-title="Referral link, copy and share with friends on socials, i.e. facebook, whatsapp, telegram, sms, email, twitter"
                    ><i class="ni ni-key-25"></i
                  ></span>
                </div>
              </div>
              <button
                href="javascript:;"
                class="btn btn-outline-secondary ms-2"
                style="border: 2px solid"
                @click="copyUrl"
              >
                Copy
              </button>
            </div>
            <p class="text-xs mb-1">
              Copy your referral link and share with your friends on your social
              channel
            </p>
          </div>
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Active Referrals</h6>
          </div>
          <div class="p-3 card-body" v-if="clients.length > 0">
            <ul class="list-group">
              <li
                class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                v-for="client in clients"
                :key="client.id"
              >
                <soft-avatar
                  class="me-3"
                  :img="
                    client?.image === null
                      ? './assets/img/avatar.png'
                      : client?.image
                  "
                  alt="referral-image"
                  border-radius="md"
                  shadow="regular"
                  style="border-radius: 50% !important"
                />
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">{{ client?.full_name }}</h6>
                  <p class="mb-0 text-xs">
                    {{ client?.phone_number }}
                  </p>
                </div>
                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  >KES {{ client?.saved_amount }}</a
                >
              </li>
            </ul>
            <div style="display: flex; justify-content: center">
              <soft-button
                class="my-4 mb-2"
                variant="gradient"
                color="dark"
                fullWidth
              >
                Withdraw Referral Balance
              </soft-button>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 3rem;
            "
            class="p-3 card-body"
            v-else
          >
            <i
              style="
                color: gray;
                font-size: 2rem !important;
                padding-bottom: 3rem !important;
              "
              class="fa fa-chain-broken text-sm ms-1"
              aria-hidden="true"
            ></i
            >No Active Referrals!!
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="stkPush"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalMessageTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <form @submit.prevent="addPayMethod" class="modal-content">
        <div style="border-bottom: none" class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Savings STK Push Opt-in or Opt-out
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" :key="init">
          <div
            style="width: 100%; height: 80px"
            class="d-flex justify-content-between align-items-center"
            v-for="(pm, index) in payment_method.filter(
              (met) => met.type == 'MP'
            )"
            :key="pm.id"
          >
            <opt-form :pm="pm" :index="index" />
          </div>
          <soft-button
            class="my-4 mb-2"
            variant="gradient"
            color="dark"
            data-bs-dismiss="modal"
          >
            Close
          </soft-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ProfileInfoCard from "./ProfileInfoCard.vue";
import PaymentMethodsCard from "./PaymentMethodsCard.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import SoftButton from "@/components/SoftButton.vue";
// import {
//   faFacebook,
//   faTwitter,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";
import setTooltip from "@/assets/js/tooltip.js";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
// import { Doughnut } from 'vue-chartjs'
import { mapActions, mapGetters } from "vuex";
import OptForm from "./optForm.vue";

// ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    SoftButton,
    // SoftCheckbox,
    // SoftInput,
    // Doughnut,
    // DefaultProjectCard,
    // PlaceHolderCard,
    MiniStatisticsCard,
    PaymentMethodsCard,
    OptForm,
  },
  props: ["profileData"],
  data() {
    return {
      showMenu: false,
      sophie,
      // faFacebook,
      // faTwitter,
      // faInstagram,
      count: 0,
      clients: [],
      wallet: {},
      investProfile: {},
      payment_method: [],
      init: 0,
      // labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
      // datasets: [
      //   {
      //     backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
      //     data: [40, 20, 80, 10]
      //   }
      // ],
      // options: {
      //   responsive: true,
      //   maintainAspectRatio: false
      // }
    };
  },
  created() {
    this.getReferrals();
    this.getInvestmentsProfile();

    this.getPaymentMethods();
    var scripts = [
      "@/assets/js/datatables.js",
      "@/assets/js/soft-ui-dashboard.min.js?v=1.1.1",
      "@/assets/js/choices.min.js",
      "@/assets/js/smooth-scrollbar.min.js",
      "@/assets/js/dragula.min.js",
      "@/assets/js/chartjs.min.js",
      "@/assets/js/tilt.min.js",
      "@/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    ...mapActions([
      "getReferrals",
      "getInvestmentsProfile",
      "UpdatePassiveSavings",
      "optInOut",
      "getPaymentMethods",
    ]),

    editPassive() {
      const passive_savings = this.$refs["passiveField"].value;
      this.UpdatePassiveSavings({
        passive_savings: passive_savings,
      });
    },

    copyUrl() {
      const el = document.createElement("textarea");
      el.value = this.profileData.referal_link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },
  computed: {
    ...mapGetters([
      "referred_clients",
      "referred_count",
      "referral_wallet",
      "investmentsProfile",
      "payMethods",
    ]),
  },
  watch: {
    referred_clients() {
      this.clients = this.referred_clients;
    },

    payMethods() {
      this.payment_method = this.payMethods;
      this.init += 1;
    },

    referred_count() {
      this.count = this.referred_count;
    },
    referral_wallet() {
      this.wallet = this.referral_wallet;
    },
    investmentsProfile() {
      this.investProfile = this.investmentsProfile;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
