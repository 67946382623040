<template>
  <form style="padding: 2rem" class="form" id="formComplete">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">Congratulations!</p>
              <div class="explainer-body">
                <div class="pb-0 text-explainer">
                  <p class="mb-0" style="font-weight: 700; text-align: center">
                    You are a {{ invProfile }} investor
                  </p>
                  <p
                    class="mb-0"
                    style="
                      padding-bottom: 1.6rem;
                      margin-top: 1rem;
                      text-align: center;
                    "
                  >
                    You are willing to take considerable risk for better returns
                    on your investments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getCompleteAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapGetters } from "vuex";
export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  props: ["invProfile"],
  data() {
    return {
      formComplete: {},
      step: 12,
    };
  },
  methods: {
    previous() {
      this.$emit("prev", this.step);
    },
    getCompleteAnswer() {
      this.$router.push({ name: "Investments" });
    },
  },

  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      if (this.invBoarding.complete) {
        this.$router.push({ name: "Investments" });
      } else {
        return;
      }
    },
  },
};
</script>
