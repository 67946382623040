<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-lg-4 mb-2" v-for="gl in goals" :key="gl.id">
        <active-investments-card :goal="gl" style="margin-bottom: 1rem" />
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import ActiveInvestmentsCard from "./ActiveInvestmentsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
//   import { mapGetters, mapActions } from "vuex";
export default {
  name: "active-investment-tab",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  components: {
    ActiveInvestmentsCard,
  },

  props: ["goals"],

  mounted() {
    setTooltip();
  },
};
</script>
