<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="
              padding: 1.1rem !important;
              padding-bottom: 3.7rem !important;
            ">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Total Savings
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter ref="counter" :startAmount="0" :endAmount="parseInt(Number(currentUser.saved_amount))"
                :duration="3" prefix="KES " separator="," decimalSeparator="." :decimals="2" :autoinit="true" />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card style="padding-bottom: 2.6rem !important" title="Savings Growth" value="5%" :percentage="{
          value: '+3%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
        <div class="card overflow-hidden">
          <div style="padding-top: 0.9rem; padding-bottom: 1rem" class="card-body">
            <div>
              <p class="text-sm text-capitalize font-weight-bold">This Week</p>
            </div>
            <div style="
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.5rem;
                margin-top: -5px;
              ">
              <span class="badge badge-dot d-block text-start pb-0">
                <i class="bg-gradient-primary"></i>
                <span class="text-muted text-xs font-weight-bold">Saved</span>
              </span>
              <div style="width: 80%; margin-top: -8px" class="progress-wrapper">
                <div class="progress-info">
                  <div class="progress-percentage">
                    <span class="text-sm font-weight-bold">KES
                      {{
                        parseFloat(currentUser.saved_this_week).toLocaleString()
                      }}</span>
                  </div>
                </div>
                <div class="progress" style="background-color: #fff">
                  <div class="progress-bar bg-primary" role="progressbar" :aria-valuenow="weekSavedPerc" aria-valuemin="0"
                    aria-valuemax="100" :style="'width: ' + weekSavedPerc + '%'"></div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span class="badge badge-dot d-block text-start">
                <i class="bg-gradient-light"></i>
                <span class="text-muted text-xs font-weight-bold">Target</span>
              </span>
              <div style="width: 80%; margin-top: -8px" class="progress-wrapper">
                <div class="progress-info">
                  <div class="progress-percentage">
                    <span class="text-sm font-weight-bold">KES
                      {{
                        parseFloat(currentUser.weekly_target)?.toLocaleString()
                      }}</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-light" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                    aria-valuemax="100" style="width: 100%"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 2rem" class="col-xl-12 col-sm-6 mb-xl-0 mb-4">
        <v-chart class="chart" :option="option" />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 3rem">
      <div class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8" id="nav">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="allGoals" href="#" role="tab"
              aria-controls="AllGoals" aria-selected="true" v-on:click="makeActive('allGoalsActive')">
              All Goals
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="fixedGoals" role="tab"
              aria-controls="FixedGoals" v-on:click="
                makeActive('fixedGoalsActive');
              fetchFixed();
              " aria-selected="false">
              Fixed
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="premiumGoals" role="tab"
              aria-controls="PremiumGoals" v-on:click="makeActive('premiumGoalsActive'), fetchPremium()"
              aria-selected="false">
              Premium
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="customGoals" role="tab"
              aria-controls="CustomGoals" v-on:click="makeActive('customGoalsActive'), fetchCustom()"
              aria-selected="false">
              Custom
            </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 0.5rem">
        <soft-button class="mb-2" variant="gradient" color="dark" data-bs-toggle="modal"
          data-bs-target="#modal-goal">Create Custom Goal
        </soft-button>
      </div>
    </div>
    <div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="allGoals"
        v-show="isActiveTab('allGoalsActive')">
        <all-goals :goals="goals" />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="fixedGoals"
        v-show="isActiveTab('fixedGoalsActive')">
        <fixed-goals :savingItems="savingItems" :goals="goals.filter((goal) => goal.type == 'SAVU')" />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="premiumGoals"
        v-show="isActiveTab('premiumGoalsActive')">
        <premium-goals :savingItems="savingItems" :goals="goals.filter((goal) => goal.type == 'SAVP')" />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="customGoals"
        v-show="isActiveTab('customGoalsActive')">
        <custom-goals :savingItems="savingItems" />
      </div>
    </div>
  </div>
  <!-- Create Goal Modal -->
  <div class="col-md-4">
    <savings-modal />
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import AllGoals from "./goals/AllGoals.vue";
import SavingsModal from "./components/SavingsModal.vue";
import FixedGoals from "./goals/FixedGoals.vue";
import PremiumGoals from "./goals/PremiumGoals.vue";
import CustomGoals from "./goals/CustomGoals.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/components/SoftButton.vue";
import Vue3Autocounter from "vue3-autocounter";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "savings-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: "allGoalsActive", // Borrow is chosen by default

      chartData: {},
      goals: [],
      savingItems: [],
      loadingPage: false,
      currentPage: 1,
      last_page: 0,
      weekSavedPerc: 20,
      page: 1,
      rows: 12,
      perPage: 12,
      filter: {},
      filter_type: {
        type: "",
      },
      query: "",
      option: {
        autoresize: true,
        title: {
          text: "Savings Distribution",
          left: "right",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [
            "Fixed",
            "Premium",
            "Custom",
            // "Total Savings",
            // "earned amount",
          ],
        },
        series: [
          {
            name: "Savings Distribution",
            type: "pie",
            radius: "70%",
            center: ["60%", "55%"],
            data: [
              { value: 13, name: "Fixed" },
              { value: 21, name: "Premium" },
              { value: 8, name: "Custom" },
              // { value: 10, name: "Total Savings" },
              // { value: 8, name: "Earned amount" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  components: {
    MiniStatisticsCard,
    SoftButton,
    AllGoals,
    FixedGoals,
    PremiumGoals,
    CustomGoals,
    SavingsModal,
    VChart,
    Vue3Autocounter,
  },

  created() {
    this.getGoals();
    this.getWeekSavedPerc();

    this.getSavingsChart();
    // var scripts = [
    //   "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/multistep-form.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/datatables.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/soft-ui-dashboard.min.js?v=1.1.1",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/choices.min.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/smooth-scrollbar.min.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/dragula/dragula.min.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/chartjs.min.js",
    //   "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/tilt.min.js",
    //   "/assets/js/multistep-form.js",
    // ];
    // scripts.forEach((script) => {
    //   let tag = document.createElement("script");
    //   tag.setAttribute("src", script);
    //   document.head.appendChild(tag);
    // });
  },

  methods: {
    ...mapActions([
      "fetchGoals",
      "getSavings",
      "fetchCustomGoals",
      "getSavingsChart",
    ]),
    getWeekSavedPerc() {
      this.weekSavedPerc =
        (parseFloat(this.currentUser.saved_this_week) /
          parseFloat(this.currentUser.weekly_target)) *
        100;

      this.weekSavedPerc = this.weekSavedPerc == 0 ? 0.1 : this.weekSavedPerc;
    },

    getGoals(page, rows) {
      this.fetchGoals({ page, rows, query: this.query, ...this.filter });
    },

    fetchFixed(page, rows) {
      this.filter_type.type = "U";
      this.getSavings({ page, rows, query: this.query, ...this.filter_type });
    },

    fetchPremium(page, rows) {
      this.filter_type.type = "P";
      this.getSavings({ page, rows, query: this.query, ...this.filter_type });
    },

    fetchCustom(page, rows) {
      this.filter_type.type = "SAVC";
      this.fetchCustomGoals({
        page,
        rows,
        query: this.query,
        ...this.filter_type,
      });
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },
  },

  computed: mapGetters([
    "goals_info",
    "savings",
    "loadingInfo",
    "c_goals_info",
    "currentUser",
    "savingsChart",
  ]),
  watch: {
    savingsChart() {
      this.chartData = this.savingsChart;
      let c_data = this.savingsChart;
      console.log("c_data", c_data);
      let __chartList = [];
      let __legends = [];
      for (const [key, value] of Object.entries(c_data)) {
        let __entry =
          key != "total_savings" && key != "earned_amount"
            ? {
              value: value,
              name: key,
            }
            : null;
        __chartList.push(__entry);
        __legends.push(key);
      }

      this.option.series[0].data = __chartList;
      this.option.legend.data = __legends;

      console.log(this.option.series[0].data);
    },
    savings() {
      this.savingItems = this.savings;
    },
    loadingInfo() {
      this.loadingPage = this.loadingInfo.loading;
    },
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.goals = this.goals_info.goals;
      // if(this.goals_info.queryState){
      //   this.goals = []
      //   this.goals = this.goals_info.goals
      // }
      // else{
      //   for(let x of this.goals_info.goals){
      //     this.goals.push(x);
      //     this.loadingPage = false;
      //   }
      // }
    },
    c_goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.savingItems = this.c_goals_info.c_goals;
    },
  },
  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
