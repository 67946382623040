<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Loan Requests</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Lender
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Interest
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Status
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="req in borrowerLoanReqData" :key="req.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ req.lender_profile.full_name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">{{ req.interest }}%</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold"
                  >KES {{ parseFloat(req.amount).toLocaleString() }}</span
                >
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <soft-badge
                    v-if="req.status == true"
                    color="success"
                    variant="gradient"
                    size="sm"
                    >Approved</soft-badge
                  >
                  <soft-badge
                    v-if="req.status == false"
                    color="danger"
                    variant="gradient"
                    size="sm"
                    >Rejected</soft-badge
                  >
                  <soft-badge
                    v-if="req.status == null"
                    color="secondary"
                    variant="gradient"
                    size="sm"
                    >Pending</soft-badge
                  >
                </div>
              </td>
              <td class="align-middle">
                <!-- <soft-button style="box-shadow: none; margin-top: 0.5rem;" class="mb-2" variant="gradient" color="info"
                  data-bs-toggle="modal" data-bs-target="#modal-loan-details">Details
                </soft-button> -->
                <button
                  href="javascript:;"
                  class="btn btn-outline-secondary ms-2"
                  style="border: 2px solid; margin-top: 0.7rem"
                  data-bs-toggle="modal"
                  :data-bs-target="'#modal-loan-details_' + req.id"
                >
                  Details
                </button>
              </td>

              <!-- Loan Request Details Modal -->
              <div class="col-md-4">
                <div
                  class="modal fade"
                  :id="'modal-loan-details_' + req.id"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="modal-default"
                  aria-hidden="true"
                >
                  <loan-request-table-form :req="req" />
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftBadge from "@/components/SoftBadge.vue";
import LoanRequestTableForm from "./LoanRequestTableForm.vue";

export default {
  name: "loan-request-table",
  components: {
    SoftBadge,
    LoanRequestTableForm,
  },
  props: ["borrowerLoanReqData"],
};
</script>
