<template>
    <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
        <div class="card-body p-3 pb-0 mb-0">
            <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            Treasury Bill- 364 Day
                        </h6>
                        <span class="text-xs"></span>
                    </div>
                    <div class="d-flex align-items-center text-sm"></div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Investment Class
                        </h6>
                        <span class="text-xs">Fixed Income</span>
                    </div>
                    <div class="d-flex align-items-center text-sm"></div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">Min. Amount</h6>
                        <span class="text-xs">50000.00</span>
                    </div>
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">Min. Period</h6>
                        <span class="text-xs">364 Day(s)</span>
                    </div>
                </li>
                <hr class="mt-0 horizontal dark" />
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0" style="color: #78519e !important"
                            data-bs-toggle="modal" :data-bs-target="'#recentInvestModal_' + investment?.id">
                            <i class="fa fa-bank text-lg me-1" aria-hidden="true"></i>
                            Invest Now
                        </button>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0" data-bs-toggle="modal"
                            :data-bs-target="'#recentInvestDetailsModal_' + investment?.id">
                            <i class="fa fa-info-circle text-lg me-1" aria-hidden="true"></i>
                            More Details
                        </button>
                    </div>

                    <!-- Invest Now Modal -->
                    <div class="modal fade" :id="'recentInvestModal_' + investment?.id" tabindex="-1" role="dialog"
                        :aria-labelledby="'recentInvestModalTitle_' + investment?.id" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div style="border-bottom: none" class="modal-header">
                                    <h5 class="modal-title" :id="'recentInvestModalLabel_' + investment?.id">
                                        {{ investment?.name }}
                                    </h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <label>Amount</label>
                                    <soft-input id="password" type="number" placeholder="KES" name="password" />
                                    <label>Period</label>
                                    <soft-input id="date" type="date" value="2024-02-30" name="date" />
                                </div>
                                <div style="border-top: none" class="modal-footer">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success">
                                        Invest Now
                                    </soft-button>
                                    <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                                        Close
                                    </soft-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- More Details Modal -->
                    <div class="modal fade" :id="'recentInvestDetailsModal_' + investment?.id" tabindex="-1" role="dialog"
                        aria-labelledby="recentInvestDetailsModalTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div id="app">
                                    <section class="stepper-modal" v-if="!hasSubmitted">
                                        <transition name="slide-fade">
                                            <section v-show="edit === 1">
                                                <div style="border-bottom: none" class="modal-header">
                                                    <h5 class="modal-title"
                                                        :id="'recentInvestDetailsModalLabel_' + investment?.id">
                                                        Treasury Bill- 364 Day
                                                    </h5>
                                                    <button type="button" class="btn-close text-dark"
                                                        data-bs-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Risk Level</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Conservative
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Investment Asset Class</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Fixed Income
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Promoter/ Fund Manager</span>
                        <!-- <soft-avatar class="me-3" :img="investment.fund_manager_image" alt="kal" border-radius="xl"
                            shadow="regular" /> -->
                        <h6 style="color: #03da92 !important" class="text-dark mb-1 font-weight-bold text-sm">
                            Central Bank of Kenya
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Minimum Amount.</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            KES 50000.00
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Minimum Period</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            364 Days
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">10 Year Perfomance</span>
                    </div>
                </li>
                <p>
                    <a style="text-decoration: underline"
                        class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" data-bs-toggle="collapse"
                        href="#collapseInvestTerms" aria-expanded="false" aria-controls="collapseInvestTerms">
                        Goal Description
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </a>
                </p>
                <div class="collapse" id="collapseInvestTerms">
                    <div class="card-body">
                        The Treasury Bills are issued by the Central Bank of Kenya. Minimum investment amount is Kes 50,000.
                        The investment transaction shall be executed when the minimum amount is reached in the proceeding
                        auction <a
                            href="https://www.centralbank.go.ke/securities/treasury-bills/">https://www.centralbank.go.ke/securities/treasury-bills/</a>
                    </div>
                </div>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Disclaimer</span>
                        <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-sm">
                            Disclaimer text
                        </h6>
                    </div>
                </li>
        </div>
        <div style="border-top: none" class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="success" @click="next">
                Invest Now
            </soft-button>
            <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                Close
            </soft-button>
        </div>
        </section>
        </transition>
        <transition name="slide-fade">
            <section v-show="edit === 2">
                <form style="padding: 2rem" class="form">
                    <div class="form-group">
                        <div style="width: 100%">
                            <label>Amount</label>
                            <input id="cpassword" type="number" placeholder="1000" aria-label="Target Amount"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div style="width: 100%">
                            <label>Period</label>
                            <input id="date" type="date" placeholder="" aria-label="End Date" class="form-control" />
                        </div>
                    </div>
                    <div style="margin-top: 3rem;" class="form-group cta-step">
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="dark" @click.prevent="prev()">
                            Back
                        </soft-button>
                        <soft-button style="margin-right: 0.1rem; margin-top: 0 !important" class="my-4 mb-2"
                            variant="gradient" color="success">
                            Invest Now
                        </soft-button>
                    </div>
                </form>
            </section>
        </transition>
        </section>
    </div>
    </div>
    </div>
    </div>
    </li>
    </ul>
    </div>
    </div>
</template>
  
<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
// import SoftAvatar from "@/components/SoftAvatar.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import gok from "@/assets/img/gok.png";

export default {
    name: "RecentInvestmentsCard",
    components: {
        SoftButton,
        // SoftAvatar,
        SoftInput,
    },
    el: "#app",
    data() {
        return {
            edit: 1,
            // gok,
        };
    },
    methods: {
        prev() {
            this.edit--;
        },

        next() {
            this.edit++;
        },
    },
    // props: ["investment"],
};
</script>
  