<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navtips btn-background="bg-gradient-primary" />
      </div>
    </div>
  </div>
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pb-0 card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">
              Investment Explainer
            </h3>
            <p class="mb-0">Which type of investment are you familiar with?</p>
          </div>
          <div class="card-body">
            <form @submit.prevent="addInvOnboardingData">
              <div class="explainer-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        v-model="form.choice"
                        :value="36"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio2"
                        >Real Estate</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        v-model="form.choice"
                        :value="37"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio3"
                        >Bonds</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        v-model="form.choice"
                        :value="38"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio4"
                        >MMF</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        v-model="form.choice"
                        :value="39"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio5"
                        >ETFs</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        v-model="form.choice"
                        :value="40"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio6"
                        >Equities</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        v-model="form.choice"
                        :value="41"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="customRadio6"
                        >Crypto/FX</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex">
                <soft-button
                  v-if="!loading"
                  class="my-4 mb-2"
                  type="submit"
                  variant="gradient"
                  color="success"
                  full-width
                  >Next
                </soft-button>
                <soft-button
                  v-else
                  class="my-4 mb-2"
                  disabled
                  variant="gradient"
                  color="success"
                  full-width
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </soft-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Familiar Investment",
  components: {
    Navtips,
    AppFooter,
    SoftButton,
    // NavPill,
  },
  data() {
    return {
      picked: "One",
      form: {},
      loading: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["addInvestmentAnswer"]),

    addInvOnboardingData() {
      console.log("ddd", this.form);
      if (this.form.choice == "") {
        this.loading = false;
        this.$toast.error("failed to add choice, try again!!");
        return;
      } else {
        this.loading = true;
        this.addInvestmentAnswer(this.form);
      }
    },
  },

  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      this.loading = false;
      if (this.invBoarding.success) {
        this.$router.push({ name: "Description" });
      } else {
        this.loading = false;
        this.$toast.error("something went wrong, try again!!!");
        return;
      }
    },
  },
};
</script>
