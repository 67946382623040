<template>
  <div class="card mb-4" style="box-shadow: none !important">
    <div style="display: flex" class="card-header pb-0">
      <div>
        <select class="form-control">
          <option>Transaction Type Filter</option>
          <option>Deposit</option>
          <option>Withdrawal</option>
          <option>Loan Repayment</option>
        </select>
      </div>
      <div style="margin-left: 10px"></div>
      <div>
        <select class="form-control">
          <option>Period Filter</option>
          <option>1 Month</option>
          <option>3 Months</option>
          <option>6 Months</option>
        </select>
      </div>
      <div style="margin-left: 10px"></div>
      <!-- <select class="form-control form-control-sm">
                <option>Transaction Type Filter</option>
            </select> -->
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction Type
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Reference Number
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Amount
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in transactions" :key="transaction.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ transaction.type_display }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">
                  {{ transaction.ref }}
                </p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{
                  setFullDateFormat(transaction.transaction_date)
                }}</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span
                    v-if="parseFloat(transaction.cr_amount) > 0"
                    style="color: #05d889"
                    class="me-2 text-xs font-weight-bold"
                  >
                    KES {{ transaction.cr_amount }}
                  </span>
                  <span
                    v-else-if="parseFloat(transaction.dr_amount) > 0"
                    style="color: #05d889"
                    class="me-2 text-xs font-weight-bold"
                  >
                    KES {{ transaction.dr_amount }}
                  </span>
                  <span
                    v-else
                    style="color: #05d889"
                    class="me-2 text-xs font-weight-bold"
                  >
                    KES 0.00
                  </span>
                </div>
              </td>
              <td class="align-middle"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftProgress from "@/components/SoftProgress";
// import SoftInput from "@/components/SoftInput.vue";

export default {
  name: "transactions-table",
  components: {
    // SoftInput
    // SoftProgress,
  },
  props: ["transactions"],
  data() {
    return {
      showMenu: false,
    };
  },

  methods: {
    setFullDateFormat(date) {
      let localDate = new Date(date);
      let year = localDate.getFullYear();
      let month = localDate.getMonth();
      let day = localDate.getDate();
      let hour = localDate.getHours();
      let min = localDate.getMinutes();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day + " " + hour + ":" + min;
    },
  },
};
</script>
