<template>
  <div class="py-4 container-fluid">
    <div class="mt-3 row">
      <div style="margin-top: 2rem">
        <div class="card">
          <div class="pb-0 card-header">
            <h6 class="mb-0">Create a Savings Goal</h6>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <div>
                  <label style="margin-bottom: 1rem"
                    >Select the type of goal you want to create</label
                  >
                  <div style="width: 100%; height: 80px" class="radio-card">
                    <input
                      type="radio"
                      id="radio3"
                      name="addGoalFo"
                      value="goal1"
                      @click="setGoalOption('goal1'), fetchFixed()"
                    />
                    <label
                      style="position: absolute; margin: 20px; cursor: pointer"
                      for="customaddGoalFo"
                      >Fixed Goal</label
                    >
                  </div>
                  <div
                    style="width: 100%; height: 80px; margin-top: -1rem"
                    class="radio-card"
                  >
                    <input
                      type="radio"
                      id="radio3"
                      name="addGoalFo"
                      value="goal2"
                      @click="setGoalOption('goal2'), fetchPremium()"
                    />
                    <label
                      style="position: absolute; margin: 20px; cursor: pointer"
                      for="customaddGoalFo"
                      >Premium Goal</label
                    >
                  </div>
                  <div
                    style="width: 100%; height: 80px; margin-top: -1rem"
                    class="radio-card"
                  >
                    <input
                      type="radio"
                      id="radio3"
                      name="addGoalFo"
                      value="goal3"
                      @click="setGoalOption('goal3')"
                    />
                    <label
                      style="position: absolute; margin: 20px; cursor: pointer"
                      for="customaddGoalFo"
                      >Custom Goal</label
                    >
                  </div>
                </div>
                <div style="margin-bottom: 1rem"></div>
                <div v-if="form.goalOption === 'goal1'">
                  <div class="py-4 container-fluid">
                    <div class="mt-4 row">
                      <div
                        class="col-lg-4 mb-2"
                        v-for="rgoal in fixedG"
                        :key="rgoal.id"
                      >
                        <recommended-fixed-goal-card :rgoal="rgoal" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="form.goalOption === 'goal2'">
                  <div class="py-4 container-fluid">
                    <div class="mt-4 row">
                      <div
                        class="col-lg-4 mb-2"
                        v-for="pgoal in premiumG"
                        :key="pgoal.id"
                      >
                        <premium-product-card :pgoal="pgoal" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="form.goalOption === 'goal3'">
                  <form @submit.prevent="createCustomGoal" class="form-group">
                    <label for="example-text-input" class="form-control-label"
                      >Name your goal</label
                    >
                    <input
                      class="form-control"
                      v-model="form.name"
                      type="text"
                      placeholder="Goal Name"
                      id="example-text-input"
                    />
                    <label for="example-date-input" class="form-control-label"
                      >When do you target to achieve this goal?</label
                    >
                    <input
                      class="form-control"
                      type="date"
                      v-model="form.end_date"
                      id="example-date-input"
                    />
                    <label for="example-number-input" class="form-control-label"
                      >How much are you targeting to save?</label
                    >
                    <input
                      class="form-control"
                      type="number"
                      min="1"
                      v-model="form.target_amount"
                      placeholder="KES 500"
                      id="example-number-input"
                    />
                    <label for="example-text-input" class="form-control-label"
                      >Define your goal terms</label
                    >
                    <textarea
                      class="form-control"
                      v-model="form.terms"
                      placeholder="Enter your terms"
                      id="example-number-input"
                    ></textarea>
                    <!-- <label for="example-number-input" class="form-control-label">How long do you want to
                                        lock your savings?</label>
                                    <select class="form-control">
                                        <option>Select Period</option>
                                        <option>30 days</option>
                                        <option>90 days</option>
                                        <option>180 days</option>
                                        <option>1 Year</option>
                                        <option>3 Years</option>
                                    </select> -->
                    <!-- <div style="margin-bottom: 1rem;"></div>
                                    <div>
                                        <label style="margin-bottom: 1rem;">Would you like to earn interest on this
                                            goal?</label>
                                        <div style="width:100%; height: 80px;" class="radio-card">
                                            <input type="radio" id="radio3" name="addGoalFo">
                                            <label style="position: absolute; margin: 20px; cursor: pointer;"
                                                for="customaddGoalFo">Yes (Goal redeemed
                                                in 48 hours)</label>
                                        </div>
                                        <div style="width:100%; height: 80px; margin-top: -1rem" class="radio-card">
                                            <input type="radio" id="radio3" name="addGoalFo">
                                            <label style="position: absolute; margin: 20px; cursor: pointer;"
                                                for="customaddGoalFo">No (Instant
                                                withdrawal)
                                                Goal</label>
                                        </div>
                                    </div> -->
                    <soft-button
                      type="submit"
                      style="margin-right: 1rem"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="dark"
                    >
                      Add Goal
                    </soft-button>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftInput from "@/components/SoftInput.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
import PremiumProductCard from "./PremiumProductCard.vue";
import RecommendedFixedGoalCard from "./RecommendedFixedGoalCard.vue";

export default {
  name: "add-goal-tab",
  data() {
    return {
      loadingCustomForm: false,
      form: {
        goalOption: "goal1",
      },
      client: null,
      filter_type: {},
      fixedG: [],
      premiumG: [],
    };
  },
  components: {
    // SoftInput,
    // SoftCheckbox,
    SoftButton,
    PremiumProductCard,
    RecommendedFixedGoalCard,
  },
  props: ["goals", "savingItems"],
  created() {
    this.createCustomGoal();
    this.setCustomForm();
    // this.fetchFixed();
    // this.fetchPremium();
  },
  methods: {
    ...mapActions(["createCustomSavingGoal"]),

    fetchFixed() {
      let fg = this.savingItems.filter((item) => item.type !== "U");
      this.fixedG = fg;
      console.log("fg", this.fixedG);
    },

    fetchPremium() {
      let pg = this.savingItems.filter((item) => item.type !== "P");
      this.premiumG = pg;
    },

    setGoalOption(opt) {
      this.form.goalOption = opt;
      // if (this.form.goalOption == "goal1") {
      //   this.fetchFixed(1, 5);
      // } else if (this.form.goalOption == "goal2") {
      //   this.fetchPremium(1, 5);
      // }
    },

    setCustomForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            name: "",
            target_amount: 1.0,
            end_date: "",
            type: "SAVC",
            terms: "",
            client: this.currentUser?.id,
          };
    },

    createCustomGoal() {
      this.loadingCustomForm = true;
      let amt = parseFloat(this.form.target_amount);
      this.form.target_amount = parseFloat(amt.toFixed(2));
      //   this.createCustomSavingGoal([this.form]);
    },
  },

  computed: {
    ...mapGetters[("loadingInfo", "currentUser", "savings")],
  },

  watch: {
    loadingInfo() {
      this.loadingCustomForm = false;
      this.loadingInfo.success && this.$router.go();
    },
  },
};
</script>
