import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  withdrawal_status: {},
};

const getters = {
  withdrawalStatus: (state) => state.withdrawal_status,
};

const mutations = {
  SET_WITHDRAWAL: (state, payload) => {
    state.withdrawal_status = payload;
  },
};

const actions = {
  makeWithdraw: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "client/wallet/withdrawals/create";
      const response = await axios.post(url, formData, headers);
      const data = response.data;
      commit("SET_WITHDRAWAL", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: data.message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
