<template>
  <form style="padding: 2rem" class="form" id="formPeriod">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                How long are you look to be invested for?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        form="formPeriod"
                        v-model="formPeriod.choice"
                        :value="26"
                        type="radio"
                        name="flexRadioDefault"
                        id="formPeriod2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formPeriod2"
                        >Less than 1 year</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formPeriod"
                        v-model="formPeriod.choice"
                        :value="27"
                        type="radio"
                        name="flexRadioDefault"
                        id="formPeriod3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formPeriod3"
                        >1 to 2 years</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formPeriod"
                        v-model="formPeriod.choice"
                        :value="28"
                        type="radio"
                        name="flexRadioDefault"
                        id="formPeriod4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formPeriod4"
                        >2 to 3 years</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formPeriod"
                        v-model="formPeriod.choice"
                        :value="29"
                        type="radio"
                        name="flexRadioDefault"
                        id="formPeriod5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formPeriod5"
                        >3 to 5 years</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formPeriod"
                        v-model="formPeriod.choice"
                        :value="30"
                        type="radio"
                        name="flexRadioDefault"
                        id="formPeriod6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="formPeriod6"
                        >5 years and above</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getPeriodAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formPeriod: {},
      step: 7,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getPeriodAnswer() {
      console.log("Period", this.formPeriod);
      this.addInvestmentAnswer(this.formPeriod);
    },

    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      if (this.invBoarding.success) {
        this.$emit("submitPeriod", this.formPeriod);
      } else {
        return;
      }
    },
  },
};
</script>
