<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navtips btn-background="bg-gradient-primary" />
      </div>
    </div>
  </div>
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pb-0 card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">
              Congratulations!
            </h3>
          </div>
          <div class="card-body">
            <div>
              <div class="explainer-body">
                <div class="pb-0 text-explainer">
                  <p class="mb-0" style="font-weight: 700; text-align: center">
                    You are a {{ result.name }} investor
                  </p>
                  <p
                    class="mb-0"
                    style="
                      padding-bottom: 1.6rem;
                      margin-top: 1rem;
                      text-align: center;
                    "
                  >
                    {{ result.characteristics }}.
                  </p>
                </div>
              </div>
              <div style="display: flex">
                <router-link style="width: 100%" :to="{ name: 'Investments' }">
                  <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    >Complete
                  </soft-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Congratulations",
  components: {
    Navtips,
    AppFooter,
    SoftButton,
    // NavPill,
  },
  data() {
    return {
      picked: "One",
      result: {},
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.getInvestmentsProfile();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["getInvestmentsProfile"]),
  },
  computed: {
    ...mapGetters(["investmentsProfile"]),
  },

  watch: {
    investmentsProfile() {
      this.result = this.investmentsProfile;
    },
  },
};
</script>
