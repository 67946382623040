<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navtips btn-background="bg-gradient-primary" />
            </div>
        </div>
    </div>
    <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
        <span class="mask bg-gradient-header opacity-6"></span>
        <div class="container">
            <div class="row justify-content-center">
                <!-- <div class="mx-auto text-center col-lg-5">
              <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
              <p class="text-white text-lead">
                Save, Invest, Borrow & Lend.
              </p>
            </div> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
                <div class="card z-index-0">
                    <div class="pb-0 card-header text-start">
                        <h3 class="font-weight-bolder text-success text-gradient">
                            Loan Application
                        </h3>
                        <p class="mb-0">Tax Information</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="pb-0 text-explainer">
                                <div>
                                    <label>Tax Residence</label>
                                    <select class="form-control">
                                        <option>Resident</option>
                                        <option>Non Resident</option>
                                    </select>
                                </div>
                                <div style="margin-top: 0.5rem;">
                                    <label>KRA Pin</label>
                                    <input class="form-control" ref="sortikaOnBordingID" id="text" type="text"
                                        placeholder="A0009234T" aria-label="date" />
                                </div>
                            </div>
                            <div style="display: flex">
                                <span style="width: 100%">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success"
                                        @click.prevent="addLoanBorrowData" full-width>Next
                                    </soft-button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer />
</template>
  
<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "Tax Information",
    components: {
        Navtips,
        AppFooter,
        SoftButton,
        // SoftInput,
        // NavPill,
    },
    data() {
        return {
            picked: "One",
        };
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

        addLoanBorrowData() {
            const __data = {
                idNo: this.$refs.sortikaOnBordingID.value,
            };

            localStorage.setItem(
                "sortikaOnboardingBorrowData",
                JSON.stringify(__data)
            );
            this.$router.push({ name: "Loan Amount" });
        },
    },
};
</script>
  