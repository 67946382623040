<template>
  <tr>
    <td>
      <div class="d-flex px-2">
        <div></div>
        <div class="my-auto">
          <h6 style="margin-left: 10px" class="mb-0 text-sm">
            {{ abl.client_lender_name }}
          </h6>
        </div>
      </div>
    </td>
    <td>
      <p class="text-sm font-weight-bold mb-0">{{ abl.interest }}%</p>
    </td>
    <td>
      <span class="text-xs font-weight-bold">{{ abl.date_payable }}</span>
    </td>
    <td class="align-middle text-center">
      <div class="d-flex align-items-center justify-content-center">
        <span class="me-2 text-xs font-weight-bold"
          >KES {{ parseFloat(abl.amount_payable).toLocaleString() }}</span
        >
      </div>
    </td>
    <td
      style="
        display: flex;
        justify-content: flex-end;
        margin-right: 1rem;
        margin-top: 0.6rem;
      "
      class="align-middle"
    >
      <soft-button
        style="box-shadow: none; margin-bottom: 1rem !important"
        class="mb-2"
        variant="gradient"
        color="success"
        data-bs-toggle="modal"
        :data-bs-target="'#modal-pay-now_' + abl.id"
        >Pay Now
      </soft-button>
      <button
        href="javascript:;"
        class="btn btn-outline-secondary ms-2"
        style="border: 2px solid"
        data-bs-toggle="modal"
        :data-bs-target="'#modal-active-loan-details_' + abl.id"
      >
        Details
      </button>
    </td>
  </tr>

  <!-- Pay Active Loan Modal -->
  <div class="col-md-4">
    <pay-active-loan-modal :abl="abl" />
  </div>

  <!-- Overdue Loan Details Modal -->
  <div class="col-md-4">
    <div
      class="modal fade"
      :id="'modal-active-loan-details_' + abl.id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-overdue-loan-details">
              Loan Details
            </h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <li
              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <span class="text-xs">Loan amount</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  KES
                  {{ parseFloat(abl.amount_payable).toLocaleString() }}
                </h6>
              </div>
              <div class="d-flex flex-column">
                <span class="text-xs">Interest rate</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  {{ abl.interest }}%
                </h6>
              </div>
            </li>
            <li
              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <span class="text-xs">Date payable</span>
                <h6
                  style="color: red !important"
                  class="text-dark mb-1 font-weight-bold text-lg"
                >
                  {{ abl.date_payable }}
                </h6>
              </div>
            </li>
            <label>Borrower's loan + interest cover</label>
            <div class="cover-bar">
              <div class="fifty">
                <p v-if="boaPayCoverage >= 50">
                  {{ boaPayCoverage.toFixed(2) }}%
                </p>
              </div>
              <div class="twentyfive">
                <p v-if="boaPayCoverage < 50 && boaPayCoverage >= 10">
                  {{ boaPayCoverage.toFixed(2) }}%
                </p>
              </div>
              <div class="ten">
                <p v-if="boaPayCoverage < 10 && boaPayCoverage >= 0">
                  {{ boaPayCoverage.toFixed(2) }}%
                </p>
              </div>
            </div>
            <hr class="horizontal dark" />
            <h6
              style="margin-bottom: 1rem"
              class="modal-title"
              id="modal-title-loan-details"
            >
              Guarantors
            </h6>
            <ul class="list-unstyled max-width-200">
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span
                  >
                </span>
              </li>
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span
                  >
                </span>
              </li>
            </ul>
            <hr class="horizontal dark" />
            <h6
              style="margin-bottom: 1rem"
              class="modal-title"
              id="modal-title-loan-details"
            >
              Recent Transactions
            </h6>
            <div>
              <transaction-loan-card
                style="margin-top: -1rem"
                :horizontal-break="false"
                :card="{}"
                :item="[
                  {
                    title: 'Self Loan - WID-CDRIQT',
                    date: '27 February 2022, at 12:30 PM',
                    amount: 'KES 2,500',
                    icon: 'fa-arrow-down',
                    color: 'danger',
                  },
                  {
                    title: 'Loan Self Payment - DEP-CDRIQT',
                    date: '23 March 2022, at 04:30 AM',
                    amount: 'KES 2,000',
                    icon: 'fa-arrow-up',
                    color: 'success',
                  },
                  {
                    title: 'Loan Self Payment - DEP-CDRIQT',
                    date: '26 March 2022, at 13:45 PM',
                    amount: 'KES 500',
                    icon: 'fa-arrow-up',
                    color: 'success',
                  },
                ]"
              >
              </transaction-loan-card>
            </div>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark"
              >Change</soft-button
            >
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Close</soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton";
import TransactionLoanCard from "@/examples/Cards/TransactionLoanCard.vue";
import PayActiveLoanModal from "./PayActiveLoanModal.vue";
export default {
  components: {
    SoftButton,
    TransactionLoanCard,
    PayActiveLoanModal,
  },
  props: ["abl"],
  data() {
    return {
      boaPayCoverage: 0,
    };
  },

  created() {
    this.getBActive();
  },

  methods: {
    getBActive() {
      this.boaPayCoverage =
        (parseFloat(this.abl?.remaining_amount) /
          parseFloat(this.abl?.amount_payable)) *
        100;
    },
  },
};
</script>

<style></style>
