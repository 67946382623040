<template>
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ invest?.name }}
            </h6>
            <span class="text-xs"></span>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Investment Class
            </h6>
            <span class="text-xs">{{ invest?.investment_class_name }}</span>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">Min. Amount</h6>
            <span class="text-xs">{{ invest?.minimum_amount }}</span>
          </div>
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">Min. Period</h6>
            <span class="text-xs">{{ invest?.minimum_period }} Day(s)</span>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <button
              class="btn btn-link text-dark text-sm mb-0 px-0"
              style="color: #78519e !important"
              data-bs-toggle="modal"
              :data-bs-target="'#recommendInvestNowModal_new_' + invest?.id"
            >
              <i class="fa fa-bank text-lg me-1" aria-hidden="true"></i>
              Invest Now
            </button>
          </div>
          <div class="d-flex align-items-center text-sm">
            <button
              class="btn btn-link text-dark text-sm mb-0 px-0"
              data-bs-toggle="modal"
              :data-bs-target="'#recommendInvestDetailsModal_new_' + invest?.id"
            >
              <i class="fa fa-info-circle text-lg me-1" aria-hidden="true"></i>
              More Details
            </button>
          </div>

          <!-- Invest Now Modal -->
          <div
            class="modal fade"
            :id="'recommendInvestNowModal_new_' + investment?.id"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'recommendInvestNowModalTitle_' + investment?.id"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div style="border-bottom: none" class="modal-header">
                  <h5
                    class="modal-title"
                    :id="'recommendInvestNowModalLabel_' + investment?.id"
                  >
                    {{ investment?.name }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label>Amount</label>
                  <soft-input
                    id="password"
                    type="number"
                    placeholder="KES"
                    name="password"
                  />
                  <label>Period</label>
                  <soft-input
                    id="date"
                    type="date"
                    value="2024-02-30"
                    name="date"
                  />
                </div>
                <div style="border-top: none" class="modal-footer">
                  <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                  >
                    Invest Now
                  </soft-button>
                  <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="dark"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </soft-button>
                </div>
              </div>
            </div>
          </div>

          <!-- More Details Modal -->
          <div
            class="modal fade"
            :id="'recommendInvestDetailsModal_new_' + invest?.id"
            tabindex="-1"
            role="dialog"
            aria-labelledby="recommendInvestDetailsModalTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div style="border-bottom: none" class="modal-header">
                  <h5
                    class="modal-title"
                    :id="'recommendInvestDetailsModalLabel_' + invest?.id"
                  >
                    {{ invest?.name }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Risk Level</span>
                      <h6 class="text-dark mb-1 font-weight-bold text-sm">
                        {{ invest?.risk_level.name }}
                      </h6>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Investment Asset Class</span>
                      <h6 class="text-dark mb-1 font-weight-bold text-sm">
                        {{ invest?.investment_class_name }}
                      </h6>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Promoter/ Fund Manager</span>
                      <soft-avatar
                        class="me-3"
                        :img="invest.fund_manager_image"
                        :alt="invest?.name"
                        border-radius="xl"
                        shadow="regular"
                      />
                      <h6
                        style="color: #03da92 !important"
                        class="text-dark mb-1 font-weight-bold text-sm"
                      >
                        {{ invest?.fund_manager_name }}
                      </h6>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Minimum Amount.</span>
                      <h6 class="text-dark mb-1 font-weight-bold text-sm">
                        KES {{ invest?.minimum_amount?.toLocaleString() }}
                      </h6>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Minimum Period</span>
                      <h6 class="text-dark mb-1 font-weight-bold text-sm">
                        {{ invest?.minimum_period }} Days
                      </h6>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">10 Year Perfomance</span>
                    </div>
                  </li>
                  <p>
                    <a
                      style="text-decoration: underline"
                      class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                      data-bs-toggle="collapse"
                      href="#collapseInvestTerms"
                      aria-expanded="false"
                      aria-controls="collapseInvestTerms"
                    >
                      Goal Description
                      <i
                        class="fas fa-arrow-right text-sm ms-1"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseInvestTerms">
                    <div class="card-body">
                      {{ invest?.description }}
                    </div>
                  </div>
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-xs">Disclaimer</span>
                      <h6
                        style="color: red !important"
                        class="text-dark mb-1 font-weight-bold text-sm"
                      >
                        Sortika investment solution provides investment
                        opportunities to its users to invest in various
                        investment products. However, investing in financial
                        markets comes with risks, and past performance is not a
                        guarantee of future results. Please note that Sortika
                        investment solution advocates that in addition to the
                        advise provided by the robo-advisory solution, users
                        should conduct their own research before making any
                        investment decisions. We do not guarantee the accuracy,
                        completeness, or timeliness of any information or data
                        on the app, and we are not liable for any loss or damage
                        resulting from the use of the app or the information it
                        provides. Please invest wisely and only with money that
                        you can afford to lose.
                      </h6>
                    </div>
                  </li>
                </div>
                <div style="border-top: none" class="modal-footer">
                  <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                  >
                    Invest Now
                  </soft-button>
                  <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="dark"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </soft-button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import gok from "@/assets/img/gok.png";

export default {
  name: "RecommendedInvestmentsCard",
  components: {
    SoftButton,
    SoftAvatar,
    // SoftInput,
  },
  data() {
    return {
      // gok,
    };
  },
  props: ["invest"],
};
</script>
