import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  showModal: false,
  savedGoal: false,
  transactionList: [],
  analysis: [],
  current_page: 1,
  last_page: 1,
  savings: [],
  savingItem: {},
  savingsChatItem: {},
};

const getters = {
  getGoalSavedStatus: (state) => state.savedGoal,
  TransactionsList: (state) => {
    return {
      transList: state.transactionList,
      analysis: state.analysis,
      curent_page: state.current_page,
      last_page: state.last_page,
    };
  },
  savings: (state) => state.savings,
  savingDetails: (state) => state.savingItem,
  savingsChart: (state) => state.savingsChatItem,
};

const mutations = {
  SET_SAVING_GOAL: (state, payload) => {
    state.savedGoal = payload.success;
    state.savings = [...state.savings, payload.data];
  },
  SET_TRANSACTIONS: (state, payload) => {
    state.transactionList = payload.data;
    state.analysis = payload.analysis;
    state.current_page = payload.current_page;
    state.last_page = payload.last_page;
  },
  SET_SAVINGS: (state, payload) => {
    state.savings = payload.data;
  },
  SET_SAVING_DETAIL: (state, payload) => {
    state.savingItem = payload;
  },
  SET_SAVINGS_CHART: (state, payload) => {
    state.savingsChatItem = payload;
  },
};

const actions = {
  createCustomSavingGoal: async ({ commit, rootState }, formData) => {
    console.log("fomr", formData);
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      const url = URL + "client/goals/multiple/create";
      let response = await axios.post(url, formData, headers);
      const { message, data, success } = response.data;
      commit("SET_SAVING_GOAL", { data, success });

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (errors) {
      const message = errors.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getSavings: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      let headers = tokenHeaders({ token });
      let url = URL + "client/goals/savings?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      let response = await axios.get(url, headers);
      const { data, message, last_page } = response.data;
      commit("SET_SAVINGS", {
        data,
        last_page,
        current_page: params.page ? params.page : 1,
      });

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      let message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getSavingsChart: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      let headers = tokenHeaders({ token });
      let url = URL + "client/goals/savings/chart";

      let response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_SAVINGS_CHART", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      let message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getSavingDetails: async ({ commit, rootState }, id) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      let headers = tokenHeaders({ token });
      let url = URL + `admin/savings/${id}`;
      let response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_SAVING_DETAIL", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      let message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      let headers = tokenHeaders({ token });
      let url = URL + "client/transactions/view?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      let response = await axios.get(url, headers);
      const { data, analysis, message, last_page } = response.data;
      commit("SET_TRANSACTIONS", {
        data,
        analysis,
        last_page,
        current_page: params.page ? params.page : 1,
      });

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      let message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
