import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SortikaUIDashboard from "./sortika-ui-dashboard";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import mixpanel from "mixpanel-browser";
import ECharts from "vue-echarts";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import Toaster from "@meforma/vue-toaster";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
// @ts-ignore
import VueStar from "vue-star";
// @ts-ignore
// import * as VueGoogleMaps from "vue2-google-maps";
import Vue3Autocounter from "vue3-autocounter";

const options = {
  confirmButtonColor: "#14F9AC",
  cancelButtonColor: "red",
};

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Toaster);
appInstance.use("vue3-autocounter", Vue3Autocounter);
appInstance.use("VueStar", VueStar);
appInstance.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA_Gzs5l038qJy8Z722G6UQGxalcSJDkMU",
    libraries: "places",
    v: 3.38,
  },
});
appInstance.use(VueSweetalert2, options);
appInstance.use(SortikaUIDashboard);
appInstance.component("v-chart", ECharts);
appInstance.use(PerfectScrollbar, {
  watchOptions: true,
  options: {
    suppressScrollY: true,
  },
});
appInstance.use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);
appInstance.mount("#app");
mixpanel.init("4479c229dd22a0889680f57f393b3e3c", { debug: true });
mixpanel.track("Sign Up", {
  source: "Sortika Client Web App",
  gender: "female",
});
mixpanel.track_links("#sign-in div", "click div link", {
  referrer: document.referrer,
});
mixpanel.register({
  gender: "female",
});
