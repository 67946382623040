import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  goals: [],
  goals_page: 1,
  goals_last_page: 1,
  queryState: true,

  c_goals: [],
  c_goals_page: 1,
  c_goals_last_page: 1,

  goalItem: {},
  redeemStatus: false,
  redeemMessage: "",

  isUpdated: false,
  isDeleted: false,
  isDisabled: false,
  isTransfered: false,
};

const getters = {
  goals_info: (state) => ({
    goals: state.goals,
    current_page: state.goals_page,
    last_page: state.goals_last_page,
    queryState: state.queryState,
  }),
  c_goals_info: (state) => ({
    c_goals: state.c_goals,
    c_current_page: state.c_goals_page,
    c_last_page: state.c_goals_last_page,
  }),
  goalDetails: (state) => state.goalItem,
  isUpdated: (state) => state.isUpdated,
  redeemStatus: (state) => state.redeemStatus,
  isDeleted: (state) => state.isDeleted,
  isDisabled: (state) => state.isDisabled,
  isTransfered: (state) => state.isTransfered,
  redeemMessage: (state) => state.redeemMessage,
};

const mutations = {
  SET_GOALS: (state, newValue) => {
    state.goals = newValue.data;
    state.goals_page = newValue.current_page;
    state.goals_last_page = newValue.last_page;
    state.goals_total_count = newValue.goals_count;
    state.queryState = newValue.queryState;
  },
  SET_CUSTOM_GOALS: (state, newValue) => {
    state.c_goals = newValue.data;
    state.c_goals_page = newValue.current_page;
    state.c_goals_last_page = newValue.last_page;
    state.c_goals_total_count = newValue.goals_count;
  },
  SET_GOAL_DETAILS: (state, payload) => {
    state.goalItem = payload;
  },
  SET_GOAL_REDEEM: (state, payload) => {
    state.redeemStatus = payload.status;
    state.redeemMessage = payload.message;
  },
  SET_GOAL_UPDATED: (state, payload) => {
    state.isUpdated = true;
    state.goalItem = payload;
  },
  SET_GOAL_DELETE: (state, payload) => {
    state.isDeleted = payload;
  },
  SET_GOAL_DISABLE: (state, payload) => {
    state.isDisabled = payload;
  },
  SET_GOAL_TRANSFER: (state, payload) => {
    state.isTransfered = payload;
  },
};

const actions = {
  fetchGoals: async ({ state, commit, rootState }, params) => {
    // commit(
    //   "SET_LOADING",
    //   { loading: true, message: "", error: false },
    //   { root: true }
    // );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = URL + "client/goals?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message, last_page } = response.data;

      if (params.query != "") {
        state.queryState = true;
      }
      const queryState = state.queryState;

      commit("SET_GOALS", {
        data,
        last_page,
        queryState,
        current_page: params.page ? params.page : 1,
      });
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchCustomGoals: async ({ commit, rootState }, params) => {
    // commit(
    //   "SET_LOADING",
    //   { loading: true, message: "", error: false },
    //   { root: true }
    // );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = URL + "client/goals?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message, last_page } = response.data;

      commit("SET_CUSTOM_GOALS", {
        data,
        last_page,
        current_page: params.page ? params.page : 1,
      });
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getGoalDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = URL + "client/goals/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;

      commit("SET_GOAL_DETAILS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  editGoal: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/goals/update";

      const response = await axios.post(url, formData, headers);
      const { data, message } = response.data;

      commit("SET_GOAL_UPDATED", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  makeTransferGoal: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/goals/transfers/create";

      const response = await axios.post(url, formData, headers);
      const { message } = response.data;

      commit("SET_GOAL_TRANSFER", true);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  deleteGoal: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/goals/delete";

      const response = await axios.post(url, formData, headers);
      const { message } = response.data;

      commit("SET_GOAL_DELETE", true);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  disableGoal: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/goals/update_status";

      const response = await axios.post(url, formData, headers);
      const { message } = response.data;

      commit("SET_GOAL_DISABLE", true);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  makeGoalRedemption: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      // let url = URL + "client/goals/redeem";
      let url = URL + "client/goals/withdrawal/create";
      const response = await axios.post(url, formData, headers);
      const { message } = response.data;

      commit("SET_GOAL_REDEEM", { status: true, message: message });
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit("SET_GOAL_REDEEM", { status: false, message: message });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
