<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Account balance
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="
                  parseInt(
                    Number(
                      profileData.saved_amount != 'NaN'
                        ? profileData.saved_amount
                        : 0
                    )
                  )
                "
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Daily Target
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="
                  parseInt(
                    Number(
                      profileData.daily_target != 'NaN'
                        ? profileData.daily_target
                        : 0
                    )
                  )
                "
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Savings rate
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="profileData?.savings_rate"
                :duration="3"
                suffix="%"
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Borrowing limit
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="
                  parseInt(Number(loanProfile.borrowing_limit_amount))
                "
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div
      style="margin-top: 10px"
      class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8"
      id="nav"
    >
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1 active active"
            data-bs-toggle="tab"
            id="investment"
            href="#"
            role="tab"
            aria-controls="Investment"
            aria-selected="true"
            v-on:click="makeActive('investmentActive')"
          >
            Investments
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="allGoals"
            role="tab"
            aria-controls="AllGoals"
            v-on:click="makeActive('allGoalsActive')"
            aria-selected="false"
          >
            My Goals
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="addGoal"
            role="tab"
            aria-controls="AddGoal"
            v-on:click="makeActive('addGoalActive')"
            aria-selected="false"
          >
            Add Goal
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div
        style="margin-left: -1.2rem !important; margin-right: -1.2rem"
        id="investment"
        v-show="isActiveTab('investmentActive')"
      >
        <investment-tab />
      </div>
      <div
        style="margin-left: -1.2rem !important; margin-right: -1.2rem"
        id="allGoals"
        v-show="isActiveTab('allGoalsActive')"
      >
        <all-goals-tab :goals="goals" />
      </div>
      <div
        style="margin-left: -1.2rem !important; margin-right: -1.2rem"
        id="addGoal"
        v-show="isActiveTab('addGoalActive')"
      >
        <add-goal-tab :savingItems="savingItems" />
      </div>
    </div>
  </div>
  <!-- Investment Assistance
  <div class="col-md-4">
    <Assistance />
  </div> -->
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pill-dash.js";
import InvestmentTab from "./components/InvestmentTab.vue";
import AllGoalsTab from "./components/AllGoalsTab.vue";
import AddGoalTab from "./components/AddGoalTab.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
import Vue3Autocounter from "vue3-autocounter";

export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: "investmentActive", // Borrow is chosen by default

      profileData: {},
      loanProfile: {},
      goals: [],
      currentPage: 1,
      last_page: 0,
      perPage: 12,
      filter: {},
      query: "",
      savingItems: [],
    };
  },
  components: {
    Vue3Autocounter,
    InvestmentTab,
    AllGoalsTab,
    AddGoalTab,
  },

  created() {
    this.getProfile();
    this.getLoanProfile();
    this.getGoals();
    this.fetchSavings(1, 20);
  },

  methods: {
    ...mapActions(["getProfile", "getLoanProfile", "fetchGoals", "getSavings"]),

    getGoals(page, rows) {
      this.fetchGoals({ page, rows, query: this.query, ...this.filter });
    },
    fetchSavings(page, rows) {
      this.getSavings({ page, rows, query: this.query, ...this.filter_type });
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      // if (val == "allGoalsActive") {
      //   getGoals(1, 12);
      // }
      return this.choice === val;
    },
  },
  mounted() {
    setTooltip();
    setNavPills();
  },

  computed: mapGetters([
    "profile",
    "loan_profile",
    "goals_info",
    "loadingInfo",
    "savings",
  ]),

  watch: {
    profile() {
      this.profileData = this.profile;
    },
    loan_profile() {
      this.loanProfile = this.loan_profile;
    },
    savings() {
      this.savingItems = this.savings;
    },
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.goals = this.goals_info.goals;
      // if(this.goals_info.queryState){
      //   this.goals = []
      //   this.goals = this.goals_info.goals
      // }
      // else{
      //   for(let x of this.goals_info.goals){
      //     this.goals.push(x);
      //     this.loadingPage = false;
      //   }
      // }
    },
  },
};
</script>
