<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">
                    Enter your phone number and pin to continue
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="login">
                    <label>Phone Number</label>
                    <div style="display: flex">
                      <!-- <soft-input id="code" type="number" placeholder="+254" name="code" style="width: 25%" /> -->
                      <div style="width: 35%">
                        <select id="code" class="form-control form-control-sm" v-model="form.code">
                          <option>Country Code</option>
                          <option v-for="country in countriesItem" :key="country.id" :value="country.code">
                            {{ country.code }} ({{ country.name }})
                          </option>
                        </select>
                      </div>
                      <div style="margin-left: 10px"></div>
                      <div style="width: 65%">
                        <input class="form-control" id="phone" type="text" placeholder="722001122" v-model="form.username"
                          error />
                      </div>
                    </div>

                    <label>Pin (4 Digit)</label>
                    <input class="form-control form-control-alternative" id="password" type="password" placeholder="****"
                      v-model="form.pin" is-valid error />
                    <div style="display: flow-root; margin-top: 1rem">
                      <soft-switch id="rememberMe" name="rememberMe" checked>
                        Remember me
                      </soft-switch>
                      <p class="mb-4 text-sm" style="margin-bottom: 0rem !important">
                        <router-link :to="{ name: 'Forgot Pin' }"
                          class="text-success text-gradient font-weight-bold">Forgot Pin?</router-link>
                      </p>
                    </div>
                    <div class="text-center" id="sign-in">
                      <soft-button v-if="!loadingForm" class="my-4 mb-2" variant="gradient" color="success" type="submit"
                        full-width>Sign in
                      </soft-button>

                      <soft-button v-else class="my-4 mb-2" variant="gradient" color="success" disabled full-width>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                      </soft-button>
                      <!-- <button class="btn btn-primary btn-sm ml-2 mb-2" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                      </button> -->
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link :to="{ name: 'Sign Up' }" class="text-success text-gradient font-weight-bold">Sign
                      up</router-link>
                  </p>
                </div>
                <div class="qrcode-body">
                  <div class="pb-0 text-qr">
                    <h3 style="font-size: 1.3rem" class="font-weight-bolder text-success text-gradient">
                      Scan QR Code
                    </h3>
                    <img style="border-radius: 12px" src="../assets/img/qr-code.png" class="navbar-brand-img h-100"
                      alt="qr_code" />
                    <p class="mb-0" style="padding: 5px">Or</p>
                    <soft-button class="mb-2" variant="gradient" color="success" full-width>Download App
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img/curved-images/curved6.png') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "SignIn",
  components: {
    Navbar,
    AppFooter,
    SoftSwitch,
    SoftButton,
  },
  data() {
    return {
      form: {
        code: "+254",
        username: "",
        pin: "",
      },
      loadingForm: false,
      errorForm: null,
      countriesItem: [],
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.fetchCountries();
    this.setForm();
    var scripts = [
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/dropzone.min.js",
      "https://demos.creative-tim.com/argon-design-system-pro/assets/demo/vendor/holder.min.js",
      "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/soft-ui-dashboard.min.js?v=1.0.0",
      "https://demos.creative-tim.com/argon-design-system-pro/assets/demo/docs.min.js",
      "https://demos.creative-tim.com/argon-dashboard-pro-bs4/assets/vendor/jquery/dist/jquery.min.js",
      "https://demos.creative-tim.com/soft-ui-design-system-pro/assets/js/core/popper.min.js",
      "https://demos.creative-tim.com/soft-ui-design-system-pro/assets/js/core/bootstrap.min.js",
      "https://demos.creative-tim.com/argon-dashboard-pro-bs4/assets/vendor/prismjs/prism.js",
      "/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["fetchCountries", "loginCustomer"]),

    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
          code: "+254",
          username: "",
          pin: "",
        };
    },

    login() {
      let oldPhone = this.form.username;
      let phone = oldPhone;
      let code = this.form.code;
      // let code = "254";

      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        this.form.username = code + phone;
      } else {
        this.form.username = code + oldPhone;
      }
      this.loadingForm = true;
      this.loginCustomer({ username: this.form.username, pin: this.form.pin });
      this.form.username = oldPhone;
    },
  },

  computed: mapGetters(["countries", "loading_info", "auth_info", "success"]),

  watch: {
    countries() {
      this.countriesItem = this.countries;
    },
    "auth_info.token"() {
      const token = this.auth_info.token;
      if (token) {
        this.$router.push({
          name: "Dashboard",
        });
      }
    },
    "auth_info.loading"() {
      this.loadingForm = this.auth_info.loading;
    },
    "auth_info.message"() {
      const message = this.auth_info?.message;
      const error = this.auth_info.error;

      if (error) this.$toast.success(message);
      else this.$toast.warning(message);
    },

    success() {
      this.loadingForm = false;
      const message = this.auth_info?.message;
      console.log("hre", message, this.success);

      if (this.success) {
        this.$toast.success(message);
      } else {
        this.$toast.warning(message);
      }
    },
  },
};
</script>
