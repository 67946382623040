import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  loans: [],
  loan_profile: {},
  listed_lenders: [],
  borrowLoans: [],
  lenderLoans: [],
  loanLenders: [],
  borrowerLoanReq: [],
  lenderLoanReq: [],
  loanDetails: {},
  guaratorsList: [],
  payLoanSuccess: false,
  lenderReqReturn: {},
};

const getters = {
  loans: (state) => state.loans,
  loan_profile: (state) => state.loan_profile,
  borrowLoans: (state) => state.borrowLoans,
  lenderLoans: (state) => state.lenderLoans,
  loanLenders: (state) => state.loanLenders,
  borrowerLoanReq: (state) => state.borrowerLoanReq,
  lenderLoanReq: (state) => state.lenderLoanReq,
  guaratorsList: (state) => state.guaratorsList,
  payLoanSuccess: (state) => state.payLoanSuccess,
  lenderReqReturn: (state) => state.lenderReqReturn,
  listed_lenders: (state) => state.listed_lenders,
};

const mutations = {
  SET_LOANS: (state, payload) => {
    state.loans = payload;
  },
  SET_LOAN_PROFILE: (state, payload) => {
    state.loan_profile = payload;
  },
  SET_LISTED_LENDERS: (state, payload) => {
    state.listed_lenders = payload;
  },
  SET_BORROWER_LOAN: (state, payload) => {
    state.borrowLoans = payload;
  },
  SET_LENDER_LOAN: (state, payload) => {
    state.lenderLoans = payload;
  },
  SET_LOAN_LENDERS: (state, payload) => {
    state.loanLenders = payload;
  },
  SET_BORROWER_LOAN_REQUEST: (state, payload) => {
    state.borrowerLoanReq = payload;
  },
  SET_LENDER_LOAN_REQUEST: (state, payload) => {
    state.lenderLoanReq = payload;
  },
  SET_PAY_LOAN: (state, payload) => {
    state.loans = payload;
  },
  SET_PAY_LOAN_SUCCESS: (state, payload) => {
    state.payLoanSuccess = payload;
  },
  SET_LOAN_DETAILS: (state, payload) => {
    state.loanDetails = payload;
  },
  SET_BORROW: (state, payload) => {
    state.borrowerLoanReq.push(payload);
  },
  SET_GUARATORS: (state, payload) => {
    state.guaratorsList = payload;
  },
  SET_lENDER_LOAN_REQ_ACTION: (state, payload) => {
    state.lenderReqReturn = payload;
  },
  SET_LOAN_REQUEST_UPDATE: (state, payload) => {
    state.lenderLoanReq.map((req) => {
      if (req.id == payload.id) {
        state.lenderLoanReq.filter((req) => req.id == payload.id);
        state.lenderLoanReq = state.lenderLoanReq.push(payload);
      }
    });
    state.borrowerLoanReq.map((req) => {
      if (req.id == payload.id) {
        state.borrowerLoanReq.filter((req) => req.id == payload.id);
        state.borrowerLoanReq = state.borrowerLoanReq.push(payload);
      }
    });
  },
};

const actions = {
  getLoanProfile: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "client/loans/profile";
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LOAN_PROFILE", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getListedLenders: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/listed";

      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LISTED_LENDERS", data);
      console.log("SET_LISTED_LENDERS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getLoans: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LOANS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit("SET_DEPOSIT", "Failed to deposit!!!");
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getLoanDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LOAN_DETAILS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getBorrowerLoan: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/borrower/loans?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_BORROWER_LOAN", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getLenderLoan: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/lender/loans?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LENDER_LOAN", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getLenders: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/lenders?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LOAN_LENDERS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getLenderLoanReq: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/requests/individual?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_LENDER_LOAN_REQUEST", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getBorrowerLoanReq: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/requests/borrower?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_BORROWER_LOAN_REQUEST", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getGuarators: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/guarantees?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_GUARATORS", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  payLoan: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/payments/create";

      const response = await axios.post(url, formData, headers);
      const { data, message } = response.data;
      commit("SET_PAY_LOAN", data);
      commit("SET_PAY_LOAN_SUCCESS", true);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  sendLoanReminder: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = URL + "client/loans/reminders/create";

      const response = await axios.post(url, formData, headers);
      const { message } = response.data;
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateLendingState: async ({ commit, rootState }, formData) => {
    console.log("object", formData);
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      // let url = URL + "client/loans/lender/lending-status";
      let url = URL + "client/loans/lender/status";

      const response = await axios.post(url, formData, headers);
      const { message, data } = response.data;
      commit("SET_LOAN_PROFILE", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  borrowLoan: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let __from = formData.from;
      let url = "";

      if (__from == "savings") {
        url = URL + "client/loans/create/self";
      } else if (__from == "specific") {
        url = URL + "client/loans/create/specified";
      } else if (__from == "broadcast") {
        url = URL + "client/loans/create/broadcast";
      } else if (__from == "listed") {
        url = URL + "client/loans/create";
      }

      const response = await axios.post(url, formData, headers);
      const { message, data } = response.data;
      commit("SET_BORROW", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  lenderLoanReqAction: async ({ commit, rootState }, formData) => {
    console.log("lenderLoanReqAction", formData);
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = "client/loans/lender/status";

      const response = await axios.post(url, formData, headers);
      const { message, data } = response.data;
      console.log("lenderLoanReqAction data", response.data);
      commit("SET_lENDER_LOAN_REQ_ACTION", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateLoanRequest: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });

      let url = "client/loans/requests/update";

      const response = await axios.post(url, formData, headers);
      const { message, data } = response.data;
      console.log("updateLoanRequest data", response.data);
      commit("SET_LOAN_REQUEST_UPDATE", data);
      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
