<template>
  <div
    style="
      margin-top: 1rem;
      margin-bottom: -3rem !important;
      margin-left: 1.5rem !important;
    "
  >
    <div
      style="
        margin-top: 1rem;
        margin-bottom: -3rem !important;
        margin-left: 1.5rem !important;
      "
    >
      <h6>Active Premium Goals</h6>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-4 row" v-if="goals.length > 0">
        <div class="col-lg-4 mb-2" v-for="goal in goals" :key="goal.id">
          <premium-goals-card :goal="goal" :goalItems="goals" />
        </div>
      </div>

      <div v-else class="mt-4 row">
        <div class="col-lg-4 mb-2">
          <i
            style="
              color: gray;
              font-size: 2rem !important;
              padding-bottom: 3rem !important;
            "
            class="fa fa-chain-broken text-sm ms-1"
            aria-hidden="true"
          ></i
          >Nothing Here!!
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 2rem;
        margin-bottom: -3rem !important;
        margin-left: 1.5rem !important;
        display: flex;
        justify-content: space-between;
      "
    >
      <h6>Recommendations</h6>
      <div
        class="pe-md-3 d-flex align-items-center"
        :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
      >
        <div class="input-group">
          <span
            style="border: none; background-color: #d2d6da57"
            class="input-group-text text-body"
            ><i class="fas fa-search" aria-hidden="true"></i
          ></span>
          <input
            type="text"
            class="form-control"
            placeholder="Search Premium Goals"
          />
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-lg-4 mb-2" v-for="pgoal in savingItems" :key="pgoal.id">
          <premium-product-card :pgoal="pgoal" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import PremiumGoalsCard from "../components/PremiumGoalsCard.vue";
import PremiumProductCard from "../components/PremiumProductCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "fixed-goals-tab",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  components: {
    PremiumGoalsCard,
    PremiumProductCard,
  },
  props: ["savingItems", "goals"],
  mounted() {
    setTooltip();
  },
};
</script>
