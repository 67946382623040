<template>
  <form style="padding: 2rem" class="form" id="formInvestorPeriod">
    <div class="form-group">
      <div style="width: 100%">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="pb-0 card-header text-start">
              <p style="text-align: center" class="mb-0">
                For how long have you been an investor?
              </p>
              <div class="onboarding-investment-body">
                <div class="pb-0 text-explainer">
                  <div>
                    <div style="width: 100%; height: 80px" class="radio-card">
                      <input
                        form="formInvestorPeriod"
                        v-model="formInvestorPeriod.choice"
                        :value="31"
                        type="radio"
                        name="flexRadioDefault"
                        id="ip2"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="ip2"
                        >Less than 6 months</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formInvestorPeriod"
                        v-model="formInvestorPeriod.choice"
                        :value="32"
                        type="radio"
                        name="flexRadioDefault"
                        id="ip3"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="ip3"
                        >6 months to 1 year</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formInvestorPeriod"
                        v-model="formInvestorPeriod.choice"
                        :value="33"
                        type="radio"
                        name="flexRadioDefault"
                        id="ip4"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="ip4"
                        >1 to 3 years</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formInvestorPeriod"
                        v-model="formInvestorPeriod.choice"
                        :value="34"
                        type="radio"
                        name="flexRadioDefault"
                        id="ip5"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="ip5"
                        >3 to 5 years</label
                      >
                    </div>
                    <div
                      style="width: 100%; height: 80px; margin-top: -1rem"
                      class="radio-card"
                    >
                      <input
                        form="formInvestorPeriod"
                        v-model="formInvestorPeriod.choice"
                        :value="35"
                        type="radio"
                        name="flexRadioDefault"
                        id="ip6"
                      />
                      <label
                        style="
                          position: absolute;
                          margin: 20px;
                          cursor: pointer;
                        "
                        for="ip6"
                        >5 years and above</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 3rem" class="form-group cta-step">
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="dark"
        @click.prevent="previous()"
      >
        Back
      </soft-button>
      <soft-button
        style="margin-right: 0.1rem; margin-top: 0 !important"
        class="my-4 mb-2"
        variant="gradient"
        color="success"
        type="submit"
        @click.prevent="getInvestorPeriodAnswer"
      >
        Next
      </soft-button>
    </div>
  </form>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "investment-onboarding-in-app-explainer",
  el: "#app",
  components: {
    SoftButton,
  },
  data() {
    return {
      formInvestorPeriod: {},
      step: 8,
    };
  },
  methods: {
    ...mapActions(["addInvestmentAnswer"]),

    getInvestorPeriodAnswer() {
      console.log("Investor Period", this.formInvestorPeriod);
      this.addInvestmentAnswer(this.formInvestorPeriod);
    },
    previous() {
      this.$emit("prev", this.step);
    },
  },
  computed: {
    ...mapGetters(["invBoarding"]),
  },

  watch: {
    "invBoarding.data"() {
      if (this.invBoarding.success) {
        this.$emit("submitInvestorPeriod", this.formInvestorPeriod);
      } else {
        return;
      }
    },
  },
};
</script>
