<template>
  <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
    <div class="modal-content">
      <div style="border-bottom: none" class="modal-header">
        <h5 class="modal-title" id="modal-title-loan-details">Loan Details</h5>
        <button
          type="button"
          class="btn-close text-dark"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">Loan amount</span>
            <h6 class="text-dark mb-1 font-weight-bold text-lg">
              KES {{ parseFloat(req.amount).toLocaleString() }}
            </h6>
          </div>
          <div class="d-flex flex-column">
            <span class="text-xs">Interest rate</span>
            <h6 class="text-dark mb-1 font-weight-bold text-lg">
              {{ req.interest }}%
            </h6>
          </div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-xs">Date payable</span>
            <h6
              style="color: red !important"
              class="text-dark mb-1 font-weight-bold text-lg"
            >
              {{ req.date_payable }}
            </h6>
          </div>
        </li>
        <!-- <label>Borrower's loan + interest cover</label>
                        <div class="cover-bar">
                          <div class="fifty">
                            <p>0.50%</p>
                          </div>
                          <div class="twentyfive">
                            <p></p>
                          </div>
                          <div class="ten">
                            <p></p>
                          </div>
                        </div> -->
        <hr class="horizontal dark" />
        <h6
          style="margin-bottom: 1rem"
          class="modal-title"
          id="modal-title-loan-details"
        >
          Change terms
        </h6>
        <div class="mb-3">
          <label>Change amount</label>
          <input
            id="amount"
            type="number"
            ref="amount"
            :value="req.amount"
            aria-label="Change amount"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label>Interest rate (%)</label>
          <input
            id="interest"
            ref="interest"
            type="number"
            :value="req.interest"
            aria-label="Interest rate"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label>End date</label>
          <input
            id="end_date"
            ref="date_payable"
            type="date"
            :value="req.date_payable"
            aria-label="End date"
            class="form-control"
          />
        </div>
        <hr class="horizontal dark" />
        <!-- <h6
          style="margin-bottom: 1rem"
          class="modal-title"
          id="modal-title-loan-details"
        >
          Guarantors
        </h6>
        <ul class="list-unstyled max-width-200">
          <li>
            <span class="text-sm">
              John Doe
              <span class="text-dark ms-sm-2 font-weight-bold">
                +25472200000</span
              >
            </span>
          </li>
          <li>
            <span class="text-sm">
              John Doe
              <span class="text-dark ms-sm-2 font-weight-bold">
                +25472200000</span
              >
            </span>
          </li>
        </ul> -->
      </div>
      <div style="border-top: none" class="modal-footer">
        <soft-button class="mb-2" type="submit" variant="gradient" color="dark"
          >Change</soft-button
        >
        <soft-button
          type="button"
          class="mb-2"
          color="warning"
          data-bs-dismiss="modal"
          >Close</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SoftButton from "@/components/SoftButton";

export default {
  name: "loan-request-table-form",
  components: {
    SoftButton,
  },
  props: ["req"],

  methods: {
    ...mapActions(["updateLoanRequest"]),

    handleUpdateLoanRequest() {
      let id = this.req.id;
      let amount = this.$refs["amount"];
      let interest = this.$refs["interest"];
      let date_payable = this.$refs["date_payable"];
      this.updateLoanRequest({ id, date_payable, interest, amount });
    },
  },

  computed: {
    ...mapGetters(["borrowerLoanReq", "lenderLoanReq"]),
  },

  watch: {
    lenderLoanReq() {
      // this.$toast.success("Loan Request Updated successfully");
      // this.$router.go();
    },
    borrowerLoanReq() {
      // this.$toast.success("Loan Request Updated successfully");
      // this.$router.go();
    },
  },
};
</script>
