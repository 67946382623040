<template>

    <aside style="max-width: 21rem !important;"
        class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl hidden-on-mobile hidden-on-tablet"
        :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'" id="sidenav-main">
        <!-- <div class="sidenav-header">
            <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
                aria-hidden="true" id="iconSidenav"></i>
            <a class="m-0 navbar-brand" href="/">
                <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
            </a>
        </div>
        <hr class="mt-0 horizontal dark" /> -->
        <div class="hidden-on-mobile"><profilenav-list :cardBg="customClass" /></div>

    </aside>
</template>
<script>
import ProfilenavList from "./ProfilenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import { mapState } from "vuex";

export default {
    name: "index",
    components: {
        ProfilenavList,
    },
    data() {
        return {
            logo,
        };
    },
    props: {
        customClass: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapState(["isRTL"]),
    },
};
</script>
