<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Overdue Loans</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0" v-if="overdueBorrowed.length > 0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Lender
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Interest
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Date payable
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                Amount Payable
              </th>
              <th></th>
            </tr>
          </thead>
          <!-- <tbody v-if="overdueBorrowed.length > 0">
            <tr v-for="abl in overdueBorrowed" :key="abl.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ abl.client_lender_name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">{{ abl.interest }}%</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{
                  abl.date_payable
                }}</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">KES
                    {{ parseFloat(abl.amount_payable).toLocaleString() }}</span>
                </div>
              </td>
              <td style="
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 1rem;
                        margin-top: 0.6rem;
                      " class="align-middle">
                <soft-button style="box-shadow: none; margin-bottom: 1rem !important" class="mb-2" variant="gradient"
                  color="success" data-bs-toggle="modal" data-bs-target="#modal-pay-overdue">Pay Now
                </soft-button>
                <button href="javascript:;" class="btn btn-outline-secondary ms-2" style="border: 2px solid"
                  data-bs-toggle="modal" :data-bs-target="'#modal-overdue-loan-details_' + abl.id"
                  @click.prevent="getBOverdueID(abl.id, abl)">
                  Details
                </button>
              </td>
            </tr>
          </tbody> -->
          <tbody v-for="abl in overdueBorrowed" :key="abl.id">
            <OverdueLoansTableRowVue :abl="abl" />
          </tbody>
          <!-- <tbody v-else>
            <tr>
              <th colspan="5" class="text-center">No Overdue Loans!</th>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </div>
  <!-- Pay Overdue Loan Modal -->
  <!-- <div class="col-md-4">
    <pay-overdue-loan-modal />
  </div> -->

  <!-- Overdue Loan Details Modal -->
  <div class="col-md-4">
    <div class="modal fade" :id="'modal-overdue-loan-details_' + bovDetails_id" tabindex="-1" role="dialog"
      aria-labelledby="modal-default" aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-overdue-loan-details">
              Loan Details
            </h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <span class="text-xs">Loan amount</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  KES
                  {{ parseFloat(bovDetail.amount_payable).toLocaleString() }}
                </h6>
              </div>
              <div class="d-flex flex-column">
                <span class="text-xs">Interest rate</span>
                <h6 class="text-dark mb-1 font-weight-bold text-lg">
                  {{ bovDetail.interest }}%
                </h6>
              </div>
            </li>
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <span class="text-xs">Date payable</span>
                <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-lg">
                  {{ bovDetail.date_payable }}
                </h6>
              </div>
            </li>
            <label>Borrower's loan + interest cover</label>
            <div class="cover-bar">
              <div class="fifty">
                <p v-if="bovPayCoverage >= 50">
                  {{ bovPayCoverage.toFixed(2) }}%
                </p>
              </div>
              <div class="twentyfive">
                <p v-if="bovPayCoverage < 50 && bovPayCoverage >= 10">
                  {{ bovPayCoverage.toFixed(2) }}%
                </p>
              </div>
              <div class="ten">
                <p v-if="bovPayCoverage < 10 && bovPayCoverage >= 0">
                  {{ bovPayCoverage.toFixed(2) }}%
                </p>
              </div>
            </div>
            <hr class="horizontal dark" />
            <h6 style="margin-bottom: 1rem" class="modal-title" id="modal-title-loan-details">
              Guarantors
            </h6>
            <ul class="list-unstyled max-width-200">
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span>
                </span>
              </li>
              <li>
                <span class="text-sm">
                  John Doe
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    +25472200000</span>
                </span>
              </li>
            </ul>
            <hr class="horizontal dark" />
            <h6 style="margin-bottom: 1rem" class="modal-title" id="modal-title-loan-details">
              Recent Transactions
            </h6>
            <div>
              <transaction-loan-card style="margin-top: -1rem" :horizontal-break="false" :card="{}" :item="[
                {
                  title: 'Self Loan - WID-CDRIQT',
                  date: '27 February 2022, at 12:30 PM',
                  amount: 'KES 2,500',
                  icon: 'fa-arrow-down',
                  color: 'danger',
                },
                {
                  title: 'Loan Self Payment - DEP-CDRIQT',
                  date: '23 March 2022, at 04:30 AM',
                  amount: 'KES 2,000',
                  icon: 'fa-arrow-up',
                  color: 'success',
                },
                {
                  title: 'Loan Self Payment - DEP-CDRIQT',
                  date: '26 March 2022, at 13:45 PM',
                  amount: 'KES 500',
                  icon: 'fa-arrow-up',
                  color: 'success',
                },
              ]">
              </transaction-loan-card>
            </div>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark">Change</soft-button>
            <soft-button type="button" class="mb-2" color="warning" data-bs-dismiss="modal">Close</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverdueLoansTableRowVue from "./OverdueLoansTableRow.vue";

export default {
  name: "overdue-loans-table",
  components: {
    OverdueLoansTableRowVue,
  },
  props: ["overdueBorrowed"],

  data() {
    return {
      bovDetails_id: 0,
      bovDetail: {},
      bovPayCoverage: 0,
    };
  },
};
</script>
