<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Overdue Loans</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table
          class="table align-items-center justify-content-center mb-0"
          v-if="overdueLenders.length > 0"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Lender
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Interest
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Date payable
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Amount
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="al in overdueLenders" :key="al.id">
            <overdue-lender-table-row :al="al" />
          </tbody>

          <!-- <tbody v-else>
            <tr>
              <th colspan="5" class="text-center">No Overdue Loans!</th>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import OverdueLenderTableRow from "./OverdueLenderTableRow.vue";

export default {
  name: "overdue-lend-table",
  components: {
    OverdueLenderTableRow,
  },
  props: ["overdueLenders"],
};
</script>
