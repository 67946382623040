<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navtips btn-background="bg-gradient-primary" />
      </div>
    </div>
  </div>
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pb-0 card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">
              Create Custom Goal
            </h3>
            <p class="mb-0">When do you date to achieve this goal?</p>
          </div>
          <div class="card-body">
            <div>
              <div class="pb-0 text-explainer">
                <input
                  class="form-control"
                  ref="date"
                  id="text"
                  type="date"
                  placeholder="Date"
                  aria-label="date"
                  format="dd/mm/yyyy"
                />
              </div>
              <div style="display: flex">
                <!-- <router-link style="width: 100%" :to="{ name: 'Custom Period' }"> -->
                <soft-button
                  @click="setGoalProp"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="success"
                  full-width
                  >Next
                </soft-button>
                <!-- </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "Custom Date",
  components: {
    Navtips,
    AppFooter,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    setGoalProp() {
      let _date = this.$refs.date.value;
      console.log("date", _date);
      localStorage.setItem("sortikaOnboardingGoalDate", _date);
      this.$router.push({ name: "Custom Period" });
    },
  },
};
</script>
