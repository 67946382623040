<template>
  <div style="margin-bottom: 1rem" class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">{{ title }}</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
          v-for="lender of lenders"
          :key="lender.id"
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <lenders-card-item :lender="lender" :guarantors="guarantors" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LendersCardItem from "./LendersCardItem.vue";
export default {
  name: "LendersCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    lenders: {
      type: Array,
      default: () => [],
    },
    guarantors: {
      type: Array,
      default: () => [],
    },
  },
  components: { LendersCardItem },
};
</script>
