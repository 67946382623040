<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Completed Loans</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Lender
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Interest
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Date payable
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                Amount Payable
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Rating
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="completeBorrowed.length > 0">
            <tr v-for="abl in completedBorrowed" :key="abl.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ abl.client_lender_name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">{{ abl.interest }}%</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{
                  abl.date_payable
                }}</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">KES
                    {{ parseFloat(abl.amount_payable).toLocaleString() }}</span>
                </div>
              </td>
              <td class="text-center">
                <div class="d-flex align-items-center ">
                  <star-rating :inline="true" :star-size="14" :read-only="true" :show-rating="false"
                    :rating="4"></star-rating>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <th colspan="5" class="text-center">No Data found!</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- View Rating Modal -->
  <!-- <div class="col-md-4">
    <div class="modal fade" id="modal-view-rating" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h6 class="modal-title" id="modal-title-view-rating">Overall rating</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <select-goal />
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark">Withdraw</soft-button>
            <soft-button type="button" class="mb-2" color="warning" data-bs-dismiss="modal">Close</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
// import SoftButton from "@/components/SoftButton";
// @ts-ignore
import StarRating from "vue-star-rating";

export default {
  name: "complete-loans-table",
  components: {
    // SoftButton,
    StarRating,
  },
  props: ["completeBorrowed"],
};
</script>
