<template>
  <span>{{ index + 1 }}. &nbsp;{{ pm.phone_number }}</span>
  <span>
    <soft-button
      v-if="!optLoading"
      variant="gradient"
      :color="pm.automatic_push ? 'danger' : 'success'"
      @click="handleOptInOut(pm.id, pm.automatic_push)"
    >
      {{ pm.automatic_push ? "Opt-out" : "Opt-in" }}
    </soft-button>
    <soft-button v-else type="disabled" variant="gradient" color="secondary">
      <i class="fa fa-spin fa-spinner"></i>
    </soft-button>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SoftButton from "../../components/SoftButton.vue";
export default {
  components: { SoftButton },
  name: "opt-form",
  props: ["index", "pm"],

  data() {
    return {
      optLoading: false,
    };
  },

  methods: {
    ...mapActions(["optInOut", "getPaymentMethods"]),

    handleOptInOut(id, status) {
      this.optLoading = true;
      this.optInOut({ payment_id: id, status: !status });
      setTimeout(() => {
        this.getPaymentMethods();
        this.optLoading = false;
        this.showAlert();
      }, 1500);
    },
    showAlert() {
      this.$swal({
        toast: true,
        position: "bottom",
        timer: 3000,
        icon: "success",
        title: "Automatic Savings STK push updated",
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
  },

  computed: {
    ...mapGetters(["OptStatus", "payMethods", "Opt"]),
  },
};
</script>

<style></style>
