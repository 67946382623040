<template>
  <form
    class="modal fade"
    id="modal-package-deposit"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-default"
    aria-hidden="true"
    @submit.prevent="depositPackage"
  >
    <div
      class="modal-dialog modal- modal-dialog-centered modal-"
      role="document"
    >
      <div class="modal-content">
        <div style="border-bottom: none" class="modal-header">
          <h6 class="modal-title" id="modal-title-package-deposit">
            Subscription payment for {{ packName }} package
          </h6>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="form.destination === 'GE'" class="form-group"></div>
          <!-- <div v-if="form.destination === 'SP'" class="form-group" style="margin-bottom: 2rem">
                        <label for="example-number-input" class="form-control-label">Select specific goal below *</label>
                        <select class="form-control" v-model="form.goals">
                            <option selected>Select Goal</option>
                            <option v-for="goal in goals" :key="goal.id" :value="goal.id">
                                {{ goal.type == "GRO" ? "Group Goal" : goal.name }}
                            </option>
                        </select>
                    </div> -->
          <div style="width: 100%; height: 80px" class="radio-card">
            <input
              type="radio"
              id="mpesa"
              name="paymentOption"
              v-model="form.type"
              value="MPA"
            />
            <img
              style="position: absolute; margin: 10px; margin-left: 20px"
              class="w-10 me-3 mb-0"
              src="@/assets/img/logos/mpesa.png"
              alt="logo"
            />
          </div>
          <div
            style="width: 100%; height: 80px; margin-top: -1rem"
            class="radio-card"
          >
            <input
              type="radio"
              id="visa"
              name="paymentOption"
              v-model="form.type"
              value="CA"
            />
            <img
              style="position: absolute; margin: 10px; margin-left: 20px"
              class="w-10 me-3 mb-0"
              src="@/assets/img/logos/visa.png"
              alt="logo"
            />
          </div>
          <div
            style="width: 100%; height: 80px; margin-top: -1rem"
            class="radio-card"
          >
            <input
              type="radio"
              id="bank"
              name="paymentOption"
              v-model="form.type"
              value="BANK"
            />
            <img
              style="position: absolute; margin: 10px; margin-left: 15px"
              class="w-10 me-3 mb-0"
              src="@/assets/img/logos/bank.png"
              alt="logo"
            />
          </div>
          <!-- Mpesa auto and Manual selection -->
          <div
            v-if="form.type === 'MPA'"
            style="display: flex; justify-content: space-between"
          >
            <div style="width: 49%; height: 80px" class="radio-card">
              <input
                type="radio"
                id="autocard"
                name="mpesaPayment"
                v-model="form.mode"
                value="Auto"
              />
              <label style="position: absolute; cursor: pointer" for="autocard">
                <h6 style="font-weight: 400; font-size: 16px; margin: 12px">
                  Automatic
                </h6>
              </label>
            </div>
            <div style="width: 49%; height: 80px" class="radio-card">
              <input
                type="radio"
                id="autocard2"
                name="mpesaPayment"
                v-model="form.mode"
                value="Manual"
              />
              <label
                style="position: absolute; cursor: pointer"
                for="autocard2"
              >
                <h6 style="font-weight: 400; font-size: 16px; margin: 12px">
                  Manual
                </h6>
              </label>
            </div>
          </div>
          <!-- Mpesa Form -->
          <form>
            <div v-if="form.mode === 'Auto' && form.type === 'MPA'">
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  >Enter M-PESA Number (For STK Push):</label
                >
                <input
                  style="margin-bottom: 1rem"
                  type="tel"
                  class="form-control"
                  placeholder="example +2547..."
                  id="recipient-name"
                  v-model="form.phone_number"
                />
              </div>
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  >Amount:</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="KES"
                  id="recipient-name"
                  v-model="form.amount"
                />
              </div>
            </div>
            <div
              v-if="form.mode === 'Manual' && form.type === 'MPA'"
              class="form-group"
            >
              <p>
                <strong
                  >Or manualy use these M-PESA payment instructions</strong
                >
                <br />
                1. Go to M-PESA Menu <br />
                2. Select Lipa na M-PESA <br />
                3. Select Paybill <br />
                4. Enter business <strong>4047737</strong> <br />
                5. Enter account name <strong>Fixed Goal</strong> <br />
                6. Enter Amount <br />
                7. Enter your M-PESA Pin Number <br />
                8. Enter the amount deposited below <br />
                9. Click Deposit
              </p>
            </div>
            <div v-if="form.type === 'CA'" class="form-group">
              <label for="recipient-name" class="col-form-label"
                >Card Details:</label
              >
              <input
                style="margin-bottom: 0.5rem"
                type="text"
                class="form-control"
                placeholder="Card Holder"
                id="recipient-name"
              />
              <input
                style="margin-bottom: 0.5rem"
                type="text"
                class="form-control"
                placeholder="Card No. Eg. 4242 4242 4242 4242"
                id="recipient-name"
              />
              <div style="display: flex" class="form-group">
                <input
                  class="form-control"
                  type="month"
                  value="2018-11"
                  id="example-month-input"
                />
                <div style="margin-right: 1rem"></div>
                <input
                  class="form-control"
                  type="number"
                  placeholder="CVV"
                  id="example-month-input"
                />
              </div>
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  >Amount:</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="KES"
                  id="recipient-name"
                  v-model="form.amount"
                />
              </div>
            </div>
            <div v-if="form.type === 'BANK'" class="form-group">
              <label for="recipient-name" class="col-form-label"
                >Bank Details:</label
              >
              <p>
                <strong>Account Name</strong> Sortika Enterprise<br /><strong
                  >Account Number</strong
                >
                1285101650 <br />
                <strong>Branch</strong> Sarit Center Westlands Branch <br />
              </p>
            </div>
          </form>
        </div>
        <div style="border-top: none" class="modal-footer">
          <soft-button
            class="mb-2"
            variant="gradient"
            type="submit"
            color="dark"
          >
            Deposit
          </soft-button>
          <soft-button class="mb-2" color="warning" data-bs-dismiss="modal"
            >Close</soft-button
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SoftButton from "@/components/SoftButton.vue";
export default {
  name: "PackageDepositModal",
  components: {
    SoftButton,
  },

  props: ["packValue", "packName"],
  data() {
    return {
      form: {},
      goals: [],
      currentPage: 1,
      last_page: 0,
      perPage: 12,
      filter: {},
      query: "",
    };
  },

  created() {
    this.setForm();
    // this.getGoals(1, 25);
  },

  methods: {
    ...mapActions(["make_deposit", "fetchGoals"]),

    getGoals(page, rows) {
      this.fetchGoals({ page, rows, query: this.query, ...this.filter });
    },
    getDepositType(event) {
      let selected_type = event.target.value;
      if (selected_type == "SP") {
        this.getGoals(1, 25);
      }
    },

    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            destination: "GE",
            phone_number: this.currentUser?.phone_number,
            amount: this.packValue,
            goals: 0,
            mode: "Auto",
          };
    },
    depositPackage() {
      let oldPhone = this.form.phone_number;
      let phone = oldPhone;
      let code = "254";

      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        this.form.phone_number = code + phone;
      } else if (phone.startsWith("+")) {
        phone = phone.substring(1);
        this.form.phone_number = oldPhone;
      } else {
        this.form.phone_number = oldPhone;
      }

      this.loadingForm = true;
      console.log(this.form);
      this.make_deposit(this.form);
      this.setForm();
    },
  },
  computed: {
    ...mapGetters(["goals_info", "currentUser"]),
  },
  watch: {
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.goals = this.goals_info.goals;
      this.form.phone_number = this.currentUser.phone_number;
      this.form.amount = this.packValue;
    },
  },
};
</script>

<style></style>
