<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div
        style="margin-bottom: 0.5rem"
        class="col-lg-4 mb-2"
        v-for="goal in goals"
        :key="goal.id"
      >
        <BYOBGoalCard :goal="goal" :goalItems="goals" />
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import BYOBGoalCard from "../components/BYOBGoalCard.vue";
// import FixedGoalsCard from "../components/FixedGoalsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "all-goals-tab",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  props: ["goals"],
  components: {
    BYOBGoalCard,
    // FixedGoalsCard,
  },
  mounted() {
    setTooltip();
  },
};
</script>
