<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div style="margin-top: 0.5rem" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Your balance" :value="'KES ' + saved" :percentage="{
          value: '+505%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div style="margin-top: 0.5rem" class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Returns" value="10%" :percentage="{
          value: '+3%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
        <v-chart class="chart" :option="option" />
      </div>

      <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Passive Savings" value="9%" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrowing limit" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div> -->
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 1rem">
      <div style="margin-top: 10px" class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8" id="nav">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="myInvestment" href="#" role="tab"
              aria-controls="Myinvestment" aria-selected="true" v-on:click="makeActive('myInvestmentActive'), getGoals()">
              Active
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="recommendedInvestment" role="tab"
              aria-controls="RecommendedInvestment" v-on:click="
                makeActive('recommendedInvestmentActive'), fetchRecommended()
                " aria-selected="false">
              Recommended
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="popularInvestment" role="tab"
              aria-controls="PopularInvestment" v-on:click="
                makeActive('popularInvestmentActive'), fetchRecommended()
                " aria-selected="false">
              Popular
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="recentInvestment" role="tab"
              aria-controls="RecentInvestment" v-on:click="
                makeActive('recentInvestmentActive'), fetchRecommended()
                " aria-selected="false">
              Recent
            </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 0.5rem">
        <soft-button class="mb-2" variant="gradient" color="dark" data-bs-toggle="modal"
          data-bs-target="#modal-investment-assistance">Investment Assistant
        </soft-button>
      </div>
    </div>
    <!-- <div style="margin-top: 2.2rem; margin-bottom: -1rem !important; display: flex; justify-content: space-between;">
      <h6>Active Investments</h6>
      <div style="margin-top: -0.5rem;" class="pe-md-3 d-flex align-items-center"
        :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
        <div class="input-group">
          <span style="border: none; background-color: #d2d6da57" class="input-group-text text-body"><i
              class="fas fa-search" aria-hidden="true"></i></span>
          <input type="text" class="form-control" placeholder="Search Investments" />
        </div>
      </div>
    </div> -->
    <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="myInvestment"
      v-show="isActiveTab('myInvestmentActive')">
      <active-investment-tab :goals="goals" />
    </div>
    <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="recommendedInvestment"
      v-show="isActiveTab('recommendedInvestmentActive')">
      <recommended-investment-tab :Investloading="Investloading" :investmentItems="investmentItems" />
    </div>
    <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="popularInvestment"
      v-show="isActiveTab('popularInvestmentActive')">
      <popular-investment-tab />
    </div>
    <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="recentInvestment"
      v-show="isActiveTab('recentInvestmentActive')">
      <recent-investment-tab />
    </div>
  </div>
  <!-- Investment Assistance -->
  <div class="col-md-4">
    <InvestmentAssistance />
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import SoftButton from "@/components/SoftButton.vue";
import ActiveInvestmentTab from "./components/ActiveInvestmentTab.vue";
import RecommendedInvestmentTab from "./components/RecommendedInvestmentTab.vue";
import PopularInvestmentTab from "./components/PopularInvestmentTab.vue";
import RecentInvestmentTab from "./components/RecentInvestmentTab.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import InvestmentAssistance from "./assistance/InvestmentAssistance.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "investments-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      choice: "myInvestmentActive", // Borrow is chosen by default
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      styleOptions: {
        width: "100%",
        height: "400px",
      },
      option: {
        autoresize: true,
        title: {
          text: "Portfolio Distribution",
          left: "right",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [],
        },
        series: [
          {
            name: "Portfolio Distribution",
            type: "pie",
            radius: "70%",
            center: ["60%", "55%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      goals: [],
      investmentItems: [],
      filters: {},
      query: "",
      currentPage: 1,
      perPage: 12,
      last_page: 0,
      loadingPage: false,
      Investloading: false,
      classes: [],
      saved: "0",
    };
  },
  components: {
    MiniStatisticsCard,
    ActiveInvestmentTab,
    RecommendedInvestmentTab,
    PopularInvestmentTab,
    RecentInvestmentTab,
    VChart,
    InvestmentAssistance,
    SoftButton,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  methods: {
    ...mapActions([
      "getInvestments",
      "fetchGoals",
      "getInvestmentsProfile",
      "getInvestmentsClasses",
    ]),

    getGoals(page, rows) {
      this.Investloading = true;
      this.filter = { type: "INV" };
      this.fetchGoals({
        page,
        rows,
        query: this.query,
        ...this.filter,
      });
    },

    fetchInvestments(page, rows) {
      this.getInvestments({ page, rows, query: this.query, ...this.filters });
    },

    fetchRecommended() {
      this.fetchInvestments(1, 12);
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      // if (val == "allGoalsActive") {
      //   getGoals(1, 12);
      // }
      return this.choice === val;
    },
  },
  created() {
    this.loadingPage = true;
    this.getGoals();
    this.getInvestmentsProfile();
    this.getInvestmentsClasses();
  },

  computed: {
    ...mapGetters([
      "investments",
      "loadingInfo",
      "goals_info",
      "investmentsProfile",
      "investmentsClasses",
      "investmentsSaved",
    ]),
  },

  watch: {
    investmentsClasses() {
      this.saved = this.investmentsSaved;
      this.classes = this.investmentsClasses;

      const chartArray = [];
      const chartData = [];

      this.investmentsClasses.map((__class) => {
        let savedAmt = 0;
        __class.goal.map((g) => {
          savedAmt += parseFloat(g.saved_amount);
        });
        let _dictName = __class.name;
        let _dict = { value: savedAmt, name: __class.name };
        chartArray.push(_dict);
        chartData.push(_dictName);
      });
      console.log("chartArray", chartArray);

      this.option.series[0].data = chartArray;
      this.option.legend.data = chartData;
    },
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.goals = this.goals_info.goals;
      // if(this.goals_info.queryState){
      //   this.goals = []
      //   this.goals = this.goals_info.goals
      // }
      // else{
      //   for(let x of this.goals_info.goals){
      //     this.goals.push(x);
      //     this.loadingPage = false;
      //   }
      // }
    },
    investmentsProfile() {
      if (this.investmentsProfile.question) {
        this.$router.push({ name: "Investment Onboarding" });
      }
    },

    investments() {
      this.currentPage = this.investments.current_page;
      this.last_page = this.investments.last_page;
      this.investmentItems = this.investments.investmentData;
      this.Investloading = false;

      //   let newInv = this.investments.investmentData;
      //   if (this.investments.queryState) {
      //     this.investmentItems = [];
      //     this.investmentItems = this.investments.investmentData;
      //   } else {
      //     for (let i of newInv) {
      //       this.investmentItems.push(i);
      //     }
      //   }
    },
  },

  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
