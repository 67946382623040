import store from "../store";
import routes from "./routes";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  // If auth is required and the user is logged in...
  const token = store.state?.auth?.auth_token;
  if (token) {
    return next();
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  // eslint-disable-next-line no-unused-vars
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "Sign In", query: { redirectFrom: routeTo.fullPath } });
  }
});

export default router;
