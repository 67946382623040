<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <h5>Loan Stats</h5>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Borrow from Savings
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="parseInt(Number(loanProfile?.amount_borrowed))"
                :duration="3"
                prefix="KES "
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Borrow from Other
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="parseInt(Number(loanProfile?.borrowing_limit_p2p))"
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Borrow from Group
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="
                  parseInt(Number(loanProfile?.borrowing_limit_group))
                "
                :duration="3"
                prefix="KES "
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="mb-4 card">
          <div class="p-3 card-body" style="padding: 1.1rem !important">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              Credit Score
            </p>
            <h5 class="mb-0 font-weight-bolder">
              <vue3-autocounter
                ref="counter"
                :startAmount="0"
                :endAmount="87"
                :duration="3"
                separator=","
                decimalSeparator="."
                :decimals="2"
                :autoinit="true"
              />
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div
      style="margin-top: 10px; margin-bottom: 20px"
      class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8"
      id="nav"
    >
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1 active active"
            data-bs-toggle="tab"
            id="activeLoan"
            href="#"
            role="tab"
            aria-controls="ActiveLoan"
            aria-selected="true"
            v-on:click="makeActive('activeLoanActive')"
          >
            Active
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="overdueLoan"
            role="tab"
            aria-controls="OverdueLoan"
            v-on:click="makeActive('overdueLoan')"
            aria-selected="false"
          >
            Overdue
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="completeLoan"
            role="tab"
            aria-controls="CompleteLoan"
            v-on:click="makeActive('completeLoanActive')"
            aria-selected="false"
          >
            Complete
          </a>
        </li>
      </ul>
    </div>
    <div id="borrow" v-show="isActiveTab('activeLoanActive')">
      <active-loans-table
        :activeBorrowed="borrowLoanData.filter((loan) => loan.is_active)"
      />
    </div>
    <div id="borrow" v-show="isActiveTab('overdueLoan')">
      <overdue-loans-table
        :overdueBorrowed="borrowLoanData.filter((loan) => loan.is_overdue)"
      />
    </div>
    <div id="borrow" v-show="isActiveTab('completeLoanActive')">
      <complete-loans-table
        :completeBorrowed="borrowLoanData.filter((loan) => loan.is_complete)"
      />
    </div>
    <div style="margin-bottom: 3rem"></div>
    <div class="row mt-4">
      <div class="col-md-6">
        <lenders-card
          title="Lenders Information"
          :lenders="loanLendersData"
          :guarantors="guarantors"
        />
      </div>
      <div class="col-md-6">
        <loan-request-table :borrowerLoanReqData="borrowerLoanReqData" />
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import LendersCard from "../components/LendersCard.vue";
import ActiveLoansTable from "../components/ActiveLoansTable.vue";
import OverdueLoansTable from "../components/OverdueLoansTable.vue";
import CompleteLoansTable from "../components/CompleteLoansTable.vue";
import LoanRequestTable from "../components/LoanRequestTable.vue";
import setNavPills from "@/assets/js/nav-pill-loan.js";
import Vue3Autocounter from "vue3-autocounter";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "borrower-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      borrowLoanData: [],
      borrowerLoanReqData: [],
      stage: "active",
      choice: "activeLoanActive", // Borrow is chosen by default
      guarantors: [],
    };
  },
  props: ["loanProfile", "loanLendersData"],
  components: {
    Vue3Autocounter,
    ActiveLoansTable,
    LendersCard,
    LoanRequestTable,
    OverdueLoansTable,
    CompleteLoansTable,
  },

  created() {
    this.fetchBorrowerLoan();
    this.fetchBorrowerLoanReq();
    this.getGuarators();
  },
  methods: {
    ...mapActions(["getBorrowerLoan", "getBorrowerLoanReq", "getGuarators"]),
    fetchBorrowerLoan() {
      this.getBorrowerLoan();
    },
    fetchBorrowerLoanReq() {
      this.getBorrowerLoanReq();
    },
    ...mapActions(["getBorrower"]),
    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },
  },
  computed: {
    ...mapGetters(["borrowLoans", "borrowerLoanReq", "guaratorsList"]),
    computedDataId: {
      get() {
        return this.data.id.toString();
      },
      set(val) {
        this.data.id = Number(val);
      },
    },
  },
  watch: {
    borrowLoans() {
      this.borrowLoanData = this.borrowLoans;
    },
    borrowerLoanReq() {
      this.borrowerLoanReqData = this.borrowerLoanReq;
    },
    guaratorsList() {
      this.guarantors = this.guaratorsList;
    },
  },

  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
