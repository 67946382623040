export const customParse = (json_string) => {
  const data =
    json_string && json_string.length > 1 ? JSON.parse(json_string) : "";

  return data;
};

export const tokenHeaders = (state) => {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (state.token) config.headers["Authorization"] = `Token ${state.token}`;

  return config;
};

export const tokenMultiPartHeaders = (state) => {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };

  if (state.token) config.headers["Authorization"] = `Token ${state.token}`;

  return config;
};

export const tokenOnlyHeaders = (state) => {
  let config = {
    headers: {
      Authorization: `Token ${state.token}`,
    },
  };

  return config;
};

export const setDateFormat = (date) => {
  let localDate = new Date(date);
  let year = localDate.getFullYear();
  let month = localDate.getMonth() + 1;
  let day = localDate.getDate();
  // let hour = localDate.getHours();
  // let min = localDate.getMinutes();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return day + "-" + month + "-" + year;
};

export const setReverseDateFormat = (date) => {
  let localDate = new Date(date);
  let year = localDate.getFullYear();
  let month = localDate.getMonth() + 1;
  let day = localDate.getDate();
  // let hour = localDate.getHours();
  // let min = localDate.getMinutes();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month + "-" + day;
};

export const get_host_url = () => {
  let _url = process.env.VUE_APP_CLIENT_URL;
  _url = _url.toString();
  _url = _url.slice(0, -1);
  return _url;
};
