<template>
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <!-- {{ rgoal }} -->
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h5 class="mb-1 text-dark font-weight-bold">
              {{ rgoal.name }}
            </h5>
            <span class="text-xs"></span>
          </div>
          <div class="d-flex align-items-center text-sm"></div>
        </li>
        <div class="col-md-12">
          <div
            style="padding: 2rem !important"
            class="card-header p-0 mx-3 mt-1 position-relative z-index-1"
          >
            <img
              src="../../assets/img/logos/samsung.png"
              style="height: 150px"
              class="img-fluid border-radius-lg"
            />
          </div>
          <!-- <div id="carouselIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../../assets/img/logos/samsung.png" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../../assets/img/logos/samsung.png" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../../assets/img/logos/samsung.png" class="d-block w-100" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div> -->
        </div>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg row"
        >
          <div class="d-flex flex-column col-lg-6 col-sm-12">
            <span class="text-sm">Sale Prize</span>
            <h6
              style="color: #0db17a !important"
              class="text-dark mb-1 font-weight-bold"
            >
              KES {{ rgoal?.minimum_amount?.toLocaleString() }}
            </h6>
          </div>
          <div class="d-flex flex-column col-lg-6 col-sm-12">
            <span class="text-sm" style="text-align: end">Min. Period</span>
            <h6
              style="color: #0db17a !important; text-align: end"
              class="text-dark mb-1 font-weight-bold"
            >
              {{ rgoal.minimum_period }} day(s)
            </h6>
          </div>
        </li>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <span class="text-sm">Description</span>
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ rgoal.call_to_action }}
            </h6>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <div>
          <soft-button
            style="box-shadow: none"
            variant="gradient"
            color="success"
            fullWidth
            data-bs-toggle="modal"
            :data-bs-target="'#fixedSProductDetailsModal_' + rgoal.id"
            >Add Goal
          </soft-button>
        </div>
        <!-- Modal -->
        <div
          class="modal fade"
          :id="'fixedSProductDetailsModal_' + rgoal.id"
          tabindex="-1"
          role="dialog"
          aria-labelledby="fixedSProductDetailsModalTitle"
          aria-hidden="true"
          :showPopUp="showPopUp"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <form @submit.prevent="createGoal" class="modal-content">
              <div style="border-bottom: none" class="modal-header">
                <h5 class="modal-title" id="fixedSProductDetailsModalLabel">
                  {{ rgoal.name }}
                </h5>
                <button
                  type="button"
                  class="btn-close text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="example-text-input" class="form-control-label"
                    >Goal Name</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    readonly
                    v-model="form.name"
                    id="name"
                  />
                  <label for="example-text-input" class="form-control-label"
                    >Minimum amount</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    min="1"
                    v-model="form.target_amount"
                    id="amount"
                  />
                  <label for="date" class="form-control-label mt-2">
                    <span>
                      End Date &nbsp;
                      <span
                        @mouseover="showDetail"
                        @mouseout="detailCard = false"
                        ><i class="fas fa-question-circle fa-lg"></i
                      ></span>
                      &nbsp; &nbsp;
                      <div class="bg-dark text-white" v-show="detailCard">
                        <p class="p-1" :style="{ fontSize: '11px' }">
                          Difference between current date and end date is to be
                          greater than the minimum period of
                          {{ rgoal.minimum_period }} days.
                        </p>
                      </div>
                    </span>

                    <span
                      class="bg-dark text-secondary p-1 rounded"
                      :style="{ fontSize: '11px' }"
                      >Date difference of: {{ dateDifference }} day(s)</span
                    >
                  </label>

                  <input
                    type="date"
                    v-model="form.end_date"
                    id="date"
                    @change="dateDiff($event)"
                    class="form-control"
                    required=""
                  />
                </div>

                <input type="hidden" readonly v-model="form.type" />

                <p>
                  <a
                    style="text-decoration: underline"
                    class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    data-bs-toggle="collapse"
                    href="#collapseGoalTerms"
                    aria-expanded="false"
                    aria-controls="collapseGoalTerms"
                  >
                    Goal Terms
                    <i
                      class="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </p>
                <div class="collapse" id="collapseGoalTerms">
                  <div class="card card-body">
                    {{ rgoal.terms }}
                  </div>
                </div>
              </div>
              <div style="border-top: none" class="modal-footer">
                <soft-button
                  style="margin-right: 0.1rem"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="success"
                  type="submit"
                >
                  Add Goal
                </soft-button>
                <soft-button
                  class="my-4 mb-2"
                  variant="gradient"
                  color="dark"
                  data-bs-dismiss="modal"
                >
                  Close
                </soft-button>
              </div>
            </form>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";
import { setReverseDateFormat } from "../../services/helpers";
// import SoftProgress from "@/components/SoftProgress";

export default {
  name: "RecommendedFixedGoalCard",
  components: {
    // SoftProgress,
    SoftButton,
  },
  props: ["rgoal"],

  data() {
    return {
      form: {
        group: null,
      },
      loadingForm: false,
      loadingPage: false,
      createStatus: false,
      showPopUp: false,
      detailCard: false,
      dateDifference: "",
    };
  },

  methods: {
    ...mapActions(["createCustomSavingGoal"]),

    showDetail() {
      return (this.detailCard = true);
    },

    HidePop() {
      this.showPopUp = false;
    },

    setRgoal() {
      this.loadingPage = this.loadingInfo.loading;
      // this.rgoal = this.savingDetails;

      this.dateDifference = this.rgoal.minimum_period;

      this.form.name = this.rgoal.name;
      this.form.target_amount = this.rgoal.minimum_amount;
      this.form.saving = this.rgoal.id;

      this.form.end_date = this.getMinDate(this.rgoal.minimum_period);
    },

    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
            name: this.rgoal.name,
            target_amount: this.rgoal.minimum_amount,
            end_date: this.rgoal.end_date,
            type: "SAVU",
          };
    },
    dateDiff(e) {
      let eDate = e.target.value;
      let endDate = new Date(eDate);
      let today = new Date();
      const diffTime = Math.floor(endDate - today);
      this.dateDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    getMinDate(days) {
      var result = new Date();
      result.setDate(result.getDate() + days);
      return setReverseDateFormat(result);
    },
    createGoal() {
      if (
        parseFloat(this.form.target_amount) >=
        parseFloat(this.rgoal.minimum_amount)
      ) {
        this.loadingForm = true;
        this.createCustomSavingGoal([this.form]);
      } else {
        this.$toast.error(
          "target amount should be equal to or greater than defined goal amount"
        );
      }
    },
  },

  created() {
    this.setForm();
    this.setRgoal();
  },

  computed: {
    ...mapGetters(["getGoalSavedStatus", "loadingInfo"]),
  },
  watch: {
    "loadingInfo.loading"() {
      this.loadingForm = this.loadingInfo.loading;
    },
    // "loadingInfo.message"() {
    //   const message = this.loadingInfo.message;
    //   const error = this.loadingInfo.error;
    //   if (error) {
    //     this.$toast.error(message);
    //   } else {
    //     this.$toast.info(message);
    //   }
    // },
    getGoalSavedStatus() {
      this.createStatus = this.getGoalSavedStatus;
      if (this.createStatus) {
        this.setForm();
        this.showPopUp = false;
      }
    },
  },
};
</script>
