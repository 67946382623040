import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  clientGroups: [],
};

const getters = {
  clientGroups: (state) => state.clientGroups,
};

const mutations = {
  SET_CLIENT_GROUPS: (state, payload) => {
    state.clientGroups = payload;
  },
};

const actions = {
  getClientGroups: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "client/groups?";

      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_CLIENT_GROUPS", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
