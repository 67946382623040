<template>
  <div style="margin-top: 2rem" class="py-4 container-fluid">
    <div style="padding: 3rem !important" class="row">
      <ul class="list-group">
        <li
          style="padding: 1.2rem !important"
          class="px-0 border-0 list-group-item"
        >
          <h4>Select Goal to withdraw from</h4>
          <ul v-if="goals.length > 0">
            <li
              v-for="(goal, index) in goals"
              :key="goal.id"
              style="
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.5rem;
              "
            >
              <div style="padding-left: 0" class="form-check">
                <!-- <input
                  type="checkbox"
                  :id="'goalCheckBox_' + index"
                  :ref="'goalCheckBoxField_' + index"
                  name="goals"
                  class="form-check-input"
                  v-model="amount"
                  :value="goal.saved_amount"
                /> -->

                <h6 class="custom-control-label" for="goals">
                  {{ index + 1 }}. {{ goal.name ? goal.name : "group goal" }}
                </h6>
                <h6 class="mb-1 font-weight-bold text-sm text-primary">
                  Balance KES {{ goal.saved_amount }}
                </h6>
              </div>

              <soft-button
                class="my-4 mb-2"
                variant="gradient"
                color="dark"
                data-bs-toggle="modal"
                :data-bs-target="'#withdrawGoal_' + goal.id"
              >
                Select
              </soft-button>

              <!-- withdrawal modal -->
              <div
                class="modal fade"
                :id="'withdrawGoal_' + goal.id"
                tabindex="-1"
                :ref="'withModal' + goal.id"
                role="dialog"
                aria-labelledby="fixedGoalDetailsModalTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <form
                    class="modal-content"
                    @submit.prevent="handleWithdrawal"
                  >
                    <div style="border-bottom: none" class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Withdraw from "{{
                          goal.name ? goal.name : "group goal"
                        }}" Goal
                      </h5>
                      <button
                        type="button"
                        class="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div
                        style="width: 100%; height: 80px"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex flex-column">
                          <label
                            for="example-number-input"
                            class="form-control-label"
                          >
                            Enter Amount To withdrawal *
                          </label>
                          <input
                            style="width: 100%"
                            v-model="form.amount"
                            :class="
                              isMore
                                ? 'border-danger form-control form-control-sm'
                                : 'form-control form-control-sm'
                            "
                            type="number"
                            placeholder="Enter Amount"
                            @keyup="checkValue(goal.saved_amount, $event)"
                          />
                          <small v-if="isMore" class="text-danger text-sm mt-1">
                            Amount is greater than the goal balance!!
                          </small>
                          <small class="my-2">
                            Maximum Withdrawable Amount {{ goal.saved_amount }}
                          </small>
                          <input type="hidden" v-model="form.id" />
                          <small
                            v-if="message != ''"
                            class="text-danger text-sm mt-1"
                          >
                            {{ message }}
                          </small>
                        </div>
                      </div>
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        type="submit"
                      >
                        Initiate Withdrawal
                      </soft-button>
                      &nbsp;
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="dark"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
          <ul v-else>
            <div
              style="margin-left: 40%"
              class="spinner-grow text-dark"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </ul>
          <div
            style="align-items: flex-end; margin-top: 2rem"
            class="d-flex flex-column"
          >
            <span style="font-size: 0.9rem">Total Withdrawable</span>
            <h4 class="text-dark mb-1 font-weight-bold">
              KES {{ amt.toFixed(2) }}
            </h4>
            <!-- <h4 class="text-dark mb-1 font-weight-bold">KES {{ amount }}</h4> -->
          </div>
          <div
            style="
              border-top: none;
              margin-right: -2px !important;
              padding: 0 !important;
              margin-top: 1rem;
            "
            class="modal-footer"
          >
            <soft-button class="mb-2" variant="gradient" color="dark"
              >Withdraw</soft-button
            >
          </div>
          <div style="margin-bottom: 1rem"></div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import SoftButton from "@/components/SoftButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "withdrawal-default",
  components: {
    SoftButton,
  },
  mounted() {
    setTooltip();
  },

  data() {
    return {
      goals: [],
      savingItems: [],
      total: 0,
      amt: 0,
      checked: "",
      amount: [],
      form: {},
      isMore: false,
      message: "",
    };
  },

  created() {
    this.getGoals();
  },

  methods: {
    ...mapActions(["fetchGoals", "fetchCustomGoals", "makeWithdraw"]),

    getGoals(page, rows) {
      this.fetchGoals({ page, rows, query: this.query, ...this.filter });
    },

    handleWithdrawal() {
      let amount = this.form.amount;
      if (amount == "" || amount == null) {
        this.message = "Amount cannot be empty";
        return;
      } else {
        let id = this.form.id;
        this.makeWithdraw({ goal_id: id, amount: amount });
        setTimeout(() => {
          this.showAlert();
          this.getGoals();
          this.$refs[`withModal_${id}'`].modal("hide");

          this.$router.go();
        }, 2000);
      }
    },

    getTotalGoalAmount() {
      this.goals.map((g) => {
        this.amt += parseFloat(g.saved_amount);
      });
      return this.amt;
    },

    checkValue(amt, event) {
      let max_amt = parseFloat(amt);
      let vl = parseFloat(event.target.value);

      if (vl > max_amt) {
        this.isMore = true;
      } else {
        this.isMore = false;
      }
    },

    onInput(__value, event) {
      // Remove all non-numeric characters except the dot
      let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");
      // Ensure there is only one dot in the input
      const dotIndex = sanitizedValue.indexOf(".");
      if (dotIndex !== -1) {
        const afterDot = sanitizedValue.substring(dotIndex + 1);
        const beforeDot = sanitizedValue.substring(0, dotIndex);
        sanitizedValue = `${beforeDot}.${afterDot.replace(".", "")}`;
      }
      // Update the input value
      event.target.value = sanitizedValue;
    },
    showAlert() {
      this.$swal({
        toast: true,
        position: "bottom",
        timer: 3000,
        icon: "success",
        title: "Debited to MPESA within <strong>48 Hours</strong>",
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
  },

  computed: mapGetters([
    "goals_info",
    "loadingInfo",
    "c_goals_info",
    "currentUser",
    "withdrawal_status",
  ]),

  watch: {
    withdrawal_status() {
      this.message = this.withdrawal_status.message;
    },
    loadingInfo() {
      this.loadingPage = this.loadingInfo.loading;
    },
    goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      const allGoals = this.goals_info.goals;
      const gls = allGoals.filter(
        (g) => g.type != "GROE" && parseFloat(g.saved_amount) > 0
      );
      this.goals = gls;
      this.getTotalGoalAmount();
      // if(this.goals_info.queryState){
      //   this.goals = []
      //   this.goals = this.goals_info.goals
      // }
      // else{
      //   for(let x of this.goals_info.goals){
      //     this.goals.push(x);
      //     this.loadingPage = false;
      //   }
      // }
    },
    c_goals_info() {
      this.currentPage = this.goals_info.current_page;
      this.last_page = this.goals_info.last_page;
      this.savingItems = this.c_goals_info.c_goals;
    },
  },
};
</script>
