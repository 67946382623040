<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <!-- <ul class="navbar-nav">
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Dashboard' }">
                        <template #icon>
                            <shop />
                        </template>
                    </profilenav-collapse>
                </li>
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Tables' }">
                        <template #icon>
                            <office />
                        </template>
                    </profilenav-collapse>
                </li>
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Billing' }">
                        <template #icon>
                            <credit-card />
                        </template>
                    </profilenav-collapse>
                </li>
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Profile' }">
                        <template #icon>
                            <customer-support />
                        </template>
                    </profilenav-collapse>
                </li>
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Sign In' }">
                        <template #icon>
                            <document />
                        </template>
                    </profilenav-collapse>
                </li>
                <li class="nav-item">
                    <profilenav-collapse navText="" :to="{ name: 'Sign Up' }">
                        <template #icon>
                            <spaceship />
                        </template>
                    </profilenav-collapse>
                </li>
            </ul> -->
  </div>
  <div style="padding-top: 30px"></div>
  <div>
    <div
      style="
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
      "
      class="col-auto d-flex"
    >
      <div>
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">Hello,</h6>
            <span
              style="font-size: 28px !important; font-weight: 700"
              class="text-xs"
              >{{ currentUser.full_name }}</span
            >
            <star-rating
              :inline="true"
              :star-size="14"
              :read-only="true"
              :show-rating="false"
              :rating="4"
            ></star-rating>
            <div class="d-flex" style="justify-content: space-between">
              <span style="font-size: 14px; color: black">Overall Rating</span>
              <i
                style="padding-top: 5px"
                type="button"
                class="fa fa-info-circle text-dark cursor-pointer btn-tooltip"
                aria-hidden="true"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-original-title="This is your overall rating between sortika app product usage"
                title="This is your overall rating between sortika app product usage"
                data-container="body"
                data-animation="true"
              >
              </i>
            </div>
          </div>
        </li>
      </div>
      <div class="avatar avatar-xl position-relative">
        <img
          v-if="currentUser.image == null"
          style="border-radius: 9rem !important; box-shadow: none !important"
          src="@/assets/img/avatar.png"
          alt="profile_image"
          class="shadow-sm w-100 border-radius-lg"
        />
        <img
          style="border-radius: 9rem !important; box-shadow: none !important"
          :src="currentUser.image"
          alt="profile_image"
          class="shadow-sm w-100 h-100 border-radius-lg"
        />
      </div>
    </div>
    <div style="padding-left: 20px; padding-right: 20px">
      <li
        class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
      >
        <div class="d-flex flex-column">
          <h6
            style="font-size: 12px !important; color: grey !important"
            class="mb-1 text-dark font-weight-bold text-sm"
          >
            Copy your referral link and share with your friends on your social
            channel
          </h6>
        </div>
      </li>
    </div>
    <div>
      <div
        class="d-flex align-items-center"
        style="margin-left: 1rem; margin-right: 1rem"
      >
        <div class="form-group w-70">
          <div class="input-group bg-gray-200">
            <input
              class="form-control form-control-sm"
              :value="currentUser.referal_link"
              type="text"
              disabled=""
              onfocus="focused(this)"
              onfocusout="defocused(this)"
              ref="mylink"
              rel="noopener noreferrer"
            />
            <span
              class="input-group-text bg-transparent"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              aria-label="Referral code is reusable"
              data-bs-original-title="Referral code is reusable"
              ><i class="ni ni-key-25"></i
            ></span>
          </div>
        </div>
        <button
          href="javascript:;"
          class="btn btn-outline-secondary ms-2"
          style="border: 2px solid"
          @click="copyUrl"
          @click.prevent="handleClick"
          :style="{
            backgroundColor: active ? '#14F9AC' : 'white',
            color: active ? 'black' : 'gray',
          }"
        >
          Copy
        </button>
      </div>
    </div>
    <div>
      <router-link :to="{ name: 'Profile' }">
        <a
          style="
            padding-left: 20px !important;
            padding-bottom: 20px !important;
            text-decoration: underline;
          "
          class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
          >View Profile
          <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i
        ></a>
      </router-link>
    </div>
  </div>

  <div class="mb-xl-0 mb-4">
    <packages-card
      style="
        margin-left: 20px;
        margin-right: 20px;
        box-shadow: none !important;
        margin-bottom: 20px;
      "
    />
  </div>
  <!-- <div class="mb-xl-0 mb-4">
    <add-card style="
                                                                                        margin-left: 20px;
                                                                                        margin-right: 20px;
                                                                                        box-shadow: none !important;
                                                                                        margin-bottom: 20px;
                                                                                      " />
  </div> -->
  <div id="carouselControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item">
        <div class="mb-xl-0 mb-4">
          <add-card
            style="
              margin-left: 20px;
              margin-right: 20px;
              box-shadow: none !important;
              margin-bottom: 20px;
            "
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="mb-xl-0 mb-4">
          <add-card
            style="
              margin-left: 20px;
              margin-right: 20px;
              box-shadow: none !important;
              margin-bottom: 20px;
            "
          />
        </div>
      </div>
      <div class="carousel-item active">
        <div class="mb-xl-0 mb-4">
          <add-card
            style="
              margin-left: 20px;
              margin-right: 20px;
              box-shadow: none !important;
              margin-bottom: 20px;
            "
          />
        </div>
      </div>
    </div>
    <div>
      <a
        class="carousel-control-prev"
        href="#carouselControls"
        role="button"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselControls"
        role="button"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
// import ProfilenavCollapse from "./ProfilenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import Settings from "../../components/Icon/Settings.vue";
import PackagesCard from "@/examples/Cards/PackagesCard.vue";
import AddCard from "@/examples/Cards/AddCard.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftButton from "@/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
// @ts-ignore
import StarRating from "vue-star-rating";
import { mapGetters } from "vuex";

const pixCodeInput = ref(null);

export default {
  name: "ProfilenavList",
  copyPixCodeClick() {
    navigator.clipboard.writeText(pixCodeInput.value.value);
  },
  // props: {
  //   cardBg: String,
  //   // profileData: Object,
  // },
  data() {
    return {
      title: "Sortika UI Dashboard",
      controls: "dashboardsExamples",
      isActive: "active",
      showMenu: false,
      disabled: true,
      active: false,
      profileInfo: {},
      URL: "https://api.sortika.co.ke",
    };
  },
  props: ["cardBg"],
  components: {
    PackagesCard,
    AddCard,
    // SoftInput,
    // SoftButton,
    StarRating,
    // ProfilenavCollapse,
    // Shop,
    // Office,
    // CreditCard,
    // CustomerSupport,
    // Document,
    // Spaceship,
  },

  methods: {
    handleClick() {
      this.active = true;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    copyUrl() {
      const el = document.createElement("textarea");
      el.value = this.currentUser.referal_link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },
  created() {
    var scripts = [
      "https://demos.creative-tim.com/test/soft-ui-dashboard-pro/assets/js/plugins/multistep-form.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/datatables.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/soft-ui-dashboard.min.js?v=1.1.1",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/choices.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/smooth-scrollbar.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/dragula/dragula.min.js",
      "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/js/plugins/chartjs.min.js",
      "/assets/js/multistep-form.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    setTooltip();
  },
};
</script>
