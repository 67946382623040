import { tokenHeaders } from "../../services/helpers";
import axios from "axios";

const URL = process.env.VUE_APP_BASE_URL;

const state = {
  investments: [],
  investments_classes: [],
  current_page: 1,
  last_page: 1,
  queryState: false,
  investments_profile: {},
  invBoardingData: {},
  invBoardingMsg: "",
  invBoardingSuccess: false,
  invBoardingComplete: false,
  investments_saved: "",
};

const getters = {
  investments: (state) => {
    return {
      investmentData: state.investments,
      curent_page: state.current_page,
      last_page: state.last_page,
      queryState: state.queryState,
    };
  },
  investmentsClasses: (state) => state.investments_classes,
  investmentsSaved: (state) => state.investments_saved,
  investmentsProfile: (state) => state.investments_profile,
  invBoarding: (state) => {
    return {
      data: state.invBoardingData,
      msg: state.invBoardingMsg,
      success: state.invBoardingSuccess,
      complete: state.invBoardingComplete,
    };
  },
};

const mutations = {
  SET_INVESTMENTS: (state, payload) => {
    state.investments = payload.data;
    state.current_page = payload.current_page;
    state.last_page = payload.last_page;
  },
  SET_INVESTMENT_CLASS: (state, payload) => {
    state.investments_classes = payload.data;
    state.investments_saved = payload.saved_amount;
  },
  SET_INVESTMENT_PROFILE: (state, payload) => {
    state.investments_profile = payload;
  },
  SET_INVESTMENT_BOARDING_ANSWER: (state, payload) => {
    const { data, message, success, complete } = payload;
    state.invBoardingData = data;
    state.invBoardingMsg = message;
    state.invBoardingSuccess = success;
    state.invBoardingComplete = complete;
  },
};

const actions = {
  getInvestments: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "client/investments?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await axios.get(url, headers);

      const { data, message, last_page } = response.data;

      if (params.query != "") {
        state.queryState = true;
      }
      const queryState = state.queryState;

      commit("SET_INVESTMENTS", {
        data,
        queryState,
        last_page,
        current_page: params.page ? params.page : 1,
      });

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getInvestmentsClasses: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "client/investment-classes";
      const response = await axios.get(url, headers);
      const { data, message, saved_amount } = response.data;
      console.log("classes", { data: data, saved_amount: saved_amount });
      commit("SET_INVESTMENT_CLASS", {
        data: data,
        saved_amount: saved_amount,
      });

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getInvestmentsProfile: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "investments/survey/questions/get";
      const response = await axios.get(url, headers);
      const { data, message } = response.data;
      commit("SET_INVESTMENT_PROFILE", data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  addInvestmentAnswer: async ({ commit, rootState }, formData) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ token });
      let url = URL + "investments/survey/questions/answer";
      const response = await axios.post(url, formData, headers);
      const { data, message, success, complete } = response.data;
      commit("SET_INVESTMENT_BOARDING_ANSWER", {
        data,
        message,
        success,
        complete,
      });
      console.log("invest", response.data);

      commit(
        "SET_LOADING",
        {
          loading: false,
          message: message,
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
