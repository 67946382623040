<template>
    <div class="form-group">
        <div style="width: 100%">
            <div class="card z-index-0">
                <div class="card-body">
                    <div>
                        <iframe
                            style="width: 100%; height: 12rem; object-fit: contain; margin-top: 1rem; margin-bottom: 1rem; border-radius: 12px;"
                            src="https://www.youtube.com/embed/4DjDE60krDY" title="How To Become Your Own Bank 2022"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div style="margin-top: 10px; margin-bottom: 20px;margin-left: 1px; margin-right: 1px;"
                        class="row align-items-center">
                        <ul class="nav nav-pills nav-fill p-1" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="investment"
                                    href="#" role="tab" aria-controls="Investment" aria-selected="true"
                                    v-on:click="makeActive('investmentActive')">
                                    Investment Goals
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="assistant" role="tab"
                                    aria-controls="Assistant" v-on:click="makeActive('assistantActive')"
                                    aria-selected="false">
                                    Investment Assistant
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="explainer-body">
                        <div class="pb-0 text-explainer">
                            <div>
                                <p class="mb-0" style="padding: 5px" id="Investment"
                                    v-show="isActiveTab('investmentActive')">We
                                    give you access to local and global
                                    investment opportunities through listed investment products by various
                                    investment managers. Each listed product is classified with it's profile,
                                    background, past performance and the promoter to guide you to make informed
                                    decisions on asset selection

                                </p>
                                <p class="mb-0" style="padding: 5px" id="Assistant" v-show="isActiveTab('assistantActive')">
                                    You
                                    have
                                    trouble making asset selection
                                    and allocation decisions? we got you. The investment assistant will create
                                    sample portfolios for you that you can invest in, provide you hypothetical
                                    projections and allow you to add the goals. Our objective is to help simplify
                                    your investment process by creating portfolios that suit your investment
                                    profile.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pill-loan.js";

export default {
    name: "investment-onboarding-in-app-explainer",
    el: "#app",
    data() {
        return {
            choice: "investmentActive", // Borrow is chosen by default
        }
    },
    methods: {
        makeActive: function (val) {
            this.choice = val;
        },
        isActiveTab: function (val) {
            // if (val == "allGoalsActive") {
            //   getGoals(1, 12);
            // }
            return this.choice === val;
        },
    },
    mounted() {
        setNavPills();
    },
}
</script>