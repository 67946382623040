<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div style="display: flex; justify-content: space-between">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-1">Transactions</h6>
              <p class="text-sm">All your transaction records</p>
            </div>
            <soft-button
              style="margin-right: 1rem"
              class="my-4 mb-2"
              variant="gradient"
              color="dark"
            >
              <i class="fa fa-print text-ms me-1" aria-hidden="true"></i>
              Print Statement
            </soft-button>
          </div>
          <div><transactions-table :transactions="transactions" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import TransactionsTable from "./TransactionsTable.vue";

export default {
  name: "Transactions Tab",
  components: {
    SoftButton,
    TransactionsTable,
  },
  props: ["transactions", "analysis"],
};
</script>
