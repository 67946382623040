<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navtips btn-background="bg-gradient-primary" />
      </div>
    </div>
  </div>
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pb-0 card-header text-start">
            <h3 class="font-weight-bolder text-success text-gradient">
              Loan Application
            </h3>
            <p class="mb-0">
              An SMS will be sent to the contacts with an invite link
            </p>
          </div>
          <div class="card-body">
            <div>
              <div class="pb-0 text-explainer">
                <div>
                  <label>Invite atleast 5 contacts</label>
                  <input class="form-control my-1" ref="phone1" id="text" type="tel" placeholder="254722001122"
                    aria-label="date" />
                  <input class="form-control my-1" ref="phone2" id="text" type="tel" placeholder="254722334455"
                    aria-label="date" />
                  <input class="form-control my-1" ref="phone3" id="text" type="tel" placeholder="254722667788"
                    aria-label="date" />
                  <input class="form-control my-1" ref="phone4" id="text" type="tel" placeholder="254722667788"
                    aria-label="date" />
                  <input class="form-control my-1" ref="phone5" id="text" type="tel" placeholder="254722667788"
                    aria-label="date" />
                </div>
              </div>
              <div style="display: flex">
                <router-link style="width: 50%" :to="{ name: 'Loan Tips' }">
                  <soft-button class="my-4 mb-2" variant="gradient" color="dark" full-width>Do it later
                  </soft-button>
                </router-link>
                <div style="margin-left: 10px"></div>
                <span style="width: 50%">
                  <soft-button type="submit" class="my-4 mb-2" variant="gradient" color="success" full-width
                    @click.prevent="addInvites">Send Request
                  </soft-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Guarantors",
  components: {
    Navtips,
    AppFooter,
    SoftButton,
    // SoftInput,
    // NavPill,
  },
  data() {
    return {
      picked: "One",
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["sendInvites"]),

    sanitizePhone(phoneNo) {
      let code = "+254";
      if (phoneNo.startsWith("0")) {
        phoneNo = phoneNo.substring(1);
        phoneNo = code + phoneNo;
        return phoneNo;
      } else if (phoneNo.startsWith("254")) {
        phoneNo = "+" + phoneNo;
        return phoneNo;
      } else if (phoneNo.startsWith("")) {
        return phoneNo;
      } else {
        phoneNo = code + phoneNo;
        return phoneNo;
      }
    },

    addInvites() {
      let phones = [];
      let phone1 = this.sanitizePhone(this.$refs.phone1.value);
      let phone2 = this.sanitizePhone(this.$refs.phone2.value);
      let phone3 = this.sanitizePhone(this.$refs.phone3.value);
      let phone4 = this.sanitizePhone(this.$refs.phone4.value);
      let phone5 = this.sanitizePhone(this.$refs.phone5.value);

      phone1 != "" && phones.push(phone1);
      phone2 != "" && phones.push(phone2);
      phone3 != "" && phones.push(phone3);
      phone4 != "" && phones.push(phone4);
      phone5 != "" && phones.push(phone5);

      //   let form = { phones: phones };
      console.log(phones);
      this.sendInvites({ phones: phones });
    },
  },
  computed: {
    ...mapGetters(["invite"]),
  },
  watch: {
    "invite.success"() {
      if (this.invites.success) {
        this.$router.push({ name: "Loan Tips" });
      } else {
        this.$toast.error(
          "Could not send invites please confirm phone numbers. Format e.g. +2547..."
        );
        return;
      }
    },
  },
};
</script>
