<template>
    <div class="card bg-transparent shadow-xl">
        <div class="overflow-hidden position-relative border-radius-xl" :style="{
            backgroundImage:
                'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        }">
            <span class="mask" :class="`bg-gradient-add`"></span>
            <div class="card-body position-relative z-index-1 p-3">
                <h5 class="text-white mb-5 pb-2">
                    {{ card.title }}<br /> {{ card.titleBreak }}
                </h5>
                <div class="d-flex">
                    <div class="d-flex">
                        <div :class="$store.state.isRTL ? 'ms-4' : 'me-4'">
                            <p class="text-white text-sm opacity-8 mb-0">
                                {{ card.holderText }}
                            </p>
                            <h6 class="text-white mb-0">{{ card.offer }}<br /> {{ card.offerBreak }}</h6>
                        </div>
                        <div>
                            <p class="text-white text-sm opacity-8 mb-0">

                            </p>
                            <h6 class="text-white mb-0"></h6>
                        </div>
                    </div>
                    <div class="w-20 d-flex align-items-end justify-content-end"
                        :class="$store.state.isRTL ? 'me-auto' : 'ms-auto'">
                        <offer style="width: 20rem !important" class="w-60 mt-2" :img="img2" alt="offer" />
                    </div>
                </div>
                <div style="margin-top: 10px">
                    <soft-button class="mb-2" variant="gradient" color="dark" full-width data-bs-toggle="modal"
                        data-bs-target="#unlockModal">Unlock this offer
                    </soft-button>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="unlockModal" tabindex="-1" role="dialog" aria-labelledby="unlockModalTitle"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div style="border-bottom: none;" class="modal-header">
                                <h5 class="modal-title" id="unlockModalLabel">Add Premium Goal</h5>
                                <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <h5 class="mb-1 text-dark font-weight-bold">
                                        Samsung Galaxy S23 Ultra
                                    </h5>
                                    <li
                                        class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                        <div class="d-flex flex-column">
                                            <span class="text-sm">Sale Prize</span>
                                            <h6 style="color: #0db17a !important" class="text-dark mb-1 font-weight-bold">
                                                KES 97,000
                                            </h6>
                                        </div>
                                    </li>
                                    <li
                                        class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                        <div class="d-flex flex-column">
                                            <span class="text-sm">Description</span>
                                            <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                                The samsung galaxy s23 ultra with 200X Zoom launched recently with tghe
                                                Quulcomm 3nm 2nd Gen
                                                chip with 12GB of RAM and 256GB internal base storage
                                            </h6>
                                        </div>
                                    </li>
                                </div>
                                <form>
                                    <div class="form-group">
                                        <input class="form-control" type="date" value="2023-11-23" id="premium-date">
                                    </div>
                                </form>
                            </div>
                            <div style="border-top: none" class="modal-footer">
                                <soft-button style="margin-right: 0.1rem;" class="my-4 mb-2" variant="gradient"
                                    color="success">
                                    Add Goal
                                </soft-button>
                                <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                                    Close
                                </soft-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import Offer from "@/components/Offer.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
// import img1 from "@/assets/img/logos/mastercard.png";
import img2 from "@/assets/img/logos/samsung.png";
import SoftButton from "@/components/SoftButton.vue";

export default {
    name: "AddCardMobile",
    components: {
        // SoftAvatar,
        SoftButton,
        Offer,
    },
    props: {
        card: {
            type: Object,
            title: String,
            titleBreak: String,
            offer: String,
            offerBreak: String,
            expiryDate: String,
            holderText: String,
            expiryText: String,
            background: String,
            default: () => ({
                title: "Saving for",
                titleBreak: "your phone?",
                offer: "The New Galaxy",
                offerBreak: "S23 Ultra",
                expiryDate: "11/22",
                holderText: "Get 12% Off",
                expiryText: "Expires",
                background: "dark",
            }),
        },
    },
    data() {
        return {
            img,
            img2,
        };
    },
};
</script>
