<template>
  <navbar btn-background="bg-gradient-primary" />
  <div class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{
    backgroundImage:
      'url(' + require('@/assets/img/curved-images/curved2.jpg') + ')',
  }">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
          <p class="text-white text-lead">Save. Lend. Borrow. Invest.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Register new account</h5>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="register">
              <div class="mb-3">
                <label>Full Name</label>
                <input id="name" type="text" placeholder="John Doe" aria-label="Full Name" class="form-control"
                  v-model="form.full_name" />
              </div>
              <div class="mb-3">
                <label>Phone Number</label>
                <div style="display: flex">
                  <!-- <soft-input id="email" type="number" placeholder="+254" name="email" style="width: 25%" /> -->
                  <select style="width: 25%" id="code" class="form-control" v-model="form.code">
                    <option v-for="country in countriesItem" :key="country.id" :value="country.code">
                      {{ country.code }} ({{ country.name }})
                    </option>
                  </select>
                  <div style="margin-left: 10px"></div>
                  <input id="phone" type="text" placeholder="722001122" name="phone" style="width: 75%"
                    class="form-control" v-model="form.phone_number" />
                </div>
              </div>
              <div class="mb-3">
                <label>Pin (4 digit)</label>
                <input class="form-control" id="password" type="password" placeholder="****" aria-label="Pin (4 digit)"
                  v-model="form.pin" />
              </div>
              <div class="mb-3">
                <label>Confirm Pin</label>
                <input id="cpassword" type="password" placeholder="****" aria-label="Confirm Pin" class="form-control"
                  v-model="form.pin_confirmation" />
              </div>
              <div class="mb-3">
                <label>Referral Code</label>
                <input id="referal" type="text" placeholder="AB1234" aria-label="Referral Code" class="form-control"
                  v-model="form.inviter_code" />
              </div>
              <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light" checked>
                I agree to the
                <a href="javascript:;" class="text-dark font-weight-bolder">Terms & Conditions</a>
              </soft-checkbox>

              <div class="text-center">
                <!-- <router-link :to="{ name: 'Personal Information' }"> -->
                <soft-button color="success" type="submit" full-width variant="gradient"
                  class="my-4 mb-2">Next</soft-button>
                <!-- </router-link> -->
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <router-link :to="{ name: 'Sign In' }" class="text-dark font-weight-bolder">
                  Sign in
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    SoftCheckbox,
    SoftButton,
  },

  data() {
    return {
      form: {},
      loadingForm: false,
      errorForm: null,
      countriesItem: [],
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.setForm();
    this.fetchCountries();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    ...mapActions(["registerCustomer", "fetchCountries"]),

    setForm(value = null) {
      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
          full_name: "",
          phone_number: "",
          pin: "",
          pin_confirmation: "",
          inviter_code: "",
          code: "+254",
        };
    },
    register() {
      this.loadingForm = true;
      let oldPhone = this.form.phone_number;
      let phone = oldPhone;
      let code = this.form.code;

      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        this.form.phone_number = code + phone;
      } else {
        this.form.phone_number = code + phone;
      }
      this.loadingForm = true;

      const formData = {
        full_name: this.form.full_name,
        phone_number: this.form.phone_number,
        pin: this.form.pin,
        pin_confirmation: this.form.pin_confirmation,
        inviter_code: this.form.inviter_code,
        code: this.form.code,
      };

      this.registerCustomer(formData);
      this.form.phone_number = oldPhone;
      this.setForm();
    },
  },

  computed: mapGetters(["auth_info", "countries"]),

  watch: {
    countries() {
      this.countriesItem = this.countries;
    },
    "auth_info.token"() {
      const token = this.auth_info.token;
      if (token) {
        console.log("token", token);
        this.$router.push({
          name: "Personal Information",
          replace: true,
        });
      } else {
        console.log("failed");
        return;
      }
    },
    "auth_info.loading"() {
      this.loadingForm = this.auth_info.loading;
    },
    "auth_info.message"() {
      const message = this.auth_info.message;
      this.$toast.success(message);
      // const error = this.auth_info.error;

      // console.log("object msg", message);

      // if (error) this.$toast.error(message);
      // else this.$toast.success(message);
    },
  },
};
</script>
