<template>
  <div class="fixed-plugin max-height-vh-100 h-100">
    <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed" @click="toggle">
      <i class="py-2 fa fa-user"> </i>
    </a>
    <div class="shadow-lg card blur">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">

        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <div>
        <!-- {{ profileInfo }} -->
        <div style="
                                                  justify-content: space-between;
                                                  padding-right: 20px;
                                                  padding-left: 20px;
                                                  background-color: transparent !important;
                                                " class="col-auto d-flex">
          <div>
            <li style="background-color: transparent !important;"
              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark font-weight-bold text-sm">Hello,</h6>
                <span style="font-size: 28px !important; font-weight: 700" class="text-xs">{{
                  profileInfo.full_name
                }}</span>
                <star-rating :inline="true" :star-size="14" :read-only="true" :show-rating="false"
                  :rating="4"></star-rating>
                <div class="d-flex" style="justify-content: space-between">
                  <span style="font-size: 14px; color: black">Overall Rating</span>
                  <i style="padding-top: 5px" type="button" class="fa fa-info-circle text-dark cursor-pointer btn-tooltip"
                    aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"
                    data-container="body" data-animation="true">
                  </i>
                </div>
              </div>
            </li>
          </div>
          <div class="avatar avatar-xl position-relative">
            <img v-if="currentUser.image == null" style="border-radius: 9rem !important;  box-shadow: none !important"
              src="@/assets/img/avatar.png" alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
            <img style="border-radius: 9rem !important; box-shadow: none !important" :src="URL + currentUser.image"
              alt="profile_image" class="shadow-sm w-100 h-100 border-radius-lg" />
          </div>
        </div>
        <div style="padding-left: 20px; padding-right: 20px">
          <li style="background-color: transparent !important;"
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
            <div class="d-flex flex-column">
              <h6 style="font-size: 12px !important; color: grey !important; background-color: transparent !important;"
                class="mb-1 text-dark font-weight-bold text-sm">
                Copy your referral link and share with your friends on your social
                channel
              </h6>
            </div>
          </li>
        </div>
        <div>
          <div class="d-flex align-items-center" style="margin-left: 1rem; margin-right:1rem">
            <div class="form-group w-70">
              <div class="input-group bg-gray-200">
                <input class="form-control form-control-sm" :value="currentUser.referal_link" type="text" disabled=""
                  onfocus="focused(this)" onfocusout="defocused(this)" ref="mylink" rel="noopener noreferrer" />
                <span class="input-group-text bg-transparent" data-bs-toggle="tooltip" data-bs-placement="top"
                  aria-label="Referral code is reusable" data-bs-original-title="Referral code is reusable"><i
                    class="ni ni-key-25"></i></span>
              </div>
            </div>
            <button href="javascript:;" class="btn btn-outline-secondary ms-2" style="border: 2px solid" @click="copyUrl">
              Copy
            </button>
          </div>
        </div>
        <div>
          <router-link :to="{ name: 'Profile' }">
            <a style="
                                                      padding-left: 20px !important;
                                                      padding-bottom: 20px !important;
                                                      text-decoration: underline;
                                                    " class="mb-0 btn btn-link pe-3 ps-0 ms-auto">View Profile
              <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i></a>
          </router-link>
        </div>
        <div class="mb-xl-0 mb-4">
          <packages-card-mobile style="
                                            margin-left: 10px;
                                            margin-right: 20px;
                                            box-shadow: none !important;
                                            margin-bottom: 20px;
                                            position: relative !important;
                                            width: 97%
                                          " />
        </div>
        <div class="mb-xl-0 mb-4">
          <add-card-mobile style="
                            margin-left: 10px;
                            margin-right: 20px;
                            box-shadow: none !important;
                            margin-bottom: 20px;
                            position: relative !important;
                            width: 97%
                          " />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
// import SoftButton from "@/components/SoftButton.vue";
import PackagesCardMobile from "@/examples/Cards/PackagesCardMobile.vue";
import AddCardMobile from "@/examples/Cards/AddCardMobile.vue";
// @ts-ignore
import StarRating from "vue-star-rating";
export default {
  name: "configurator",
  props: ["toggle"],
  components: {
    StarRating,
    // SoftButton,
    AddCardMobile,
    PackagesCardMobile
  },
  data() {
    return {
      fixedKey: "",
      profileInfo: {},
      URL: "https://api.sortika.co.ke",
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor", "getProfile"]),

    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },

    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },

    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");
      let white = document.querySelector("#btn-white");
      if (window.innerWidth < 1200) {
        transparent.classList.add("disabled");
        white.classList.add("disabled");
      } else {
        transparent.classList.remove("disabled");
        white.classList.remove("disabled");
      }
    },
    copyUrl() {
      const el = document.createElement("textarea");
      el.value = this.currentUser.referal_link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },

  computed: {
    ...mapGetters(["profile", "currentUser"]),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  watch: {
    profile() {
      this.profileInfo = this.profile;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
    // Deactivate sidenav type buttons on resize and small screens
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
};
</script>
