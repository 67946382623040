<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row" v-if="!loadingPage">
      <div
        class="col-lg-4 mb-2"
        v-for="investment in investmentItems"
        :key="investment?.id"
      >
        <investment-card :investment="investment" style="margin-bottom: 1rem" />
      </div>
    </div>
    <div v-else class="mt-4 row">
      <div class="col-lg-12">
        <div
          class="card h-100 mt-4"
          style="padding-left: 10px; padding-right: 10px"
        >
          <div class="card-body p-3 pb-0 mb-0">
            <div
              style="margin-left: 50%; margin-top: 2%"
              class="spinner-grow text-dark"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import InvestmentCard from "./InvestmentCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: "investmentActive", // Borrow is chosen by default

      investmentItems: [],
      filters: {},
      query: "",
      currentPage: 1,
      perPage: 12,
      last_page: 0,
      loadingPage: false,
    };
  },
  components: {
    InvestmentCard,
  },

  created() {
    this.loadingPage = true;
    this.fetchInvestments(1, 12);
    // this.getInvestmentsProfile();
  },

  methods: {
    ...mapActions(["getInvestments"]),

    fetchInvestments(page, rows) {
      this.getInvestments({ page, rows, query: this.query, ...this.filters });
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },
  },

  computed: {
    ...mapGetters(["investments", "loadingInfo"]),
  },

  watch: {
    investments() {
      this.currentPage = this.investments.current_page;
      this.last_page = this.investments.last_page;
      this.investmentItems = this.investments.investmentData;
      this.loadingPage = false;

      //   let newInv = this.investments.investmentData;
      //   if (this.investments.queryState) {
      //     this.investmentItems = [];
      //     this.investmentItems = this.investments.investmentData;
      //   } else {
      //     for (let i of newInv) {
      //       this.investmentItems.push(i);
      //     }
      //   }
    },
  },

  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
