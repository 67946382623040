<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Loan Requests</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Borrower
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Rating
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Interest
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Date Payable
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lenderReq in lenderReqs" :key="lenderReq.id">
              <td>
                <div class="d-flex px-2">
                  <div></div>
                  <div class="my-auto">
                    <h6 style="margin-left: 10px" class="mb-0 text-sm">
                      {{ lenderReq.borrower_profile.full_name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div class="d-flex align-items-center">
                  <star-rating
                    :inline="true"
                    :star-size="14"
                    :read-only="true"
                    :show-rating="false"
                    :rating="4"
                  ></star-rating>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">
                  {{ lenderReq.interest }}%
                </p>
              </td>
              <td>
                <span class="text-xs font-weight-bold"
                  >KES {{ parseFloat(lenderReq.amount).toLocaleString() }}</span
                >
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{
                  lenderReq.date_payable
                }}</span>
              </td>
              <td
                style="
                  display: flex;
                  justify-content: flex-end;
                  margin-right: 1rem;
                  margin-top: 0.6rem;
                "
                class="align-middle"
              >
                <soft-button
                  style="box-shadow: none; margin-bottom: 1rem !important"
                  class="mb-2"
                  variant="gradient"
                  color="success"
                  @click="setLoanAcceptance(lenderReq.id, 'true')"
                  >Accept
                </soft-button>
                <button
                  href="javascript:;"
                  class="btn btn-outline-secondary ms-2"
                  style="border: 2px solid"
                  @click="setLoanAcceptance(lenderReq.id, 'false')"
                >
                  Decline
                </button>
                <button
                  href="javascript:;"
                  class="btn btn-outline-secondary ms-2"
                  style="border: 2px solid #14f9ac"
                  data-bs-toggle="modal"
                  :data-bs-target="'#modal-loan-terms' + lenderReq.id"
                >
                  Change Terms
                </button>
              </td>

              <!-- Loan Terms Modal -->
              <div class="col-md-4">
                <div
                  class="modal fade"
                  :id="'modal-loan-terms' + lenderReq.id"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="modal-default"
                  aria-hidden="true"
                >
                  <loan-request-table-form :req="lenderReq" />
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftProgress from "@/components/SoftProgress";
// import SoftBadge from "@/components/SoftBadge.vue";
import SoftButton from "@/components/SoftButton";
// @ts-ignore
import StarRating from "vue-star-rating";
import { mapActions } from "vuex";
import LoanRequestTableForm from "./LoanRequestTableForm.vue";

export default {
  name: "lender-request-table",
  components: {
    // SoftBadge,
    StarRating,
    SoftButton,
    LoanRequestTableForm,
    // SoftProgress,
  },
  props: ["lenderReqs"],

  methods: {
    ...mapActions(["lenderLoanReqAcceptance"]),

    setLoanAcceptance(id, status) {
      let __status = status == "true" ? true : false;
      this.lenderLoanReqAction({ id, __status });
    },
  },
};
</script>
