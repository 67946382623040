<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <h5>Loan Stats</h5>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          title="Total Loans"
          :value="
            'KES ' + parseFloat(loanProfile?.amount_lent).toLocaleString()
          "
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Actual Earnings"
          :value="
            'KES ' + parseFloat(loanProfile?.amount_earned).toLocaleString()
          "
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          title="Loans Issued"
          :value="loanProfile?.n_lent"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          direction-reverse
        />
      </div>
      <div
        style="display: flex; justify-content: flex-end"
        class="col-xl-3 col-sm-6 mb-xl-0"
      >
        <h6 v-if="lendingEnabled">Enable Lending</h6>
        <h6 v-else>Disable Lending</h6>
        <div style="margin-left: 0.5rem"></div>
        <soft-switch
          id="lendingActive"
          name="lendingActive"
          :checked="lendingEnabled ? 'checked' : ''"
          @change="editLendingState($event)"
        >
        </soft-switch>
      </div>
    </div>
    <div
      style="margin-top: 10px; margin-bottom: 20px"
      class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8"
      id="nav"
    >
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1 active active"
            data-bs-toggle="tab"
            id="activeLend"
            href="#"
            role="tab"
            aria-controls="ActiveLend"
            aria-selected="true"
            v-on:click="makeActive('activeLendActive')"
          >
            Active
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="overdueLend"
            role="tab"
            aria-controls="OverdueLend"
            v-on:click="makeActive('overdueLendActive')"
            aria-selected="false"
          >
            Overdue
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="#"
            id="completeLend"
            role="tab"
            aria-controls="CompleteLend"
            v-on:click="makeActive('completeLendActive')"
            aria-selected="false"
          >
            Complete
          </a>
        </li>
      </ul>
    </div>
    <div id="lend" v-show="isActiveTab('activeLendActive')">
      <active-lender-table
        :activeLenders="lenderLoanData.filter((loan) => loan.is_active)"
      />
    </div>
    <div id="lend" v-show="isActiveTab('overdueLendActive')">
      <overdue-lender-table
        :overdueLenders="lenderLoanData.filter((loan) => loan.is_overdue)"
      />
    </div>
    <div id="lend" v-show="isActiveTab('completeLendActive')">
      <complete-lend-table
        :completedLenders="lenderLoanData.filter((loan) => loan.is_completed)"
      />
    </div>
    <div style="margin-bottom: 3rem"></div>
    <div class="row mt-4">
      <div>
        <lender-request-table :lenderReqs="lenderReqs" />
      </div>
    </div>
  </div>

  <!-- Enable Lending Switch Modal -->
  <div class="col-md-4">
    <div
      class="modal fade"
      id="modal-set-rating"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-default"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <!-- <h6 class="modal-title" id="modal-title-view-rating">Lending rate</h6> -->
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="margin-bottom: 1rem">Lending rate</label>
            <soft-input
              id="rate"
              type="number"
              placeholder="Eg. 5%"
              aria-label="rate"
            />
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark"
              >Submit</soft-button
            >
            <soft-button
              type="button"
              class="mb-2"
              color="warning"
              data-bs-dismiss="modal"
              >Dismiss</soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import NavPillLoan from "../components/NavPillLoan.vue";
// import NavPillLoanStatus from "../components/NavPillLoanStatus.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import LendersCard from "./components/LendersCard.vue";
// import AllGoalsCard from "./components/AllGoalsCard.vue";
import ActiveLenderTable from "../components/ActiveLenderTable.vue";
import OverdueLenderTable from "../components/OverdueLenderTable.vue";
import CompleteLendTable from "../components/CompleteLendTable.vue";
import LenderRequestTable from "../components/LenderRequestTable.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "lender-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: "activeLendActive", // Borrow is chosen by default
      lenderLoanData: [],
      lendingEnabled: false,
      lenderReqs: [],
    };
  },
  components: {
    MiniStatisticsCard,
    // NavPillLoan,
    // NavPillLoanStatus,
    ActiveLenderTable,
    // LendersCard,
    LenderRequestTable,
    OverdueLenderTable,
    CompleteLendTable,
    SoftSwitch,
    SoftInput,
    SoftButton,
  },
  props: ["loanProfile"],

  created() {
    this.fetchLenderLoan();
    this.fetchLenderLoanReq();
  },
  methods: {
    ...mapActions(["getLenderLoan", "updateLendingState", "getLenderLoanReq"]),

    editLendingState(evt) {
      const lp_id = this.loanProfile.id;
      let event = evt.target.checked;
      const lending_state = event;
      this.updateLendingState({ id: lp_id, status: lending_state });
    },

    fetchLenderLoan() {
      this.getLenderLoan();
    },

    fetchLenderLoanReq() {
      this.getLenderLoanReq();
    },
    ...mapActions(["getLender"]),
    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },
  },
  computed: {
    ...mapGetters(["lenderLoans", "loanProfile", "lenderLoanReq"]),
  },
  watch: {
    loanProfile() {
      this.lendingEnabled = this.loanProfile.is_lending_enabled;
      console.log(
        "there: ",
        this.lendingEnabled,
        this.loanProfile.is_lending_enabled
      );
    },
    lenderLoans() {
      this.lenderLoanData = this.lenderLoans;
    },
    lenderLoanReq() {
      this.lenderReqs = this.lenderLoanReq;
    },
  },
  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
