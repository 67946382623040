<template>
  <navbaronboarding btn-background="bg-gradient-primary" />
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
          <p class="text-white text-lead">
            Save, Invest, Borrow & Lend.
          </p>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Quick Survey</h5>
          </div>
          <div class="card-body">
            <div id="app">
              <form role="form">
                <div class="mb-3">
                  <label :style="{ fontSize: '1rem' }">What is your current financial position?</label>
                  <div style="width: 100%; height: 80px; margin-top: 1rem;" class="radio-card">
                    <input form="formSurvey" type="radio" name="flexRadioDefault" id="survey" />
                    <label style="
                                                    position: absolute;
                                                    margin: 20px;
                                                    cursor: pointer;
                                                  " for="survey">I have an income but no savings</label>
                  </div>
                  <div style="width: 100%; height: 80px; margin-top: -0.7rem;" class="radio-card">
                    <input form="formSurvey" type="radio" name="flexRadioDefault" id="survey" />
                    <label style="
                                                    position: absolute;
                                                    margin: 20px;
                                                    cursor: pointer;
                                                  " for="survey">I have an income and savings</label>
                  </div>
                  <div style="width: 100%; height: 80px; margin-top: -0.7rem;" class="radio-card">
                    <input form="formSurvey" type="radio" name="flexRadioDefault" id="survey" />
                    <label style="
                                                    position: absolute;
                                                    margin: 20px;
                                                    cursor: pointer;
                                                  " for="survey">I have no income but have savings</label>
                  </div>
                  <div style="width: 100%; height: 80px; margin-top: -0.7rem;" class="radio-card">
                    <input form="formSurvey" type="radio" name="flexRadioDefault" id="survey" />
                    <label style="
                                                    position: absolute;
                                                    margin: 20px;
                                                    cursor: pointer;
                                                  " for="survey">I have no income nor savings</label>
                  </div>
                </div>
                <div class="text-center">
                  <router-link :to="{ name: 'Tips' }">
                    <soft-button color="success" full-width variant="gradient" class="my-4 mb-2">Next</soft-button>
                  </router-link>
                  <router-link :to="{ name: 'Product' }" style="font-size: 0.85rem; color: deepskyblue">Skip for
                    now</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbaronboarding from "@/examples/PageLayout/Navbaronboarding.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbaronboarding,
    AppFooter,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "onboardProfile",
    ]),

    // addOnboardProcess(){
    //     this.onboardProfile({
    //     address: this.form.address,
    //     gender: this.form.gender,
    //     date_of_birth: date_of_birth,
    //     full_name: this.currentUser.full_name,
    //     id: this.currentUser.id,
    //     phone_number: this.currentUser.phone_number,
    //     step: "personal",
    //   });

    // }
  },
  computed: {
    ...mapGetters(["profile", "currentUser", "success"]),
  },

  watch: {
    success() {
      if (this.success) {
        this.$router.push({ name: "Quick Survey" });
      } else {
        this.loading = false;
        return;
      }
    },
  },
};
</script>
